import React from 'react';
import Page from '../pageData/page';
import Spinner from '../../../commons/spinner';
import { useGifts } from '../../services/gifts/giftServices';
import GiftDataCol from '../../components/tabledata/giftsTable/giftDataCol';
import GiftSearchCol from '../../components/tabledata/giftsTable/searchFields';
import GiftCol from '../../components/tabledata/giftsTable/giftCol';

const Gifts = () => {
  const {
    loading,
    addData,
    allGiftData,
    deleteGift,
    updateData,
    rowsPerPage,
    currentPage,
    noOfPages,
    active,
    getCurrentPage,
    totalRecords,
    searchGifts,
  } = useGifts();

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Page
          columns={GiftDataCol}
          rows={allGiftData}
          data={GiftCol}
          temp={'id'}
          showGradientButton={true}
          showQRButton={false}
          delData={deleteGift}
          addData={addData}
          updateData={updateData}
          toggle={active}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          total={noOfPages}
          getPage={getCurrentPage}
          totalRecords={totalRecords}
          searchFields={GiftSearchCol}
          search={searchGifts}
        />
      )}
    </>
  );
};

export default Gifts;
