const hostname = window.location.hostname.split('.');
const backendUrl = process.env.REACT_APP_TENANT;
const backendProtocol = process.env.REACT_APP_TENANT_PROTOCOL;

const LOCALHOST = process.env.REACT_APP_DEV_URL;
const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;

const isValidHost =
  (hostname[1] === LOCALHOST || hostname[1] === DOMAIN_NAME) &&
  hostname[0] !== LOCALHOST &&
  hostname[0] !== DOMAIN_NAME;

const getTenantUrl = () => {
  if (isValidHost) {
    return `${backendProtocol}://${hostname[0]}.${backendUrl}`;
  } else {
    return null;
  }
};

export const TENANT_URL = getTenantUrl();
