const ProdCatSearchCol = [
  {
    id: 'category_name',
    numeric: false,
    disablePadding: true,
    label: 'Category Name',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Active',
  },
];

export default ProdCatSearchCol;
