import * as Yup from 'yup';

const AddSchema = (fields, isEdit) => {
  const schema = {};

  if (fields.shopname) {
    schema.shopname = Yup.string()
      .required('Enter Your Shop Name')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!');
  }

  if (fields.name) {
    schema.name = Yup.string().min(2, 'Too Short!').max(50, 'Too Long!');
  }

  if (fields.email) {
    schema.email = Yup.string().email('Invalid email');
  }

  if (fields.password) {
    schema.password = isEdit ? Yup.string().notRequired() : Yup.string().required('No password provided.');
    //.min(8, 'Password is too short - should be 8 chars minimum.');
  }

  if (fields.account_number) {
    schema.accountNumber = Yup.string()
      .required('Enter Your Account Number')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(9, 'Must be exactly 9 digits')
      .max(9, 'Must be exactly 9 digits');
  }

  if (fields.adhaar_number) {
    schema.adhaarNumber = Yup.string()
      .required('Enter Your Adhaar Number')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(12, 'Must be exactly 12 digits')
      .max(12, 'Must be exactly 12 digits');
  }

  if (fields.company_name) {
    schema.companyName = Yup.string()
      .required('Enter Your Company Name')
      .min(2, 'Too Short!')
      .max(50, 'Too Long!');
  }

  if (fields.pan_card_number) {
    schema.panCardNumber = Yup.string()
      .required('Enter Your Pan Card Number')
      .matches(/^[0-9a-zA-Z]+$/, 'Must be alphanumeric');
  }

  return Yup.object(schema);
};

export default AddSchema;
