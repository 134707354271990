const OrdersCol = [
    'order_no',
    // 'user_type',
    'distributor_name',
    'action',
    // 'contact_number',
    // 'bank_name',
    'distributor_state',
    'distributor_city',
    'distributor_contact_number',
    'grand_total',
    'order_date_time',
    'order_status',
    // 'total_points',
    // 'avaiable_points',
    // 'is_active',
    // 'actions',
  ];
  
  export default OrdersCol;
  