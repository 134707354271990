import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import styled from '@emotion/styled';
import { InputBase } from '@mui/material';
import Spinner from '../../../commons/spinner';
import GradientButton from '../../../commons/gradiantComponents/gradientButtonPage';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import ThrowError from '../../utils/errors';
import { TENANT_URL } from '../../../config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  color: '#FABF59',
};

const StyledTextField = styled(InputBase)(({ theme }) => ({
  borderRadius: '12px',
  height: '56px',
  background: theme.palette.textFieldBackground.main,
  boxShadow: theme.palette.textFieldBackground.boxShadow,
  padding: '20px',
}));

const BasicModal = ({ open, handleClose, tenetId }) => {
  const [NEFTDetails, setNEFTDetails] = React.useState(null);
  const [NEFTNumber, setNEFTNumber] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const { show } = useSnackbar();

  const token = JSON.parse(localStorage.getItem('userdata')).token;

  React.useEffect(() => {
    if (tenetId) {
      getNEFTDetails();
    }
    // eslint-disable-next-line
  }, [tenetId]);

  const getNEFTDetails = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${TENANT_URL}/neft/details/${tenetId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setNEFTDetails(res.data.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const onSubmit = async (event) => {
    let data = {
      id: NEFTDetails.id || '',
      neft_number: NEFTNumber || '',
    };
    try {
      const config = {
        method: 'post',
        url: `${TENANT_URL}/approve/redeem/request`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      };
      const res = await axios(config);
      if (res.data) {
        show('Request Approved');
        handleClose();
      }
    } catch (error) {
      ThrowError(error, show);
    }
  };

  const handleNEFTNumber = (event) => {
    setNEFTNumber(event.target.value);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Grid container spacing={2} direction="column">
            <Grid item xs={12}>
              {NEFTDetails ? (
                <>
                  <Typography>
                    Name:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {NEFTDetails.name}
                    </span>
                  </Typography>
                  <Typography>
                    Phone:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {NEFTDetails.phone}
                    </span>
                  </Typography>
                  <Typography>
                    Bank Name:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {NEFTDetails.bank}
                    </span>
                  </Typography>
                  <Typography>
                    Phone:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {NEFTDetails.phone}
                    </span>
                  </Typography>
                  <Typography>
                    Account Number:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {NEFTDetails.account_number}
                    </span>
                  </Typography>
                  <Typography>
                    IFSC Code:{' '}
                    <span style={{ fontWeight: 'bold' }}>
                      {' '}
                      {NEFTDetails.ifsc}
                    </span>
                  </Typography>
                </>
              ) : (
                <Typography>No NEFT detasils available</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                placeholder="NEFT Number"
                onChange={handleNEFTNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>Redeem value:</Typography>
              <StyledTextField
                placeholder={NEFTDetails && NEFTDetails.amount}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>NEFT Date:</Typography>
              <StyledTextField
                placeholder={NEFTDetails && NEFTDetails.date}
                disabled
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <GradientButton
                disabled={!NEFTNumber}
                onClick={onSubmit}
                name={'save'}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <GradientButton onClick={handleClose} name={'close'} fullWidth />
            </Grid>
          </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default BasicModal;
