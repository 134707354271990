const OrdersSearchCol = [
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Distributor Name',
    },
    {
      id: 'contact_number',
      numeric: false,
      disablePadding: false,
      label: 'Phone',
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: 'State',
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      label: 'City',
    },
    {
      id: 'is_active',
      numeric: false,
      disablePadding: false,
      label: 'Active',
    },
  ];
  
  export default OrdersSearchCol;
  