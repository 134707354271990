import { useEffect, useState } from 'react';

const ProductList = () => {
  const val = JSON.parse(localStorage.getItem('userdata'));
  const permissions = val.tenant_nav_bar_permission;
  const [prodPages, setProdPages] = useState([]);

  useEffect(() => {
    const updatedTenantPages = [];
    if (permissions?.is_products_tab == (1 || "1")) {
      updatedTenantPages.push(
        { name: 'Products(S)', link: '/product' },
        {
          name: 'Product Category',
          link: '/productcategory',
        },
      
      );
    }
    if (permissions?.is_gift_tab == (1 || "1")) {
      updatedTenantPages.push(
        {
          name: 'Gift(s)',
          link: '/gifts',
        }
      
      );
    }
    setProdPages(updatedTenantPages);
    // eslint-disable-next-line
  }, [permissions]);

  return prodPages;
};

export default ProductList;
