import React, { useEffect, useState } from 'react';
// **********************************************************************************************************
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
// **********************************************************************************************************

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import AddSchema from '../../components/validations/validationsSchema';
import FormInitialValues from '../../components/validations/initalValues';
import { useUtility } from '../../services/utilityContext/useUtility';
import { useProduct } from '../../services/products/productService';
//AutoComplete Component Imports starts
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { display, style } from '@mui/system';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

//AutoComplete Component Imports Ends

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: 'button.menu' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'icon.main',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProductDialogBox = ({ name, onClose, data, onAddRow, editData, id }) => {
 
const [currentPage, setCurrentPage] = useState(1);
const [allProductData, setAllProductData] = useState([]);
const { show } = useSnackbar();

const getCurrentPage = async (pageNo) => {
  setCurrentPage(pageNo);
  const token = JSON.parse(localStorage.getItem('userdata')).token;
  const getConfig = {
    method: 'get',
    url: `${TENANT_URL}/category?page=${currentPage}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const response = await axios(getConfig);
  //  const finalData =  getRequiredArray(response.data.data)
    setAllProductData(response.data.data);
  } catch (error) {
    console.error(error);
    show('Data not Updated', 'error');
  }
};

  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const func = editData && Object.keys(editData).length > 0 ? 'Edit' : 'Add';
  const { city, states, getCities, banks, distributors } = useUtility();
  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };


  const handleSelectChange = (key, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));

    if (key === 'state') {
      const stateId = value;
      getCities(stateId);
    }
  };

  
  const handleChange = (event, newValue) => {
    let selectedDistributorID = newValue.map(distributor => distributor.id);
    handleSelectChange('me_distributor', selectedDistributorID);
    setSelectedDistributors(newValue);

  };

  const formik = useFormik({
    initialValues: { FormInitialValues },
    validationSchema: AddSchema(data),
    onSubmit: () => {
      setOpen(false);
      onAddRow(selectedValues, editData[id]);
      onClose();
    },
  });

  useEffect(() => {
    if (editData) setSelectedValues(editData);
    else setSelectedValues([]);
    setOpen(true);
  }, [editData]);
 useEffect(() => {
   getCurrentPage(currentPage);
 },[])
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'background.paper',
            boxShadow: 'none',
            borderRadius: '10px',
            width: '100%',
            maxWidth: '600px',
          },
          '& button.MuiButtonBase-root ': {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            textTransform: 'capitalize',
            borderRadius: '10px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'button.menuhover',
              boxShadow: 'none',
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {func}&nbsp;
          <span style={{ textTransform: 'capitalize' }}>{name}</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Grid container spacing={2}>
              {Object.entries(data).map(([key, value]) => {

                if (
                  value.id === 'id' ||
                  value.id === 'is_active' ||
                  value.id === 'actions' ||
                  value.id === 'mechanicTab' ||
                  value.id === 'retailersTab' ||
                  value.id === 'productsTab' ||
                  value.id === 'redeemRequestTab' ||
                  value.id === 'redeemPointsTab' ||
                  value.id === 'permission' ||
                  value.id === 'deleted_at' ||
                  value.id === 'created_at' ||
                  value.id === 'updated_at' ||
                  value.id === 'total_points' ||
                  value.id === 'avaiable_points'
                ) {
                  return null;
                }

                return (
                  <Grid item xs={12} md={6} key={key}>
                    {value.id === 'me_distributor' ? (
                      <Autocomplete
                        multiple
                        id="checkboxes-tags-demo"
                        options={distributors}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.name}
                          </li>
                        )}
                        fullWidth
                        value={selectedDistributors}
                        onChange={handleChange}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Distributor(s)" placeholder="Distributors" />
                        )}
                      />

                    ) :
                      !['bank_name', 'city', 'state','product_category_id'].includes(value.id) ? (

                        <TextField
                          type='select'
                          required={value.required ? 'required' : ''}
                          placeholder={value.label}
                          fullWidth
                          name={value.id}
                          label={value.label}
                          value={selectedValues[value.id]}
                          error={
                            formik.errors[value.id] && formik.touched[value.id]
                          }
                          helperText={
                            formik.errors[value.id] && formik.touched[value.id]
                              ? formik.errors[value.id]
                              : ''
                          }
                          onBlur={formik.handleBlur}
                          onChange={(event) => {
                            formik.handleChange(event);
                            handleSelectChange(value.id, event.target.value);
                          }}
                        />
                      ) : (
                        <Grid item xs={12} md={12}>
                          <Select
                            fullWidth
                            required={value.required ? 'required' : ''}
                            value={selectedValues[value.id] || ''}
                            displayEmpty
                            name={value.id}
                            onChange={(event) =>
                              handleSelectChange(value.id, event.target.value)
                            }
                          >
                            <MenuItem value="" disabled>
                              {value.label}
                            </MenuItem>
                            {value.id === 'state'
                              ? states.map((state) => (
                                <MenuItem key={state.id} value={state.id}>
                                  {state.name}
                                </MenuItem>
                              ))
                              : value.id === 'city'
                                ? city.map((city) => (
                                  <MenuItem key={city.name} value={city.name}>
                                    {city.name}
                                  </MenuItem>
                                ))
                                : value.id === 'bank_name'
                                  ? banks.map((bank) => (
                                    <MenuItem key={bank.name} value={bank.name}>
                                      {bank.name}
                                    </MenuItem>
                                  ))
                                  : value.id === "product_category_id"
                                    ? allProductData?.map((product) => (
                                        <MenuItem key={product.id} value={product.id}>
                                          {product.category_name}
                                        </MenuItem>
                                      ))
                                    : null}
                          </Select>
                        </Grid>
                      )}
                  </Grid>
                );
              })}
              
              <Grid item xs={12} md={12}>
                <Button
                  autoFocus
                  fullWidth
                  type="submit"
                  sx={{
                    backgroundColor: 'button.menu',
                    color: 'icon.main',
                    fontFamily: 'Poppins',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '22px',
                    textTransform: 'capitalize',
                    padding: '12px 0px 12px 20px',
                    borderRadius: '10px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'button.menuhover',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Save change
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ProductDialogBox;
