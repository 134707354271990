import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../commons/spinner';
import { useAdminAuth } from '../../auth/superadmin/adminAuth';
import { useFormik } from 'formik';
import AddSchema from '../../tenant/components/validations/validationsSchema';
import FormInitialValues from '../../tenant/components/validations/initalValues';

export default function AdminLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login, loading } = useAdminAuth();

  const handleSubmit = async (event) => {
    try {
      await login({ email, password });
      navigate('/superadmin/dashboard');
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const formik = useFormik({
    initialValues: FormInitialValues,
    validationSchema: AddSchema({
      email: true,
      password: true,
    }),
    onSubmit: handleSubmit,
  });

  return (
    <>
      {loading === true ? (
        <Spinner />
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h4" fontWeight={'bold'}>
              Sign In To Super Admin
            </Typography>
            <Typography sx={{ color: 'grey', fontSize: '15px' }}>
              Your Social Campaigns
            </Typography>
            <Box padding={'10px'}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                textAlign="center"
              >
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<i className="fab fa-apple" />}
                    sx={{
                      textTransform: 'none',
                      height: '2.5rem',
                      boxShadow: 'none',
                      width: '10rem',
                    }}
                  >
                    Google Login
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<i className="fab fa-apple" />}
                    sx={{
                      textTransform: 'none',
                      height: '2.5rem',
                      boxShadow: 'none',
                    }}
                  >
                    Apple Login
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={3}
          >
            <Box flexGrow={1} border="1px solid #ccc" mr={1} />
            <Typography variant="body2" color="text.secondary">
              or with email
            </Typography>
            <Box flexGrow={1} border="1px solid #ccc" ml={1} />
          </Box>
          <Box sx={{ maxWidth: 400, mx: 'auto', mt: 5 }}>
            <form
              onSubmit={(e) => {
                if (formik.handleSubmit(e)) handleSubmit(e);
              }}
              onReset={formik.handleReset}
            >
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom align="left">
                  Email
                </Typography>
                <TextField
                  fullWidth
                  id="email"
                  type="email"
                  variant="outlined"
                  disabled={loading}
                  value={email}
                  error={formik.errors['email'] && formik.touched['email']}
                  helperText={
                    formik.errors['email'] && formik.touched['email']
                      ? formik.errors['email']
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setEmail(event.target.value);
                  }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom align="left">
                  Password
                </Typography>
                <TextField
                  fullWidth
                  disabled={loading}
                  id="password"
                  type="password"
                  variant="outlined"
                  value={password}
                  error={
                    formik.errors['password'] && formik.touched['password']
                  }
                  helperText={
                    formik.errors['password'] && formik.touched['password']
                      ? formik.errors['password']
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setPassword(event.target.value);
                  }}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                disabled={!formik.isValid || loading}
                sx={{
                  boxShadow: 'none',
                  textTransform: 'none',
                  height: '2.5rem',
                }}
              >
                {loading ? `Loging in` : `Login`}
              </Button>
            </form>
          </Box>
        </Container>
      )}
    </>
  );
}
