import axios from 'axios';
import { createContext, useContext, useState } from 'react';
import { useSnackbar } from '../../commons/snackBarContextProvider';

const AdminAuthContext = createContext();

const AdminAuthProvider = ({ children }) => {
  const [admin, setAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const { show } = useSnackbar();

  const login = async (userData) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN}/login`,
        userData
      );

      if (response.data.success) {
        const data = response.data.data;
        localStorage.setItem('adminData', JSON.stringify(data));
        show('Logged in');
      }
    } catch (error) {
      show('Incorrect Data', 'error');
    }
    setLoading(false);
  };

  const logoutAdmin = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem('adminData')).token;
      await axios.get(`${process.env.REACT_APP_ADMIN}/logout`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdmin(false);
      localStorage.removeItem('adminData');
      show('Logged out successfully');
    } catch (error) {
      console.error(error);
      show('Error logging out', 'error');
    }
    setLoading(false);
  };

  const contextValue = {
    admin,
    login,
    logoutAdmin,
    loading,
    setAdmin,
    setLoading,
  };

  return (
    <AdminAuthContext.Provider value={contextValue}>
      {children}
    </AdminAuthContext.Provider>
  );
};

const useAdminAuth = () => useContext(AdminAuthContext);

export { AdminAuthProvider, useAdminAuth };
