import React, { useEffect, useState,useRef } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TableData from '../../../commons/table/table';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import GradientButton from '../../../commons/gradiantComponents/gradientButton';
import GradientPageButton from '../../../commons/gradiantComponents/gradientButtonPage';
import { useLocation } from 'react-router-dom';
import DialogBox from '../dialogBox/addRecord';
// import ProductDialogBox from '../dialogBox/addProductRecord';
import { useUtility } from '../../services/utilityContext/useUtility';
import GradientProductButon from '../../../commons/gradiantComponents/productButton';

const Page = ({
  columns,
  rows: rowData,
  data,
  temp,
  showGradientProductButton,
  showGradientButton,
  showQRButton,
  delData,
  addData,
  updateData,
  toggle,
  rowsPerPage,
  page,
  total,
  getPage,
  totalRecords,
  searchFields,
  search,
  selectFile,
  setCurrentOrder
}) => {
  const [pageName, setPageName] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showProductDialog, setShowProductDialog] = useState(false);
  const [searchTermData, setSearchTermData] = useState({});
  const [editData, setEditData] = useState([]);
  const location = useLocation();
  const { city, states, getCities, banks } = useUtility();
  const userData = localStorage.getItem('userdata');
  const user = JSON.parse(userData);
  const name = useState(user.name);
  const [selectedValues, setSelectedValues] = useState({
    bank_name: '',
    city: '',
    state: '',
    is_active: '',
    status: '',
    user_type: '',
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Update the state with the selected file
    setSelectedFile(file);
  };

  const handleSelectFile = () => {
    // Trigger file input click when the button is clicked
    fileInputRef.current.click();
  };
  useEffect(() => {
    const pathname = location.pathname;
    const parts = pathname.split('/');
    const currentPage = parts[parts.length - 1];
    if (currentPage === 'redeemrequest') setPageName('NEFT Request');
    else if (currentPage === 'redeemrequestgift')
      setPageName('Gifts Request');
    else if (currentPage === 'productcategory') setPageName('Product Category');
    else if (currentPage === 'marketingExecutive') setPageName('Marketing Executive');

    else setPageName(currentPage);
  }, [location]);

  const theme = useTheme();

  const handleClick = () => {
    setEditData([]);
    setShowDialog(!showDialog);
  };
  const handleProductClick = () => {
    setEditData([]);
    setShowProductDialog(!showProductDialog);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  function handleClickQR() {
    const path = '/productsQR';
    window.location.href = path;
  }

  const handleSearch = () => {
    if (Object.keys(searchTermData).length > 0) {
      search(searchTermData);
    } else {
      search({});
    }
  };

  const handleSearchTermChange = (event, key) => {
    if (key === 'state') {
      getCities(event.target.value);
    }

    const newValue = event.target.value;

    if (newValue === '' || newValue === null) {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [key]: newValue,
      }));
      setSearchTermData((prevSearchTermData) => {
        const updatedSearchTermData = { ...prevSearchTermData };
        delete updatedSearchTermData[key];
        return updatedSearchTermData;
      });
    } else if (key === 'is_active' || key === 'status' || key === 'user_type') {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [key]: newValue,
      }));
      setSearchTermData((prevSearchTermData) => ({
        ...prevSearchTermData,
        [key]: newValue,
      }));
    } else {
      setSelectedValues((prevSelectedValues) => ({
        ...prevSelectedValues,
        [key]: newValue,
      }));
      setSearchTermData((prevSearchTermData) => ({
        ...prevSearchTermData,
        [key]: newValue,
      }));
      if (key === 'state') {
        setSearchTermData((prevSearchTermData) => ({
          ...prevSearchTermData,
          [key]: states.find((state) => state.id === newValue)?.name || '',
        }));
      }
    }
  };

  const handleAddRow = (newData, id) => {
    if (!id) {
      addData(newData);
    } else {
      updateData(newData, id);
    }
  };
  const handleEditData = (val, del) => {
    if (del) {
      delData(val);
    } else {
      const matchedObject = rowData?.find((row) => row[temp] === val);
      setEditData(matchedObject);
      setShowDialog(true);
    }
  };

  const filteredColumns = columns?.filter((col) => data.includes(col.id));
  

  
  const [filteredRows, setFilteredRows] = useState();


  useEffect(() => {
    setFilteredRows(
      rowData?.filter((row) => {
        return Object.values(row).some((value) =>
          value?.toString().toLowerCase()
        );
      })
    );
  }, [rowData]);

  const IsActiveSelect = ({ value, onChange }) => (
    <Select
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        borderRadius: 4,
        border: '2px solid #DEDEDE',
        background: '#FFF',
        boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
        '&:hover': {
          boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
        },
        '&:focus': {
          boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
        },
      }}
      displayEmpty
      value={value}
      onChange={onChange}
    >
      <MenuItem value="">Search Staus</MenuItem>
      <MenuItem value="true">true</MenuItem>
      <MenuItem value="false">false</MenuItem>
    </Select>
  );

  const StatusSelect = ({ value, onChange }) => (
    <Select
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        borderRadius: 4,
        border: '2px solid #DEDEDE',
        background: '#FFF',
        boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
        '&:hover': {
          boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
        },
        '&:focus': {
          boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
        },
      }}
      displayEmpty
      value={value}
      onChange={onChange}
    >
      <MenuItem value="">Search Status</MenuItem>
      <MenuItem value="pending">pending</MenuItem>
      <MenuItem value="approved">approved</MenuItem>
    </Select>
  );

  const StateSelect = ({ value, onChange }) => {
    return (
      
      <Select
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
          borderRadius: 4,
          border: '2px solid #DEDEDE',
          background: '#FFF',
          boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
          '&:hover': {
            boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
          },
          '&:focus': {
            boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
          },
        }}
        displayEmpty
        value={value}
        onChange={onChange}
      >
        <MenuItem value="">Search State</MenuItem>
        {states.map((state) => (
          <MenuItem value={state.id} key={state.name}>
            {state.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const UserTypeSelect = ({ value, onChange }) => (
    <Select
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        borderRadius: 4,
        border: '2px solid #DEDEDE',
        background: '#FFF',
        boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
        '&:hover': {
          boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
        },
        '&:focus': {
          boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
        },
      }}
      displayEmpty
      value={value}
      onChange={onChange}
    >
      <MenuItem value="">Search User Type</MenuItem>
      <MenuItem value="Mechanic">Mechanic</MenuItem>
      <MenuItem value="Retailer">Retailer</MenuItem>
    </Select>
  );

  return (
    <Box>
      {showDialog && (
        <DialogBox
          name={pageName}
          onClose={handleClose}
          data={columns}
          onAddRow={handleAddRow}
          editData={editData}
          id={temp}
        />
      )}
      <Box sx={{ mx: '40px', mt: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'space-between',
            marginBottom: '24px',
            alignItems: { md: 'auto', xs: 'center' },
          }}
        >
          <Box sx={{ marginBottom: { md: 'auto', xs: '24px' } }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: { md: 'row', xs: 'column' },
              }}
            >
              <Typography
                sx={{
                  color: 'text.headings',
                  fontFamily: 'Poppins',
                  fontSize: 28,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '36px',
                  textTransform: 'capitalize',
                }}
              >
                {name}
              </Typography>
              <Typography
                sx={{
                  color: 'text.headings',
                  fontFamily: 'Poppins',
                  fontSize: 28,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '36px',
                  textTransform: 'capitalize',
                }}
              >
                ,&nbsp;{pageName}&nbsp;(
                <span style={{ fontSize: '80%' }}>s</span>)
              </Typography>
            </Box>
            <Typography

              sx={{
                color: 'text.headings',
                fontFamily: 'Poppins',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                textTransform: 'uppercase',
              }}
            >
              List of all the {name} &nbsp;{pageName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'center', sm: 'flex-start' },
              justifyContent: { xs: 'center', sm: 'flex-start' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            {showGradientButton && (
              <Box
                sx={{
                  marginBottom: { xs: '20px', sm: '0' },
                  marginRight: { xs: '0', sm: '8px' },
                  width: { xs: '100%', sm: 'auto' },
                  display:'flex',gap:2,
                }}
              >
                {selectFile? (<><input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                 <GradientButton
                  name={`Upload`}
                  UploadIcon={UploadFileIcon}
                  onClick={handleSelectFile}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                  }}
                />
                </>):''}
                <GradientButton
                  name={`Add ${pageName}`}
                  onClick={handleClick}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                  }}  
                />
              </Box>
            )}
            {showGradientProductButton && (
              <Box
                sx={{
                  marginBottom: { xs: '20px', sm: '0' },
                  marginRight: { xs: '0', sm: '8px' },
                  width: { xs: '100%', sm: 'auto' },
                  display:'flex',gap:2,
                }}
              >
                {selectFile? (<><input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                 <GradientButton
                  name={`Upload`}
                  UploadIcon={UploadFileIcon}
                  onClick={handleSelectFile}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                  }}
                />
                </>):''}
                <GradientProductButon
                  name={`Add ${pageName}`}
                  onClick={handleProductClick}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                  }}  
                />
              </Box>
            )}
            {showQRButton && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center', sm: 'flex-start' },
                }}
              >
                <GradientButton
                  name="Generate QR Code"
                  onClick={handleClickQR}
                  showQr={true}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box>
          <Grid container spacing={2}>
            {searchFields?.map((col) => (
              <Grid item xs={12} md={2}>
                {col.id === 'bank_name' || col.id === 'city' ? (
                  <Select
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      width: '100%',
                      borderRadius: 4,
                      border: '2px solid #DEDEDE',
                      background: '#FFF',
                      boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
                      '&:hover': {
                        boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
                      },
                      '&:focus': {
                        boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
                      },
                    }}
                    displayEmpty
                    value={selectedValues[col.id]}
                    onChange={(event) => handleSearchTermChange(event, col.id)}
                  >
                    <MenuItem value="">Search {col.label}</MenuItem>
                    {col.id === 'bank_name' ? (
                      banks.map((bank) => (
                        <MenuItem value={bank.name} key={bank.name}>
                          {bank.name}
                        </MenuItem>
                      ))
                    ) : col.id === 'city' ? (
                      city.map((city) => (
                        <MenuItem value={city.name} key={city.name}>
                          {city.name}
                        </MenuItem>
                      ))
                    ) : (
                      <></>
                    )}
                  </Select>
                ) : col.id === 'state' ? (
                  <StateSelect
                    value={selectedValues.state}
                    onChange={(event) => handleSearchTermChange(event, 'state')}
                  />
                ) : col.id === 'is_active' ? (
                  <IsActiveSelect
                    value={selectedValues.is_active}
                    onChange={(event) =>
                      handleSearchTermChange(event, 'is_active')
                    }
                  />
                ) : col.id === 'user_type' ? (
                  <UserTypeSelect
                    value={selectedValues.user_type}
                    onChange={(event) =>
                      handleSearchTermChange(event, 'user_type')
                    }
                  />
                ) : col.id === 'status' ? (
                  <StatusSelect
                    value={selectedValues.status}
                    onChange={(event) =>
                      handleSearchTermChange(event, 'status')
                    }
                  />
                ) : (
                  <TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      padding: '12px 20px 12px 20px',
                      borderRadius: 4,
                      border: '2px solid #DEDEDE',
                      background: '#FFF',
                      boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
                      '&:hover': {
                        boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
                      },
                      '&:focus': {
                        boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
                      },
                    }}
                    placeholder={`Search ${col.label}`}
                    name={col.id}
                    key={col.id}
                    value={selectedValues[col.id]}
                    onChange={(event) => handleSearchTermChange(event, col.id)}
                  />
                )}
              </Grid>
            ))}
            <Grid item xs={12} md={2}>
              <Box sx={{ height: '100%' }}>
                <GradientPageButton name="Search" onClick={handleSearch} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <TableData
          columns={filteredColumns}
          setCurrentOrder={setCurrentOrder}
          rows={filteredRows}
          func={handleEditData}
          temp={temp}
          toggle={toggle}
          rowsPPage={rowsPerPage}
          pageNo={page}
          total={total}
          getPage={getPage}
          totalRecords={totalRecords}  
        />
      </Box>
    </Box>
  );
};

export default Page;
