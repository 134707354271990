const RedPointsCol = [
  'user_type',
  'name',
  'phone',
  'part_number',
  'scan_point',
  'location',
  'date',
];

export default RedPointsCol;
