import React from 'react';
import Page from '../pageData/page';
import RetailersDataCol from '../../components/tabledata/retailerTable/retailersDataCol';
import RetailersCol from '../../components/tabledata/retailerTable/retialersCol';
import { useRetailer } from '../../services/retailers/retailersService';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';
import RetailSearchCol from '../../components/tabledata/retailerTable/searchFields';

const Retailers = () => {
  const {
    loading,
    addData,
    allRetailData,
    deleteMech,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchRetailers,
  } = useRetailer();

  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_retailer_tab != (1||"") ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Page
              columns={RetailersDataCol}
              rows={allRetailData}
              data={RetailersCol}
              temp={'id'}
              showGradientButton={true}
              showQRButton={false}
              delData={deleteMech}
              addData={addData}
              updateData={updateData}
              toggle={active}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              total={noOfPages}
              getPage={getCurrentPage}
              totalRecords={totalRecords}
              searchFields={RetailSearchCol}
              search={searchRetailers}
            />
          )}
        </>
      )}
    </>
  );
};

export default Retailers;
