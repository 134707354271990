import React from 'react';
import PointsPage from './redeemPage';
import RedPointsDataCol from '../../components/tabledata/redeemPoints/redPointsDataCol';
import RedPointsSearchCol from '../../components/tabledata/redeemPoints/searchFields';
import RedPointsCol from '../../components/tabledata/redeemPoints/redPointsCol';
import {
  PointsProvider,
  usePoints,
} from '../../services/redeemPoints/rpService';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';

const RedeemPoints = () => {
  const {
    loading,
    allPointsData,
    rowsPerPage,
    currentPage,
    noOfPages,
    getScanPoints,
    start_date,
    end_date,
    getCurrentPage,
    totalRecords,
    searchRedeemPoints,
    searchData
  } = usePoints();

  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;
  return (
    <>
      {permissions.is_redeem_points_tab != (1 || "1") ? (
        <ErrorPage />
      ) : (
        <PointsProvider>
          <>
            {loading ? (
              <Spinner />
            ) : (
              <PointsPage
                columns={RedPointsDataCol}
                rows={allPointsData}
                data={RedPointsCol}
                getPoints={getScanPoints}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                total={noOfPages}
                getPage={getCurrentPage}
                loading={loading}
                start_date={start_date}
                end_date={end_date}
                totalRecords={totalRecords}
                searchFields={RedPointsSearchCol}
                search={searchRedeemPoints}
                searchData={searchData}
              />
            )}
          </>
        </PointsProvider>
      )}
    </>
  );
};

export default RedeemPoints;
