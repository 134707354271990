import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Button, Divider, Drawer } from '@mui/material';
import Image1 from '../../../tenant/utils/Minhas.png';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import KeyboardDoubleArrowDownSharpIcon from '@mui/icons-material/KeyboardDoubleArrowDownSharp';
import AdminPages from '../paths/adminPages';
import { useAdminAuth } from '../../../auth/superadmin/adminAuth';

const AdminNavbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const { logoutAdmin } = useAdminAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const location = useLocation();
  const userData = localStorage.getItem('adminData');
  const user = JSON.parse(userData);
  const [name, setName] = useState('');
  const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    try {
      await logoutAdmin();
      navigate('/superadmin/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handlePageClick = (page) => {
    setSelectedPage(page);
    navigate(page.link);
    handleCloseDrawer();
  };

  useEffect(() => {
    const currentLocation = location.pathname;
    const foundPage = AdminPages.find((page) => page.link === currentLocation);
    setSelectedPage(foundPage || null);
  }, [location]);

  useEffect(() => {
    setName(user.name);
  }, [user]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>Profile</MenuItem>
      <MenuItem>Change Password</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" color="icon.main">
          <MailOutlineRoundedIcon />
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton size="large" color="icon.main">
          <NotificationsNoneRoundedIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton size="large" color="icon.main">
          <SettingsOutlinedIcon />
        </IconButton>
        <p>Settings</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton size="large" color="icon.main">
          <DarkModeOutlinedIcon />
        </IconButton>
        <p>Darkmode</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="icon.main"
        >
          <Avatar alt="Remy Sharp" src={Image1} />
        </IconButton>
        <p>{name}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'background.main',
          boxShadow: 'none',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5" component="div" sx={{ color: '#737373' }}>
            Loyalty
          </Typography>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: '#737373',
              display: { xs: 'none', md: 'block' },
            }}
          >
            Logo
          </Typography>

          <Box sx={{ flexGrow: 1 }} />

          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenDrawer}
                  color="icon.main"
                >
                  <KeyboardDoubleArrowDownSharpIcon />
                </IconButton>
                <Drawer
                  anchor="top"
                  open={openDrawer}
                  onClose={handleCloseDrawer}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  PaperProps={{
                    sx: {
                      padding: '16px',
                      backgroundColor: 'background.main',
                    },
                  }}
                >
                  {AdminPages.map((page, index) => (
                    <Button
                      key={index}
                      onClick={() => handlePageClick(page)}
                      fullWidth
                      variant={selectedPage === page ? 'contained' : 'text'}
                      sx={{
                        color: 'icon.main',
                        marginBottom: '8px',
                        borderRadius: '20px',
                        ...(selectedPage === page && {
                          backgroundColor: 'button.menu',
                          color: 'text.primary',
                        }),
                      }}
                    >
                      {page.name}
                    </Button>
                  ))}
                </Drawer>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {AdminPages.map((page, index) => (
                  <Button
                    key={index}
                    onClick={() => handlePageClick(page)}
                    sx={{
                      my: 2,
                      color: 'text.primary',
                      display: 'inline-flex',
                      padding: '9px 18px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '4px',
                      backgroundColor:
                        selectedPage === page ? 'button.menu' : 'inherit',
                      marginLeft: '15px',
                      borderRadius: '20px',
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontWeight: 400,
                      lineHeight: '22px',
                      textTransform: 'capitalize',
                      ...(selectedPage === page && {
                        color: 'text.primary',
                        textAlign: 'center',
                        fontWeight: 600,
                        backgroundColor: 'button.menu',
                      }),
                      '&:hover': {
                        backgroundColor: 'button.menuhover',
                      },
                    }}
                    startIcon={
                      <span
                        style={{
                          position: 'absolute',
                          left: '14px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          width: '8px',
                          height: '8px',
                          borderRadius: '50%',
                          backgroundColor:
                            selectedPage === page ? 'black' : '#dedede',
                        }}
                      />
                    }
                  >
                    {page.name}
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </Container>
          <Box
            sx={{ display: { md: 'flex', xs: 'none' }, alignItems: 'center' }}
          >
            <IconButton size="large" color="icon.main">
              <DarkModeOutlinedIcon />
            </IconButton>
            {/* <IconButton
              size="large"
              aria-label="new notifications"
              color="icon.main"
            >
              <SettingsOutlinedIcon />
            </IconButton> */}
            {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="icon.main"
            >
              <MailOutlineRoundedIcon />
            </IconButton> */}
            <IconButton size="large" color="icon.main">
              <NotificationsNoneRoundedIcon />
            </IconButton>

            <Divider orientation="vertical" variant="middle" flexItem />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '15px',
              }}
            >
              <Avatar alt="Remy Sharp" src={Image1} />
              <Button
                variant="text"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={handleProfileMenuOpen}
                sx={{
                  color: 'text.primary',
                  textTransform: 'none',
                  marginLeft: '10px',
                }}
              >
                {name}
              </Button>
              {renderMenu}
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="icon.main"
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
};

export default AdminNavbar;
