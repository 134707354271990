const GiftCol = [
  'name',
  'description',
  'mrp',
  'points',
  'is_active',
  'actions',
];

export default GiftCol;
