import React from 'react';
import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';

const TenantErrorPage = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#ffb000',
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ backgroundColor: '#ffb000', position: 'relative' }}>
        <Container maxWidth="md">
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ zIndex: 1, position: 'relative' }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: '90px',
                  color: '#150035',
                  marginTop: '100px',
                  fontWeight: 900,
                  whiteSpace: 'pre-line',
                  lineHeight: 1,
                  letterSpacing: '3px',
                  textShadow: '1px 7px 1px #fff',
                }}
              >
                500 ERROR
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '60px',
                  color: '#150035',
                  marginTop: '25px',
                  fontWeight: 400,
                  lineHeight: 1,
                  letterSpacing: '3px',
                  textShadow: '1px 7px 1px #fff',
                }}
              >
                System Error
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '50px',
                  color: '#150035',
                  marginTop: '5px',
                  fontWeight: 400,
                  lineHeight: 1,
                  letterSpacing: '3px',
                  textShadow: '1px 7px 1px #fff',
                }}
              >
                Tenant not defined in the URL
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default TenantErrorPage;
