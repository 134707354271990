import React from 'react';
import ManageTenantPage from './manageTenantPage';
import AdminDataCol from '../dataAdminFiles/adminDataCol';
import AdminCol from '../dataAdminFiles/adminCol';
import { useManageTenetContext } from '../services/manageTenetService';
import Spinner from '../../commons/spinner';

function ManageTenant() {
  const {
    getTenet,
    isLoading,
    deleteTenet,
    updateData,
    addData,
    rowsPrPage,
    currentPage,
    noOfPages,
    fetchTenant,
  } = useManageTenetContext();

  if (!getTenet) {
    return <Spinner />;
  }
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <ManageTenantPage
          columns={AdminDataCol}
          rows={getTenet}
          data={AdminCol}
          temp={'id'}
          delData={deleteTenet}
          updateData={updateData}
          addData={addData}
          showGradientButton={true}
          rowsPerPage={rowsPrPage}
          page={currentPage}
          total={noOfPages}
          getPage={fetchTenant}
        />
      )}
    </>
  );
}

export default ManageTenant;
