const RedPointsSearchCol = [
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
];

export default RedPointsSearchCol;
