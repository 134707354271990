import { Outlet } from 'react-router-dom';
import NavBar from '../../components/navbar/navbar';
import GradientBox from '../../../commons/gradiantComponents/gradientBox';

const TenantLayout = () => {
  return (
    <GradientBox>
      <NavBar />
      <Outlet />
    </GradientBox>
  );
};

export default TenantLayout;
