import React from 'react';
import Page from '../pageData/page';
import { useRedeemRequestContext } from '../../services/redeemRequestContext/redeemRequestContext';
import RedeemRequestDataCol from '../../components/tabledata/redeemRequestTable/redeemRequestDataCol';
import RedeemRequestSearchCol from '../../components/tabledata/redeemRequestTable/searchFieldsNEFT';
import RedeemRequestCol from '../../components/tabledata/redeemRequestTable/redeemRequestCol';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';

const RedeemRequest = () => {
  const {
    RedeemRequest,
    loading,
    rowsPrPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchRedeemReqs,
  } = useRedeemRequestContext();

  if (loading) {
    return <Spinner />;
  }
  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_redeem_request_tab != 1 ? (
        <ErrorPage />
      ) : (
        <>
          <Page
            columns={RedeemRequestDataCol}
            rows={RedeemRequest}
            data={RedeemRequestCol}
            temp={'id'}
            showGradientButton={false}
            showQRButton={false}
            rowsPerPage={rowsPrPage}
            page={currentPage}
            total={noOfPages}
            getPage={getCurrentPage}
            totalRecords={totalRecords}
            searchFields={RedeemRequestSearchCol}
            search={searchRedeemReqs}
          />
        </>
      )}
    </>
  );
};

export default RedeemRequest;
