import React from 'react';
import Page from '../pageData/page';
import MechanicCol from '../../components/tabledata/mechanicTable/mechanicCol';
import MechDataCol from '../../components/tabledata/mechanicTable/machDataCol';
import { useMechanic } from '../../services/mechanics/mechanicsService';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';
import MechanicSearchCol from '../../components/tabledata/mechanicTable/searchFields';

const Mechanics = () => {
  const {
    loading,
    addData,
    allMechData,
    deleteMech,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchMechanics,
  } = useMechanic();

  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_mechanic_tab != (1||"1") ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Page
              columns={MechDataCol}
              rows={allMechData}
              data={MechanicCol}
              temp={'id'}
              showGradientButton={true}
              showQRButton={false}
              delData={deleteMech}
              addData={addData}
              updateData={updateData}
              toggle={active}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              total={noOfPages}
              getPage={getCurrentPage}
              totalRecords={totalRecords}
              searchFields={MechanicSearchCol}
              search={searchMechanics}
            />
          )}
        </>
      )}
    </>
  );
};

export default Mechanics;
