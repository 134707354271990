import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../tenant/pages/login/login';
import Dashboard from '../tenant/pages/dashboard/dashboard';
import Mechanics from '../tenant/pages/mechanics/mechanics';
import Products from '../tenant/pages/products/products';
import ProductCategory from '../tenant/pages/products/productCategory';
import ProductsQR from '../tenant/pages/products/productsQR';
import RedeemRequest from '../tenant/pages/redeemRequests/redeemReq';
import RedeemRequestGift from '../tenant/pages/redeemRequests/redeemReqGift';
import Retailers from '../tenant/pages/retailers/retailers';
import Distributors from '../tenant/pages/distributors/distributors';
import Orders from '../tenant/pages/orders/orders';
import MarketingExecutives from '../tenant/pages/marketingExecutives/marketingExecutives';
import RedeemPoints from '../tenant/pages/redeemPoints/redeemPoints';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../theme';
import ManageTenant from '../admin/manageTenants/manageTenants';
import AdminDashboard from '../admin/dashboard/adminDashboard';
import AdminLogin from '../admin/adminLogin/adminLogin';
import { TenantAuthProvider, useTenantAuth } from '../auth/tenant/auth';
import { ManageTenetProvider } from '../admin/services/manageTenetService';
import { DashboardProvider } from '../tenant/pages/dashboard/dashboardContext';
import { RedeemRequestProvider } from '../tenant/services/redeemRequestContext/redeemRequestContext';
import { RedeemRequestGiftProvider } from '../tenant/services/redeemRequestContext/redeemRequestGiftContext';
import { MechanicProvider } from '../tenant/services/mechanics/mechanicsService';
import { RetailerProvider } from '../tenant/services/retailers/retailersService';
import { DistributorProvider } from '../tenant/services/distributors/distributorsService';
import { OrderProvider } from '../tenant/services/orders/ordersService';
import { MarketingExecutiveProvider } from '../tenant/services/marketingExecutives/marketingExecutivesService';
import { ProductProvider } from '../tenant/services/products/productService';
import { ProductCategroyProvider } from '../tenant/services/products/productCategory';
import { PointsProvider } from '../tenant/services/redeemPoints/rpService';
import { AdminAuthProvider } from '../auth/superadmin/adminAuth';
import ProtectedRoute from '../auth/tenant/protected';
import NonProtectedRoute from '../auth/tenant/nonProtected';
import AdminNonLoginRoute from '../auth/superadmin/adminNonProtected';
import AdminProtectedRoute from '../auth/superadmin/adminProtected';
import ErrorPage from '../tenant/utils/errorPage404';
import { UtilityProvider, } from '../tenant/services/utilityContext/useUtility';
import { SnackbarProvider } from '../commons/snackBarContextProvider';
import TenantLayout from '../tenant/pages/layout/tenantLayout';
import AdminLayout from '../admin/layout/adminLayout';
import Gifts from '../tenant/pages/gifts/gifts';
import { GiftProvider } from '../tenant/services/gifts/giftServices';
import TenantErrorPage from '../commons/tenantErrorPage';
import ViewOrder from '../tenant/pages/orders/viewOrder/viewOrder';
import OrderViewPage from '../tenant/pages/viewPage/OrderViewPage';
const RoutesInfo = () => {
const {loading}=useTenantAuth();
    const userData = JSON.parse(localStorage.getItem('userdata')) || '';
    const { tenant_nav_bar_permission } = userData ?? []
    console.log("tenant_nav_bar_permission", tenant_nav_bar_permission)

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/error" element={<TenantErrorPage />} />
                    <Route element={<NonProtectedRoute />}>
                        <Route path="/login" element={<Login />} />
                    </Route>
                    <Route element={<ProtectedRoute />}>
                        <Route
                            path="/*"
                            element={
                                <>
                                    <Routes>
                                        <Route element={<TenantLayout />}>
                                            <Route
                                                path="/"
                                                element={
                                                    <DashboardProvider>
                                                        <Dashboard />
                                                    </DashboardProvider>
                                                }
                                            />
                                            <Route
                                                path="vieworders/:orderId"
                                                element={
                                                    tenant_nav_bar_permission?.is_order_tab == (1 || "1") ?
                                                        <OrderProvider>
                                                            <OrderViewPage />
                                                        </OrderProvider>
                                                        : <ErrorPage />
                                                }
                                            />
                                            <Route
                                                path="product"
                                                element={
                                                    tenant_nav_bar_permission?.is_products_tab == (1 || "1") ?
                                                        <ProductProvider>
                                                            <Products />
                                                        </ProductProvider>
                                                        :
                                                        <ErrorPage />
                                                }
                                            />
                                            <Route
                                                path="vieworder"
                                                element={
                                                    <ProductProvider>
                                                        <ViewOrder />
                                                    </ProductProvider>
                                                }
                                            />
                                            <Route
                                                path="productcategory"
                                                element={
                                                    tenant_nav_bar_permission?.is_products_tab == (1 || "1") ?

                                                        <ProductCategroyProvider>
                                                            <ProductCategory />
                                                        </ProductCategroyProvider>
                                                        :
                                                        <ErrorPage />
                                                }
                                            />
                                            <Route
                                                path="productsQR"
                                                element={
                                                    tenant_nav_bar_permission?.is_products_tab == (1 || "1") ?
                                                        <ProductsQR />
                                                        :
                                                        <ErrorPage />
                                                }
                                            />
                                            <Route
                                                path="retailer"
                                                element={
                                                    tenant_nav_bar_permission?.is_retailer_tab == (1 || "1") ?
                                                        <RetailerProvider>
                                                            <Retailers />
                                                        </RetailerProvider>
                                                        :
                                                        <ErrorPage />

                                                }
                                            />
                                            <Route
                                                path="distributor"
                                                element={
                                                    tenant_nav_bar_permission?.is_distributor_tab == (1 || "1") ?
                                                        <DistributorProvider>
                                                            <Distributors />
                                                        </DistributorProvider>
                                                        : <ErrorPage />
                                                }
                                            />
                                            <Route
                                                path="orders"
                                                element={
                                                    tenant_nav_bar_permission?.is_order_tab == (1 || "1") ?
                                                        <OrderProvider>
                                                            <Orders />
                                                        </OrderProvider>
                                                        : <ErrorPage />

                                                }
                                            />
                                            <Route
                                                path="marketingExecutive"
                                                element={
                                                    tenant_nav_bar_permission?.is_marketing_executive_tab == (1 || "1") ?
                                                        <MarketingExecutiveProvider>
                                                            <MarketingExecutives />
                                                        </MarketingExecutiveProvider>
                                                        : <ErrorPage />

                                                }
                                            />
                                            <Route
                                                path="mechanic"
                                                element={
                                                    tenant_nav_bar_permission?.is_mechanic_tab == (1 || "1") ?
                                                        <MechanicProvider>
                                                            <Mechanics />
                                                        </MechanicProvider>
                                                        : <ErrorPage />

                                                }
                                            />
                                            <Route
                                                path="redeempoint"
                                                element={
                                                    tenant_nav_bar_permission?.is_redeem_points_tab == (1 || "1") ?

                                                        <PointsProvider>
                                                            <RedeemPoints />
                                                        </PointsProvider>
                                                        : <ErrorPage />

                                                }
                                            />
                                            <Route
                                                path="redeemrequest"
                                                element={
                                                    tenant_nav_bar_permission?.is_redeem_request_tab == (1 || "1") ?

                                                        <RedeemRequestProvider>
                                                            <RedeemRequest />
                                                        </RedeemRequestProvider>
                                                        : <ErrorPage />

                                                }
                                            />
                                            <Route
                                                path="redeemrequestgift"
                                                element={
                                                    tenant_nav_bar_permission?.is_gift_tab == (1 || "1") ?

                                                        <RedeemRequestGiftProvider>
                                                            <RedeemRequestGift />
                                                        </RedeemRequestGiftProvider>
                                                        : <ErrorPage />

                                                }
                                            />
                                            <Route
                                                path="gifts"
                                                element={
                                                    tenant_nav_bar_permission?.is_gift_tab == (1 || "1") ?
                                                        <GiftProvider>
                                                            <Gifts />
                                                        </GiftProvider>
                                                        : <ErrorPage />

                                                }
                                            />
                                            <Route path="*" element={<ErrorPage />}></Route>
                                        </Route>
                                    </Routes>
                                </>
                            }
                        />
                    </Route>
                    <Route element={<AdminNonLoginRoute />}>
                        <Route
                            path="/superadmin/login"
                            element={<AdminLogin />}
                        />
                    </Route>
                    <Route element={<AdminProtectedRoute />}>
                        <Route
                            path="/superadmin/*"
                            element={
                                <>
                                    <Routes>
                                        <Route element={<AdminLayout />}>
                                            <Route path="/" element={<AdminDashboard />} />
                                            <Route
                                                path="/dashboard"
                                                element={<AdminDashboard />}
                                            />
                                            <Route
                                                path="/managetenant"
                                                element={
                                                    <ManageTenetProvider>
                                                        <ManageTenant />
                                                    </ManageTenetProvider>
                                                }
                                            />
                                            <Route path="*" element={<ErrorPage />}></Route>
                                        </Route>
                                    </Routes>
                                </>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default RoutesInfo