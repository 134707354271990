import React from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Container } from '@mui/system';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
  },
};

const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: '20px',
  background: theme.palette.secondary.color,
  boxShadow: '0px 4px 16px 0px rgba(41, 27, 4, 0.08)',
  padding: "25px",
}));

const LineChartTypo = styled(Typography)({
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: 'capitalize',
  paddingTop: '25px',
});

const LineChartSeconTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.earnedPointsSeconday.color,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  textTransform: 'capitalize',
  paddingTop: '20px',
  paddingBottom: '10px',
}));

export function LineChart({ graphInfo }) {
  const theme = useTheme();

  const data = {
    labels,
    datasets: [
      {
        label: 'Redeem Requests',
        data: graphInfo?.redeemed_values || [],
        borderColor: theme.palette.lineChartColors.dataSetOneColor,
        backgroundColor: theme.palette.lineChartColors.backgroundColorLineOne,
        cubicInterpolationMode: 'monotone',
      },
      {
        label: 'Coupons Scanned',
        data: graphInfo?.scanned_values || [],
        borderColor: theme.palette.lineChartColors.dataSetTwoColor,
        backgroundColor: theme.palette.lineChartColors.backgroundColorLineTwo,

        cubicInterpolationMode: 'monotone',
      },
    ],
  };

  return (
    <StyledBox>
      {/* <Container> */}
      <LineChartTypo>Performance Line Chart</LineChartTypo>
      <LineChartSeconTypo>
        Your performance summary this year
      </LineChartSeconTypo>
      <Line options={options} data={data} />
      {/* </Container> */}
    </StyledBox>
  );
}
