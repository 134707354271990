import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import ThrowError from '../../utils/errors';
import { TENANT_URL } from '../../../config';

const OrderContext = createContext();
const OrderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [allOrderData, setAllOrderData] = useState([]);
  const [OrderData, setOrderData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [qrCodeOrderList, setQrCodeOrderList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [order, setOrder] = useState({});
  // const [currentOrder, setCurrentOrder] = useState(null);
  const { show } = useSnackbar();
  const addData = async (userData) => {
    userData.is_active = true;
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const setData = {
      method: 'post',
      url: `${TENANT_URL}/orders`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);

    try {
      await axios({ ...setData, data: data });
      show('Order Added Successfully');
      await getCurrentPage(currentPage);
    } catch (error) {
      ThrowError(error, show);
    }

    setLoading(false);
  };

  const getSingleData = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/orders/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setLoading(true);
    try {
      const response = await axios(getConfig);
      setOrderData(response.data.data);
    } catch (error) {
      console.error(error);
      show('User Not Found', 'error');
    }
    setLoading(false);
  };

  const getCurrentPage = async (pageNo) => {
    setCurrentPage(pageNo);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/orders?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);

      setAllOrderData(response.data.data);
    } catch (error) {
      console.error(error);
      show('Data not Updated', 'error');
    }
  };


  const getPage = async (pageNo) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/orders?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRowsPerPage(response.data.meta.per_page);
      setCurrentPage(response.data.meta.current_page);
      setNoOfPages(Math.ceil(response.data.meta.total / 10));
      setTotalRecords(response.data.meta.total);
    } catch (error) {
      console.error(error);
      show('Data not Updated', 'error');
    }
  };

  const deleteMech = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/orders/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(deleteConfig);
      await getCurrentPage(currentPage);
      show('Order Deleted Successfully');
    } catch (error) {
      console.error(error);
      show('Order Not Deleted', 'error');
    }
  };

  const updateData = async (userData, id) => {
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const setData = {
      method: 'put',
      url: `${TENANT_URL}/orders/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    try {
      await axios({ ...setData, data: data });
      show('Order Updated');
      await getCurrentPage(currentPage);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };

  const active = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var getConfig = {
      method: 'get',
      url: `${TENANT_URL}/active/orders/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(getConfig);
      await getCurrentPage(currentPage);
    } catch (error) {
      console.error(error);
    }
  };

  const searchOrders = async (searchData) => {
    var data = JSON.stringify(searchData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const setData = {
      method: 'post',
      url: `${TENANT_URL}/search/orders`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    try {
      const response = await axios({ ...setData, data: data });
      setAllOrderData(response.data.data);
      setRowsPerPage(100);
      setCurrentPage(1);
      setNoOfPages(1);
      setTotalRecords(10);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getPage();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    
    getCurrentPage(currentPage);

    // eslint-disable-next-line
  }, [currentPage]);

  const setCurrentOrder =  (obj) => {
    setOrder(obj);
  }
  const getOrderById = async (id)=>{
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var getConfig = {
      method: 'get',
      url: `${TENANT_URL}/orders/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
     const res =  await axios(getConfig);
     if (res?.data?.data){
      setOrder(res.data.data)
       return res.data.data
     }
     else {
      setOrder({})
      return {}
     }
    } catch (error) {
      console.error(error);
    }
  }

  const contextValue = {
    order,
    setLoading,
    loading,
    addData,
    allOrderData,
    getSingleData,
    OrderData,
    getCurrentPage,
    deleteMech,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getPage,
    totalRecords,
    searchOrders,
    setCurrentOrder,
    currentPage,
    getOrderById
    // qrCodeOrderList,
    // fetchQrCodeOrderList,
  };
 


  return (
    <OrderContext.Provider value={contextValue}>
      {children}
    </OrderContext.Provider>
  );
};

const useOrder = () => useContext(OrderContext);

export { OrderProvider, useOrder };
