const FormInitialValues = {
  shopname: '',
  name: '',
  email: '',
  password: '',
  account_number: '',
  adhaar_number: '',
  company_name: '',
  pan_card_number: '',
};

export default FormInitialValues;
