const AdminDataCol = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'company_name',
    numeric: false,
    disablePadding: false,
    label: 'Company Name',
  },
  {
    id: 'password',
    numeric: false,
    disablePadding: false,
    label: 'Password',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    label: 'State',
  },
  {
    id: 'gst_number',
    numeric: false,
    disablePadding: false,
    label: 'GST Number',
  },
  {
    id: 'pan_card_number',
    numeric: false,
    disablePadding: false,
    label: 'Pan Card Number',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    label: 'Address',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Is Active',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Action',
  },
  {
    id: 'is_retailer_tab',
    numeric: false,
    disablePadding: false,
    label: 'Retailer Tab',
    isPermissionField: true,
  },
  {
    id: 'is_mechanic_tab',
    numeric: false,
    disablePadding: false,
    label: 'Mechanic Tab',
    isPermissionField: true,
  },
  {
    id: 'is_products_tab',
    numeric: false,
    disablePadding: false,
    label: 'Products Tab',
    isPermissionField: true,
  },
  {
    id: 'is_redeem_request_tab',
    numeric: false,
    disablePadding: false,
    label: 'Redeem Request Tab',
    isPermissionField: true,
  },
  {
    id: 'is_redeem_points_tab',
    numeric: false,
    disablePadding: false,
    label: 'Reports',
    isPermissionField: true,
  },
  {
    id: 'is_order_tab',
    numeric: false,
    disablePadding: false,
    label: 'Orders Tab',
    isPermissionField: true,
  },
  {
    id: 'is_distributor_tab',
    numeric: false,
    disablePadding: false,
    label: 'Distributor Tab',
    isPermissionField: true,
  },
  {
    id: 'is_marketing_executive_tab',
    numeric: false,
    disablePadding: false,
    label: 'ME Tab',
    isPermissionField: true,
  },
  {
    id: 'is_gift_tab',
    numeric: false,
    disablePadding: false,
    label: 'Gift Tab',
    isPermissionField: true,
  },
  {
    id: 'is_points_tab',
    numeric: false,
    disablePadding: false,
    label: 'Points Tab',
    isPermissionField: true,
  },
  {
    id: 'permission',
    numeric: false,
    disablePadding: false,
    label: 'Permissions',
  },
];

export default AdminDataCol;
