const AdminCol = [
  'id',
  'name',
  'company_name',
  'password',
  'email',
  'phone',
  'country',
  'state',
  'gst_number',
  'pan_card_number',
  'address',
  'isActive',
  'actions',
  'permission',
];

export default AdminCol;
