import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../commons/snackBarContextProvider';
import { TENANT_URL } from '../../config';

const TenantAuthContext = createContext();

const TenantAuthProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { show } = useSnackbar();

  const redirectToError = () => {
    const adminRoutes = ['/superadmin'];

    const isAdminRoute = adminRoutes.some((route) =>
      window.location.pathname.startsWith(route)
    );

    const hasTenantUrl = TENANT_URL !== null;

    if (
      !isAdminRoute &&
      !hasTenantUrl &&
      window.location.pathname !== '/error'
    ) {
      window.location.href = '/error';
    }
  };

  useEffect(() => {
    // TODO: extract this to a custom hook/context
    redirectToError();
  }, []);

  const login = async (userData) => {
    setLoading(true);
    try {
      const response = await axios.post(`${TENANT_URL}/tenant/login`, userData);

      if (response.data.success) {
        const data = response.data.data;
        localStorage.setItem('userdata', JSON.stringify(data));
        show('Logged in');
      }
    } catch (error) {
      show('Incorrect Data', 'error');
    }
    setLoading(false);
  };

  const logout = async () => {
    setLoading(true);
    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      await axios.get(`${TENANT_URL}/logout/tenant/user`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUser(false);
      localStorage.removeItem('userdata');
      show('Logged out successfully');
    } catch (error) {
      console.error(error);
      show('Error logging out', 'error');
    }
    setLoading(false);
  };

  const contextValue = {
    user,
    login,
    logout,
    loading,
    setUser,
    setLoading,
  };

  return (
    <TenantAuthContext.Provider value={contextValue}>
      {children}
    </TenantAuthContext.Provider>
  );
};

const useTenantAuth = () => useContext(TenantAuthContext);

export { TenantAuthProvider, useTenantAuth };
