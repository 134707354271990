import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../commons/snackBarContextProvider';
import ThrowError from '../../tenant/utils/errors';

const ManageTenetContext = createContext();

export const useManageTenetContext = () => useContext(ManageTenetContext);

export const ManageTenetProvider = ({ children }) => {
  const [getTenet, setGetTenet] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPrPage, setRowsPrPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const { show } = useSnackbar();

  const fetchTenet = async (pageNo) => {
    setNoOfPages(pageNo);
    const token = JSON.parse(localStorage.getItem('adminData')).token;
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ADMIN}/superadmin/tenant?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setGetTenet(res.data.data);
      show('Tenant Fetched');
    } catch (error) {
      show('Failed to fetch tenant.', 'error');
    }
  };

  const fetchPageData = async () => {
    const token = JSON.parse(localStorage.getItem('adminData')).token;
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ADMIN}/superadmin/tenant?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token},
          `,
          },
        }
      );
      setRowsPrPage(res.data.meta.per_page);
      setCurrentPage(res.data.meta.current_page);
      setNoOfPages(Math.ceil(res.data.meta.total / 10));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteTenet = async (id) => {
    const token = JSON.parse(localStorage.getItem('adminData')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${process.env.REACT_APP_ADMIN}/superadmin/tenant/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(deleteConfig);
      await fetchTenet(currentPage);
      show('Tenant Deleted');
    } catch (error) {
      show('Failed to delete tenant.', 'error');
    }
  };

  const addData = async (userData) => {
    userData.is_active = true;
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('adminData')).token;

    const setData = {
      method: 'post',
      url: `${process.env.REACT_APP_ADMIN}/superadmin/tenant`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setIsLoading(true);

    try {
      await axios({ ...setData, data: data });
      await fetchTenet(currentPage);
      show('Tenant added');
    } catch (error) {
      ThrowError(error, show);
    }
    setIsLoading(false);
  };

  const updateData = async (userData, id) => {
    const dataToSend = {
      company_name: userData.company_name,
      phone: userData.phone,
      country: userData.country,
      state: userData.state,
      gst_number: userData.gst_number,
      address: userData.address,
      pan_card_number: userData.pan_card_number,
      password: userData.password,
      email: userData.email,
      is_active: userData.is_active,
    };

    const data = JSON.stringify(dataToSend);
    const token = JSON.parse(localStorage.getItem('adminData')).token;
    const setData = {
      method: 'put',
      url: `${process.env.REACT_APP_ADMIN}/superadmin/tenant/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setIsLoading(true);
    try {
      await axios({ ...setData, data: data });
      await fetchTenet(currentPage);
      show('Tenant updated');
    } catch (error) {
      ThrowError(error, show);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchPageData();
    setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchTenet(currentPage);
    // eslint-disable-next-line
  }, []);

  const values = {
    getTenet,
    isLoading,
    rowsPrPage,
    currentPage,
    noOfPages,
    deleteTenet,
    updateData,
    addData,
    fetchPageData,
  };

  return (
    <ManageTenetContext.Provider value={values}>
      {children}
    </ManageTenetContext.Provider>
  );
};
