const RetailersCol = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'shop_name',
    numeric: false,
    disablePadding: true,
    required: true,
    label: 'Shop Name',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    required: true,
    label: 'Retailer Name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'contact_number',
    numeric: true,
    disablePadding: true,
    required: true,
    label: 'Phone',
  },
  {
    id: 'gst_number',
    numeric: false,
    disablePadding: false,
    label: 'GST Number',
  },
  {
    id: 'pan_number',
    numeric: false,
    disablePadding: false,
    label: 'PAN Number',
  },
  {
    id: 'aadhar_number',
    numeric: false,
    disablePadding: false,
    label: 'Aadhar Number',
  },
  {
    id: 'password',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'Password',
  },
  {
    id: 'bank_name',
    numeric: false,
    disablePadding: false,
    label: 'Bank',
  },
  {
    id: 'account_number',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'Account Number',
  },
  {
    id: 'ifsc_code',
    numeric: false,
    disablePadding: true,
    required: true,
    label: 'IFSC Code',
  },
  {
    id: 'address',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'Postal Address',
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'State',
  },
  {
    id: 'state_name',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'State',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'City',
  },
  {
    id: 'total_points',
    numeric: true,
    disablePadding: false,
    label: 'Total Points',
  },
  {
    id: 'avaiable_points',
    numeric: true,
    disablePadding: false,
    label: 'Available Points',
  },
  {
    id: 'pin_code',
    numeric: false,
    disablePadding: false,
    label: 'Pin Code',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Is Active',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

export default RetailersCol;
