import React, { useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { TENANT_URL } from '../../../config';
import CloseIcon from '@mui/icons-material/Close';
// import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Person } from '@mui/icons-material';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneRoundedIcon from '@mui/icons-material/NotificationsNoneRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Button, Drawer, TextField } from '@mui/material';
// import Image1 from '../../utils/Minhas.png';
import { useTenantAuth } from '../../../auth/tenant/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import KeyboardDoubleArrowDownSharpIcon from '@mui/icons-material/KeyboardDoubleArrowDownSharp';
import TenantPages from '../paths/tenantPages';
import NavDropdown from './dropDown';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import axios from 'axios';
import ProductList from './productList';
import RedReqList from './redReqList';
import UserList from './userList';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import GradientButton from '../../../commons/gradiantComponents/gradientButtonPage';
import UploadFileIcon from '@mui/icons-material/UploadFile';


const NavBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const { logout } = useTenantAuth();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const location = useLocation();
  const userData = localStorage.getItem('userdata');
  const user = JSON.parse(userData);
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const pages = TenantPages();
  const redReqPages = RedReqList();
  const prodPages = ProductList();
  const userPages = UserList();
  const fileSelectorRef = useRef(null)

  const CustomButton = styled(Button)(({ theme }) => ({
    height: "3rem",
    marginTop: "1rem",
    fontWeight: "bold",
    fontSize: "20px",
    // background:"#f1f4f7",
    background: theme.palette.button.gradient,
    borderRadius: '12px',
    color: theme.palette.text.primary,
    width: "100%",
    '&:hover': {
      background: theme.palette.button.gradient,
    },
  })
  )
  //Handle Active Links For Dropdown
  const [selectedUserPage, setSelectedUserPage] = useState(null);
  const [selectedReportPage, setSelectedReportPage] = useState(null);
  const [selectedProdPage, setSelectedProdPage] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { show } = useSnackbar();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const handleClose = () => {
    setShowNewModal(false);
  };
  const handleNewClose = () => {
    setShowModal(!showModal);
  };

  const updatePassword = async () => {
    setLoading(true);

    // Validation for current password
    if (!currentPassword) {
      setCurrentPasswordError('Current password is required');
      setLoading(false);
      return;
    }
    setCurrentPasswordError('');

    // Validation for new password
    if (!newPassword) {
      setNewPasswordError('New password is required');
      setLoading(false);
      return;
    } else if (newPassword.length < 8) {
      setNewPasswordError('New password must be at least 8 characters');
      setLoading(false);
      return;
    }
    setNewPasswordError('');

    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      await axios.post(
        `${TENANT_URL}/user/web/update/password`,
        {
          current_password: currentPassword,
          new_password: newPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      show('Password updated successfully');
      handleClose()
    } catch (error) {
      console.error(error);

      if (error.response) {
        const status = error.response.status;
        if (status === 401) {
          // Incorrect current password
          setCurrentPasswordError('Incorrect current password');
        } else {
          // Handle other error scenarios
          show('Error updating password', 'error');
        }
      } else {
        show('Error updating password', 'error');
      }
    }

    setLoading(false);
  };

  const handleProfileData = async (file) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const formData = new FormData();
    formData.append('company_logo', file);
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    const postConfig = {
      method: 'post',
      url: `${TENANT_URL}/tenant/profile/update`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };


    setLoading(true);
    try {
      const response = await axios(postConfig);
      // Handle the response as needed
      const { data } = response.data
      console.log(response.data);
      let userInfo = JSON.parse(localStorage.getItem('userdata'))
      userInfo.company_logo = data.company_logo
      console.log("userInfo", { ...userInfo })
      localStorage.setItem('userdata', JSON.stringify(userInfo))
      handleNewClose()
    } catch (error) {
      console.error(error);
      // Handle the error as needed

    }
    setLoading(false);
  };

  const handleOpen = () => {
    setShowNewModal(!showNewModal);
  };
  const handleNewOpen = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const currentLocation = location.pathname;

    const foundUserPage = userPages.find((page) => page.link === currentLocation);
    setSelectedUserPage(foundUserPage || null);

    const foundRedReqPage = redReqPages.find((page) => page.link === currentLocation);
    setSelectedReportPage(foundRedReqPage || null);

    const foundProdPage = prodPages.find((page) => page.link === currentLocation);
    setSelectedProdPage(foundProdPage || null);



    // eslint-disable-next-line
  }, [location, userPages, redReqPages, prodPages]);



  const otherNavLinks = [
    {
      'name': 'User',
      'link': userPages,
      'getState': selectedUserPage,
      'setState': setSelectedUserPage
    },
    {
      'name': 'Products',
      'link': prodPages,
      'getState': selectedProdPage,
      'setState': setSelectedProdPage
    },
    {
      'name': 'Redeem Request(s)',
      'link': redReqPages,
      'getState': selectedReportPage,
      'setState': setSelectedReportPage
    },
  ];


  // const order = ['Dashboard', 'User', 'Products', 'Redeem Request(s)', 'Reports'];
  const order = ['Dashboard', 'User', 'Products', 'Orders', 'Redeem Request(s)', 'Reports'];

  // Merge arrays and sort based on the order array
  const allNavLinks = [...pages, ...otherNavLinks].filter(item => item.link.length > 0) // Filter out items with empty link arrays
    .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
  // const allNavLinks = [...pages, ...otherNavLinks].sort((a, b) => {
  //   console.log("checking", a)
  //   if (a?.link.length > 0)
  //     return order.indexOf(a.name) - order.indexOf(b.name);
  // });



  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handlePageClick = (page) => {
    setSelectedPage(page);
    navigate(page.link);
    handleCloseDrawer();
  };

  useEffect(() => {
    const currentLocation = location.pathname;
    const foundPage = pages.find((page) => page.link === currentLocation);
    setSelectedPage(foundPage || null);
    // eslint-disable-next-line
  }, [location, pages]);

  useEffect(() => {
    setName(user.name);
  }, [user]);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        '& .MuiMenu-paper': {
          backgroundColor: 'background.main',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          borderRadius: '10px',
          position: 'absolute',
        },
      }}
    >
      <MenuItem onClick={handleNewOpen}>Profile</MenuItem>
      <MenuItem onClick={handleOpen}>Change Password</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" color="icon.main">
          <MailOutlineRoundedIcon />
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton size="large" color="icon.main">
          <NotificationsNoneRoundedIcon />
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton size="large" color="icon.main">
          <SettingsOutlinedIcon />
        </IconButton>
        <p>Settings</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton size="large" color="icon.main">
          <DarkModeOutlinedIcon />
        </IconButton>
        <p>Darkmode</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          color="icon.main"
        >
          <Avatar >
            <Person />
          </Avatar>
        </IconButton>
        <p>{name}</p>
      </MenuItem>
    </Menu>
  );

  return (
    <>

      <div>
        <Dialog
          open={showNewModal}
          onClose={handleClose}
          // aria-labelledby="customized-dialog-title"
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: 'background.paper',
              boxShadow: 'none',
              borderRadius: '10px',
              width: '100%',
              maxWidth: '500px',
            },
            '& button.MuiButtonBase-root': {
              fontFamily: 'Poppins',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              textTransform: 'capitalize',
              borderRadius: '10px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'button.menuhover',
                boxShadow: 'none',
              },
            },
          }}
        >
          <Box sx={{ background: "#FABF59", height: "3rem", display: "flex", justifyContent: 'space-between', alignItems: 'center', }}>
            <Box sx={{ textTransform: 'capitalize', fontSize: "25px", marginLeft: "1rem", '@media (max-width: 344px)': { fontSize: "15px", } }}>Change Password</Box>
            <IconButton onClick={handleClose} color="inherit" >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle id="customized-dialog-title">
            <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px", fontWeight: "bold" }}>Current Password:</Typography>
            <TextField
              sx={{ width: "100%", margin: "1px 0px 5px 0px" }}
              placeholder='Current Password'
              type={currentPasswordVisible ? 'text' : 'password'} // Toggle visibility
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              error={Boolean(currentPasswordError)}
              helperText={currentPasswordError}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setCurrentPasswordVisible(!currentPasswordVisible)}
                    size="large"
                    edge="end"
                  >
                    {currentPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
            <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px", fontWeight: "bold" }}>
              New Password:
            </Typography>
            <TextField
              sx={{ width: "100%", margin: "1px 0px 5px 0px" }}
              placeholder='New Password'
              type={newPasswordVisible ? 'text' : 'password'} // Toggle visibility
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              error={Boolean(newPasswordError)}
              helperText={newPasswordError}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                    size="large"
                    edge="end"
                  >
                    {newPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
              <GradientButton
                name={`Save`}
                onClick={() => updatePassword()}
              />
            </Box>
          </DialogTitle>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={showModal}
          onClose={handleNewClose}
          // aria-labelledby="customized-dialog-title"
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: 'background.paper',
              boxShadow: 'none',
              borderRadius: '10px',
              width: '100%',
              maxWidth: '500px',
            },
            '& button.MuiButtonBase-root': {
              fontFamily: 'Poppins',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              textTransform: 'capitalize',
              borderRadius: '10px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'button.menuhover',
                boxShadow: 'none',
              },
            },
          }}
        >
          <Box sx={{ background: "#FABF59", height: "3rem", display: "flex", justifyContent: 'space-between', alignItems: 'center', }}>
            <Box sx={{ textTransform: 'capitalize', fontSize: "25px", marginLeft: "1rem", '@media (max-width: 344px)': { fontSize: "15px", } }}>Profile</Box>
            <IconButton onClick={handleNewClose} color="inherit" >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle id="customized-dialog-title">
            <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
              <Box>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px", fontWeight: "bold" }}>NAME :</Typography>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px", fontWeight: "bold" }}>EMAIL :</Typography>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px", fontWeight: "bold" }}>CONTACT :</Typography>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px", fontWeight: "bold" }}>ROLE :</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px" }}>{user.name || "--"}</Typography>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px" }}>{user.email || "--"}</Typography>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px" }}>{user.contact_number || "--"}</Typography>
                <Typography sx={{ fontSize: "15px", margin: "15px 5px 2px 5px" }}>{user.role || "--"}</Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
              <GradientButton
                name={`Upload Image`}
                UploadIcon={UploadFileIcon}
                onClick={() => fileSelectorRef.current.click()}
                sx={{
                  width: { xs: '100%', sm: 'auto' },
                }}
              />
              <input type="file" name="myfile"
                style={{ display: 'none' }} ref={fileSelectorRef}
                accept="image/*" // Specify accepted image file types
                onChange={(e) => {
                  let file = e.target.files[0];
                  if (file && file.type.startsWith('image/')) {
                    handleProfileData(file)

                  } else {
                    show("Please select a valid image file.", "error");
                  }
                }}

              />
            </Box>
          </DialogTitle>
        </Dialog>
      </div>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: 'background.main',
            boxShadow: 'none',
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {JSON.parse(localStorage.getItem("userdata"))?.company_logo ? <img src={JSON.parse(localStorage.getItem("userdata"))?.company_logo} alt='logo' height={"50px"} /> : "QRCMS"}

            <Box sx={{ flexGrow: 1 }} />

            <Container maxWidth="xl">
              <Toolbar disableGutters>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenDrawer}
                    color="icon.main"
                  >
                    <KeyboardDoubleArrowDownSharpIcon />
                  </IconButton>
                  <Drawer
                    anchor="top"
                    open={openDrawer}
                    onClose={handleCloseDrawer}
                    ModalProps={{
                      keepMounted: true,
                    }}
                    PaperProps={{
                      sx: {
                        padding: '16px',
                        backgroundColor: 'background.main',
                      },
                    }}
                  >
                    {allNavLinks.map((page, index) => (
                      !['User', 'Products', 'Redeem Request(s)'].includes(page.name) ? (
                        <Button
                          key={index}
                          onClick={() => handlePageClick(page)}
                          fullWidth
                          variant={selectedPage === page ? 'contained' : 'text'}
                          sx={{
                            color: 'icon.main',
                            marginBottom: '8px',
                            borderRadius: '20px',
                            ...(selectedPage === page && {
                              backgroundColor: 'button.menu',
                              color: 'text.primary',
                            }),
                          }}
                        >
                          {page.name}
                        </Button>
                      ) : (
                        <NavDropdown
                          label={page.name}
                          pages={page.link}
                          selectedPage={page.getState}
                          onPageClick={(pager) => page.setState(pager)}
                        />
                      )

                    ))

                    }
                    {/* <NavDropdown
                    label="User"
                    pages={userPages}
                    selectedPage={selectedPage}
                  />
                  <NavDropdown
                    label="Redeem Request"
                    pages={redReqPages}
                    selectedPage={selectedPage}
                  />
                  <NavDropdown
                    label="Products"
                    pages={prodPages}
                    selectedPage={selectedPage}
                  /> */}

                  </Drawer>
                </Box>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {allNavLinks.map((page, index) => (
                    !['User', 'Products', 'Redeem Request(s)'].includes(page.name) ? (
                      <Button
                        key={index}
                        onClick={() => handlePageClick(page)}
                        sx={{
                          my: 2,
                          color: 'text.primary',
                          display: 'inline-flex',
                          padding: '9px 18px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '4px',
                          backgroundColor:
                            selectedPage === page ? 'button.menu' : 'inherit',
                          marginLeft: '15px',
                          borderRadius: '20px',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: 400,
                          lineHeight: '22px',
                          textTransform: 'capitalize',
                          ...(selectedPage === page && {
                            color: 'text.primary',
                            textAlign: 'center',
                            fontWeight: 600,
                            backgroundColor: 'button.menu',
                          }),
                          '&:hover': {
                            backgroundColor: 'button.menuhover',
                          },
                        }}
                      >
                        {page.name}
                      </Button>
                    ) : (
                      <NavDropdown
                        label={page.name}
                        pages={page.link}
                        selectedPage={page.getState}
                        onPageClick={(pager) => page.setState(pager)}
                      />
                    )

                  ))

                  }
                  {/* <NavDropdown
                  label="User"
                  pages={userPages}
                  selectedPage={selectedPage}
                />
                <NavDropdown
                  label="Redeem Request"
                  pages={redReqPages}
                  selectedPage={selectedPage}
                />
                <NavDropdown
                  label="Products"
                  pages={prodPages}
                  selectedPage={selectedPage}
                /> */}

                </Box>
              </Toolbar>
            </Container>
            <Box
              sx={{ display: { md: 'flex', xs: 'none' }, alignItems: 'center' }}
            >
              {/* <IconButton size="large" color="icon.main">
                <DarkModeOutlinedIcon />
              </IconButton> */}
              {/* <IconButton
              size="large"
              aria-label="new notifications"
              color="icon.main"
            >
              <SettingsOutlinedIcon />
            </IconButton> */}
              {/* <IconButton
              size="large"
              aria-label="account of current user"
              aria-haspopup="true"
              color="icon.main"
            >
              <MailOutlineRoundedIcon />
            </IconButton> */}
              {/* <IconButton size="large" color="icon.main">
                <NotificationsNoneRoundedIcon />
              </IconButton> */}

              {/* <Divider orientation="vertical" variant="middle" flexItem /> */}

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '15px',
                }}
              >
                <Avatar>
                  <Person />
                </Avatar>
                <Button
                  variant="text"
                  endIcon={<KeyboardArrowDownIcon />}
                  onClick={handleProfileMenuOpen}
                  sx={{
                    color: 'text.primary',
                    textTransform: 'none',
                    marginLeft: '10px',
                  }}
                >
                  {name}
                </Button>
                {renderMenu}
              </Box>
            </Box>

            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="icon.main"
              >
                <MoreVertIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    </>
  );
};

export default NavBar;
