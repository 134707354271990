import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import {
  Button,
  CircularProgress,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  // Modal,
  Paper,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import GradientButton from '../gradiantComponents/gradientButtonPage';
import BasicModal from '../../tenant/components/neftModal/neftModal';
// import PictureAsPdfSharpIcon from '@mui/icons-material/PictureAsPdfSharp';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import PDFDocument from '../../tenant/components/downloaders/pdfDownloader';
import XLSXDownload from '../../tenant/components/downloaders/xlsxDownloader';
import Pagination from './pagination/paginationModal';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { TENANT_URL } from '../../../src/config';
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, columns } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(property);
  };


  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            key={column.id}
            align='center'
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.id ? order : false}
            sx={{
              borderRight: '1px solid rgba(224, 224, 224, 1)',
              backgroundColor: 'background.default',
              padding: '12px 8px 8px 16px',
              fontWeight: 'bold',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      numeric: PropTypes.bool.isRequired,
      disablePadding: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default function TableData({
  columns,
  rows,
  func,
  temp,
  onPermissionClick,
  toggle,
  rowsPPage,
  pageNo,
  total,
  getPage,
  totalRecords,
  setCurrentOrder,
  searchData
}) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const page = 0;
  const rowsPerPage = 10;
  const [visibleRows, setVisibleRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tenetId, setTenetId] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [spinnerStates, setSpinnerStates] = useState(Array(10).fill(false));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  const handleModalClose = () => {
    setShowModal(false);
  }

  const uploadProductFile = async ({ status, id }) => {
    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
  
      const postConfig = {
        method: 'post',
        url: `${TENANT_URL}/approve/redeem/request/gift`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: { status, id }, // Use 'status' instead of 'action'
      };
  
      setLoading(true);
  
      const response = await axios(postConfig);
      console.log('Response:', response.data);
  
      // Handle the response and update your UI as needed
  
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
    } finally {
      setLoading(false);
    }
  };
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleActionChange = (value, id) => {
    if (value === 'edit') {
      func(id, false);
    } else if (value === 'delete') {
      func(id, true);
    }
  };

  const handlePermissionButtonClick = (tenantId) => {
    onPermissionClick(tenantId);
  };

  const handleNEFTButtonClick = (tenantId, status) => {
    if (status === 'Approved') {
      return;
    }
    setTenetId(tenantId);
    handleOpen();
  };

  const handleToggleChange = async (value, index) => {
    const newState = [...spinnerStates];
    newState[index] = true;
    setSpinnerStates(newState);
    toggle(value);
  };

  useEffect(() => {
    setVisibleRows(
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    );
    setSpinnerStates(Array(10).fill(false));
  }, [order, orderBy, rows]);
  const handleClick = (obj) => {
    setCurrentOrder(obj);
    navigate(`/vieworders/${obj.id}`);
  }
  const handleModalClick = () => {
    setShowModal(!showModal);
  }
 
const handleRejectClick = () => {
  uploadProductFile({ status: 'declined', id: rows[0].id });
}

const handleApproveClick = () => {
  uploadProductFile({ status: 'approved', id: rows[0].id });
} 
  return (
    <Box sx={{ marginTop: '24px', maxHeight: '80vh', overflowY: 'auto', }}>
      <Grid justifyContent="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%', borderRadius: '20px 20px 20px 20px' }}>
            <Paper sx={{ width: '100%', mb: 2, borderRadius: '24px' }}>
              <TableContainer
                component="div"
                sx={{
                  borderRadius: '24px',
                  maxHeight: 'calc(86vh - 200px)',
                  minHeight: 'calc(30vh - 200px)',
                }}
              >
                <Table
                  sx={{
                    minWidth: 750,
                    '& th': {
                      ...(columns[0].disablePadding && {
                        paddingLeft: '16px',
                      }),
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size="medium"
                >
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    columns={[{ id: 'srNo', label: 'Sr. No' }, ...columns]}
                  />
                  <TableBody>
                    {visibleRows?.length > 0 ? (
                      visibleRows.map((row, index) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                          sx={{
                            cursor: 'default',
                            borderBottom:
                              index === visibleRows.length - 1
                                ? '1px solid rgba(224, 224, 224, 1)'
                                : 'none',
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: '1px solid rgba(224, 224, 224, 1)',
                              borderBottom: '0px',
                              padding: '8px 11px 8px 16px',
                              alignItems: 'center',
                              // ...(0 === 0 && {
                              //   paddingLeft: '16px',
                              // }),
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          {columns.map((column, columnIndex) => {
                            if (column.id === 'is_active') {
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    alignItems: 'center',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                  }}
                                >
                                  {spinnerStates[index] ? (
                                    <Box>
                                      <CircularProgress size={20} />
                                    </Box>
                                  ) : (
                                    <Switch
                                      checked={row[column.id]}
                                      color="primary"
                                      onChange={(event) =>
                                        handleToggleChange(row[temp], index)
                                      }
                                    />
                                  )}
                                </TableCell>
                              );
                            } else if (column.id === 'actions') {
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    alignItems: 'center',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                  }}
                                >
                                  <Select
                                    value=""
                                    displayEmpty
                                    sx={{
                                      minWidth: '40px',
                                      borderRadius: '30px',
                                      backgroundColor: 'primary.main',
                                      color: '#fff',
                                      padding: '0px 15px',
                                      height: '50px',
                                      '&:hover': {
                                        backgroundColor: 'primary.main',
                                      },
                                    }}
                                    onChange={(event) =>
                                      handleActionChange(
                                        event.target.value,
                                        row[temp]
                                      )
                                    }
                                  >
                                    <MenuItem value="" disabled>
                                      Actions
                                    </MenuItem>
                                    <MenuItem value="edit">Edit</MenuItem>
                                    {/* <MenuItem value="delete">Delete</MenuItem> */}
                                  </Select>
                                </TableCell>
                              );
                            } else if (column.id === 'action') {
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    alignItems: 'center',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                  }}
                                >
                                  <Button
                                    sx={{
                                      minWidth: '100px',
                                      borderRadius: '30px',
                                      backgroundColor: 'primary.main',
                                      color: '#fff',
                                      padding: '0px 15px',
                                      height: '50px',
                                      '&:hover': {
                                        backgroundColor: 'primary.main',
                                      },
                                    }}
                                    onClick={() => handleClick(row)}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              );
                            }
                            else if (column.id === 'Action') {
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    alignItems: 'center',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                  }}
                                >
                                  <Button
                                    sx={{
                                      minWidth: '100px',
                                      borderRadius: '30px',
                                      backgroundColor: 'primary.main',
                                      color: '#fff',
                                      padding: '0px 15px',
                                      height: '50px',
                                      '&:hover': {
                                        backgroundColor: 'primary.main',
                                      },
                                    }}
                                    onClick={() => handleModalClick(row.id)}
                                  >
                                    Approve
                                  </Button>
                                </TableCell>
                              );
                            }
                            else if (column.id === 'permission') {
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    alignItems: 'center',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                  }}
                                  onClick={() =>
                                    handlePermissionButtonClick(row[temp])
                                  }
                                >
                                  <GradientButton
                                    name={`Permission`}
                                    onClick={() =>
                                      handlePermissionButtonClick(row[temp])
                                    }
                                    sx={{
                                      width: { xs: '100%', sm: 'auto' },
                                    }}
                                  />
                                </TableCell>
                              );
                            } else if (column.id === 'neft') {
                              return (
                                <TableCell
                                  key={column.id}
                                  align="center"
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    alignItems: 'center',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                  }}
                                >
                                  <GradientButton
                                    name={`NEFT`}
                                    disabled={row.status === 'Approved'}
                                    onClick={() =>
                                      handleNEFTButtonClick(
                                        row[temp],
                                        row.status
                                      )
                                    }
                                    sx={{
                                      width: { xs: '100%', sm: 'auto' },
                                    }}
                                  />
                                </TableCell>
                              );
                            } else if (column.id === 'status') {
                              return (
                                <TableCell
                                  align='center'
                                  key={column.id}
                                  // align={column.numeric ? 'right' : 'left'}
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                    color:
                                      row[column.id] === 'Approved'
                                        ? 'green'
                                        : 'orange',
                                  }}
                                >
                                  {row[column.id]}
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell
                                  key={column.id}
                                  align='center'
                                  sx={{
                                    borderRight:
                                      '1px solid rgba(224, 224, 224, 1)',
                                    borderBottom: '0px',
                                    padding: '8px 11px 8px 16px',
                                    ...(columnIndex === 0 && {
                                      paddingLeft: '16px',
                                    }),
                                  }}
                                >
                                  {row[column.id]}
                                </TableCell>
                              );
                            }
                          })}{' '}
                        </TableRow>
                      ))
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '80px',
                          width: '90%',
                          ml: '500px',
                        }}
                      >
                        <Typography>TABLE IS EMPTY</Typography>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Box display="flex" flexDirection="row">
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      marginLeft: '20px',
                      marginTop: '10px',
                    }}
                  >
                    Download Files:
                  </Typography>
                  <XLSXDownload rows ={rows} payload={searchData} />
                  {/* <PDFDownloadLink
                    document={<PDFDocument columns={columns} rows={rows} />}
                    fileName="table_data.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        'Loading document...'
                      ) : (
                        <IconButton
                          aria-label="Download PDF"
                          backgroundColor="#FF0000"
                          style={{ color: 'red' }}
                        >
                          <PictureAsPdfSharpIcon />
                        </IconButton>
                      )
                    }
                  </PDFDownloadLink> */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginRight: '15px',
                  }}
                >
                  <Pagination
                    currentPage={pageNo}
                    maxPages={total}
                    setCurrentPage={getPage}
                    totalRecords={totalRecords}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
      {openModal ? (
        <BasicModal
          tenetId={tenetId}
          open={openModal}
          handleClose={handleClose}
        />
      ) : (
        <></>
      )}

      <div>
        <Dialog
          open={showModal}
          onClose={handleModalClose}
          // aria-labelledby="customized-dialog-title"
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: 'white',
              boxShadow: 'none',
              borderRadius: '10px',
              width: '100%',
              maxWidth: '500px',
            },
            '& button.MuiButtonBase-root': {
              fontFamily: 'Poppins',
              fontSize: 14,
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '22px',
              textTransform: 'capitalize',
              borderRadius: '10px',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'button.menuhover',
                boxShadow: 'none',
              },
            },
          }}
        >
          <Box sx={{ background: "#FABF59", height: "3rem", display: "flex", justifyContent: 'space-between', alignItems: 'center', }}>
            <Box sx={{ textTransform: 'capitalize', fontSize: "25px", marginLeft: "1rem", '@media (max-width: 344px)': { fontSize: "15px", } }}>Approve</Box>
            <IconButton onClick={handleModalClose} color="inherit" >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogTitle>
            <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
              <Button onClick={handleApproveClick} sx={{width: "50%",height: "50px", fontSize: "15px", margin: "15px 5px 15px 5px", color: "white", backgroundColor: "green"}}>Approved</Button>
              <Button onClick={handleRejectClick} sx={{width: "50%",height: "50px", fontSize: "15px", margin: "15px 5px 15px 5px", color: "white", backgroundColor: "red" }}>Declined</Button>
              </Box>
          </DialogTitle>
        </Dialog>
      </div>
    </Box>
  );
}
