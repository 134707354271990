import React from 'react';
import Page from '../pageData/page';
import MarketingExecutivesDataCol from '../../components/tabledata/marketingExecutiveTable/marketingExecutivesDataCol';
import MarketingExecutivesCol from '../../components/tabledata/marketingExecutiveTable/marketingExecutivesCol';
import { useMarketingExecutive } from '../../services/marketingExecutives/marketingExecutivesService';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';
import MarketingExecutiveSearchCol from '../../components/tabledata/marketingExecutiveTable/searchFields';

const MarketingExecutives = () => {
  const {
    loading,
    addData,
    allMarketingExecutiveData,
    deleteMech,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchMarketingExecutives,
  } = useMarketingExecutive();

  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_marketing_executive_tab != (1 || "1") ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Page
              columns={MarketingExecutivesDataCol}
              rows={allMarketingExecutiveData}
              data={MarketingExecutivesCol}
              temp={'id'}
              showGradientButton={true}
              showQRButton={false}
              delData={deleteMech}
              addData={addData}
              updateData={updateData}
              toggle={active}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              total={noOfPages}
              getPage={getCurrentPage}
              totalRecords={totalRecords}
              searchFields={MarketingExecutiveSearchCol}
              search={searchMarketingExecutives}
            />
          )}
        </>
      )}
    </>
  );
};

export default MarketingExecutives;
