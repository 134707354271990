import { useEffect, useState } from 'react';

const RedReqList = () => {
  const val = JSON.parse(localStorage.getItem('userdata'));
  const permissions = val.tenant_nav_bar_permission;
  const [redReqPages, setRedReqPages] = useState([]);

  useEffect(() => {
    const updatedTenantPages = [];
    if (permissions?.is_redeem_request_tab == (1 || "1")) {
      updatedTenantPages.push(
        {
          name: 'NEFT Request',
          link: '/redeemrequest',
        },

      );
    }
    if (permissions?.is_gift_tab == (1 || "1")) {
      updatedTenantPages.push(
        {
          name: 'Gift Request',
          link: '/redeemrequestgift',
        }
      );
    }
    setRedReqPages(updatedTenantPages);
    // eslint-disable-next-line
  }, [permissions]);

  return redReqPages;
};

export default RedReqList;
