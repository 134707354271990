import styled from "@emotion/styled";
import { Box, Paper, Typography, Button, TextField, Grid,Card } from "@mui/material";
import { Container } from "@mui/system";

export const BoxContainer = styled(Box)(() => ({
  marginTop: "1rem",
  flexGrow: 1,
  padding: "1rem",
  paddingLeft: "1rem",
  position: 'relative',
  height: '100vh',
  width:"100%",
})
)
export const CustomBox = styled(Box)(() => ({
  marginTop: "1.5rem",
})
)
export const CustomTypography = styled(Typography)(() => ({
  fontSize: "25px",
  color: "#291B04",
  fontWeight: "bold",
  marginBottom: "1rem",

})
)
export const MainBox = styled(Box)(() => ({
  backgroundColor: "white",

  width: "100%",
})
)
export const MainTypography = styled(Typography)(() => ({
  fontWeight: "bold",
  padding: '1rem',
  fontSize: "19px",
'@media (max-width: 588px)': {
   fontSize:"13px"
  }
})
)
export const CustomHr = styled('hr')(() => ({
  marginBottom: "2rem",
  width: "98.5%",
})
)
export const CustomGrid = styled(Grid)(() => ({
  background: "white",
  borderTopLeftRadius: "28px",
  borderTopRightRadius: "28px",
  boxShadow:" 0px -25px 50px -16px rgba(0,0,0,0.45)",
})
)
export const CustomGridOne = styled(Grid)(() => ({
  background: "white",
})
)
export const CustomGridTwo = styled(Grid)(() => ({
  background: "white",
})
)
export const CustomGridLast = styled(Grid)(() => ({
  background: "white",
  borderBottomLeftRadius: "28px",
  borderBottomRightRadius: "28px",
  boxShadow:" 0px 25px 20px -20px rgba(0,0,0,0.45)"
})
)
export const CardWrapper = styled(Paper)(() => ({
  padding: '1rem',
  justifyContent: 'left',
  display: 'flex',
  flexDirection: 'row',
  height: 500,
})
)
export const BoxWrapper = styled(Box)(() => ({
  borderRadius:"30px",
  height: 250,
  border: '2px solid #FABF59',
  width: "97%",
  marginBottom: "1rem",
  '@media (max-width: 355px)': { height: 260, },
  
})
)
export const BoxWrapperTwo = styled(Box)(() => ({
  height: 250,
    borderRadius:"30px",
  border: '2px solid #67809F',
  width: "97%",
  marginBottom: "1rem",
    '@media (max-width: 489px)': { height: 260, },

})
)
export const OrderTypography = styled(Typography)(() => ({
  fontSize: "20px",
  background: "#FABF59",
    borderTopLeftRadius: "28px",
  borderTopRightRadius: "28px",
  fontWeight: "bold",
  padding: "0.7rem 0rem 0.7rem 1.5rem",
  '@media (max-width: 588px)': { fontSize: "13px",},
})
)
export const Customtypography = styled(Typography)(() => ({
  fontSize: "20px",
  background: " #67809F",
  fontWeight: "bold",
  borderTopLeftRadius: "28px",
  borderTopRightRadius: "28px",
  padding: "0.7rem 0rem 0.7rem 1.5rem",
    '@media (max-width: 588px)': { fontSize: "13px",},
})
)
export const CustomLi = styled('li')(() => ({
  listStyle: "none",
  margin: "0.5rem",

})
)
export const CustomLiOne = styled('li')(() => ({
  listStyle: "none",
  margin: "0.5rem",
  '@media (max-width: 490px)': { fontSize: "12px",},
  '@media (max-width: 334px)': { fontSize: "10px",},


})
)
export const StatusLi = styled('li')(() => ({
  listStyle: "none",
  margin: "0.5rem",
  fontWeight: "bold",
  background: "lightgreen",
  width: "auto",
})
)
export const CustomLiTwo = styled('li')(() => ({
  listStyle: "none",
  margin: "0.5rem",
  fontWeight: "bold"
})
)
export const CustomContainer = styled(Container)(() => ({
  display: "flex",
  justifyContent: "space-Around",
  '@media (max-width: 1018px)': { fontSize: "13.5px",},
  '@media (max-width: 588px)': { fontSize: "13px",},
  '@media (max-width: 1141px)': { fontSize: "14px",},
  '@media (max-width: 334px)': { fontSize: "10px",},
})
)
export const BoxWrapperThree = styled(Box)(() => ({
  height: 'auto',
  border: '2px solid #95A5A6',
  width: "98.5%",
  marginTop: "-0.5rem",
  marginBottom: "1rem", 
  borderRadius:"32px",
  boxShadow:' 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
})
)
export const CustomTableTypography = styled(Typography)(() => ({
  marginBottom: "1rem",
  fontSize: "20px",
  background: " #95A5A6",
  fontWeight: "bold",
  padding: "0.7rem 0rem 0.7rem 1.5rem",
  borderTopLeftRadius: "30px",
  borderTopRightRadius: "30px",
  '@media (max-width: 588px)': { fontSize: "13px",},
})
)
export const OneBox = styled(Box)(() => ({
  width:'70%',
  '@media (max-width: 578px)': { display: "flex", flexDirection: "column",width:"90%"},
  '@media (max-width: 474px)': { width:"82%"},
})
)
export const CustomTextField = styled(TextField)(() => ({
  margin: "1rem",
  '@media (max-width: 578px)': { width: "100%",},
})
)
export const CustomTextFieldDate = styled(TextField)(() => ({
  width: "100%",
  margin: "1rem",
  '@media (max-width: 578px)': { width: "100%",},
})
)
export const CoustomBox = styled(Box)(() => ({
  width: "30%",
  margin: "1rem"
})
)
export const CustomButton = styled(Button)(({ theme }) => ({
  height:"3rem",
  margin:"1rem",
  fontWeight:"bold",
  fontSize:"17px",
  // background:"#f1f4f7",
  background: theme.palette.button.gradient,
  borderRadius: '12px',
  color: theme.palette.text.primary,
  width:"30%",
  '@media (max-width: 844px)': {fontSize:"12px",},
  '@media (max-width: 578px)': { width: "100%",fontSize:"15px",},
  '@media (max-width: 477px)': { width: "90%",fontSize:"12px",},
  '&:hover': {
    background: theme.palette.button.menu,
  },

})
)
export const BoxLast = styled(Box)(() => ({
  display:"flex",
  justifyContent:"space-between",
   ' @media (max-width:900px)': { display: "Block" },
})
)
export const CardWrapperTwo = styled(Card)(() => ({
  height:150, 
  marginRight:"1.3rem", 
  display: "flex", 
  justifyContent: "space-Around",
  background:"#f1f4f7",
  paddingTop:"0.5rem",
  '@media (max-width: 588px)': { fontSize: "13px",  },
  '@media (max-width: 321px)': { fontSize: "10px",},
 marginBottom:"7rem"
})
)
export const CustomSpan = styled('span')(() => ({
 "@media (max-width: 409px)": { display: "none" },
})
)