import { useEffect, useState } from 'react';

const TenantPagesComponent = () => {
  const val = JSON.parse(localStorage.getItem('userdata'));
  const permissions = val.tenant_nav_bar_permission;
  const [pages, setTenantPages] = useState([{ name: 'Dashboard', link: '/' }]);

  useEffect(() => {
    const updatedTenantPages = [{ name: 'Dashboard', link: '/' }];

    // if (permissions?.is_retailer_tab === 1) {
    //   updatedTenantPages.push({ name: 'Retailer(S)', link: '/retailer' });
    // }
    // if (permissions?.is_redeem_points_tab === 1) {
    //   updatedTenantPages.push({ name: 'Distributor(S)', link: '/distributor' });
    // }
    // if (permissions?.is_mechanic_tab === 1) {
    //   updatedTenantPages.push({ name: 'Mechanic(S)', link: '/mechanic' });
    // }
    if (permissions?.is_redeem_points_tab == (1 | "1")) {
      updatedTenantPages.push({ name: 'Reports', link: '/redeempoint' });
    }
    if (permissions?.is_order_tab == (1 || "1")) {
      updatedTenantPages.push({ name: 'Orders', link: '/orders' });
    }

    setTenantPages(updatedTenantPages);
    // eslint-disable-next-line
  }, []);

  return pages;
};

export default TenantPagesComponent;
