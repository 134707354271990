import React from 'react';
import Page from '../pageData/page';
import { useRedeemRequestGiftContext } from '../../services/redeemRequestContext/redeemRequestGiftContext';
import RedeemRequestGiftDataCol from '../../components/tabledata/redeemRequestTable/redeemRequestGiftDataCol';
import RedeemRequestSearchCol from '../../components/tabledata/redeemRequestTable/searchFieldsGift';
import RedeemRequestGiftCol from '../../components/tabledata/redeemRequestTable/redeemRequestGiftCol';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';

const RedeemRequestGift = () => {
  const {
    RedeemRequest,
    loading,
    rowsPrPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchRedeemReqsGift,
  } = useRedeemRequestGiftContext();

  if (loading) {
    return <Spinner />;
  }
  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_gift_tab != (1 || "1") ? (
        <ErrorPage />
      ) : (
        <>
          <Page
            columns={RedeemRequestGiftDataCol}
            rows={RedeemRequest}
            data={RedeemRequestGiftCol}
            temp={'id'}
            showGradientButton={false}
            showQRButton={false}
            rowsPerPage={rowsPrPage}
            page={currentPage}
            total={noOfPages}
            getPage={getCurrentPage}
            totalRecords={totalRecords}
            searchFields={RedeemRequestSearchCol}
            search={searchRedeemReqsGift}
          />
        </>
      )}
    </>
  );
};

export default RedeemRequestGift;
