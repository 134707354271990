import React, { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { theme } from './theme';
import { TenantAuthProvider, useTenantAuth } from './auth/tenant/auth';
import { AdminAuthProvider } from './auth/superadmin/adminAuth';
import { UtilityProvider, } from './tenant/services/utilityContext/useUtility';
import { SnackbarProvider } from './commons/snackBarContextProvider';
import Routes from './routes';

function App() {


  return (
    <>
      <ThemeProvider theme={theme('light')}>
        <SnackbarProvider>
          <AdminAuthProvider>
            <TenantAuthProvider>
              <UtilityProvider>
                <Routes/>
              </UtilityProvider>
            </TenantAuthProvider>
          </AdminAuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
