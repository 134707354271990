import { createTheme } from '@mui/material';

const theme = (mode) =>
  createTheme({
    palette: {
      ...(mode === 'light'
        ? {
            primary: {
              main: '#FABF59',
              color: '#FABF59',
            },
            background: {
              default: '#F5F5F5',
              main: '#fff',
            },
            spinner: {
              main: '#ffad1f',
            },
            secondary: {
              main: '#F5F5F5',
              color: '#F5F5F5',
            },
            button: {
              menu: '#FABF59',
              menuhover: '#D7A148',
              gradient: 'linear-gradient(179deg, #FC0 0%, #F90 100%)',
            },
            icon: {
              main: 'rgb(0, 0, 0)',
            },
            text: {
              primary: 'rgb(0, 0, 0)',
              headings: '#291B04',
            },
            page: {
              main: 'linear-gradient(180deg, #FABF59 0%, #FFF 100%)',
            },
            qrCodeBackground: {
              main: 'linear-gradient(to bottom, #FABF59, #F5F5F5)',
            },
            checkboxBackground: {
              main: 'rgb(0, 0, 0)',
            },
            textFieldBackground: {
              main: '#FFF',
              boxShadow: '0px 4px 16px 0px rgba(41, 27, 4, 0.08)',
            },
            selectFieldBackground: {
              main: '#FFF',
              boxShadow: '0px 4px 16px 0px rgba(41, 27, 4, 0.08)',
              border: '2px solid #DEDEDE',
            },
            printButton: {
              background: 'linear-gradient(179deg, #FC0 0%, #F90 100%)',
              color: '#291B04',
            },
            qrCodeContainer: {
              background: '#fff',
              boxShadow: '0px 4px 16px 0px rgba(41, 27, 4, 0.08)',
            },
            qrCodeImage: {
              borderRadius: '20px',
            },
            GridTypograpgy: {
              color: '#291B04',
            },
            graphTypography: {
              color: '#EA5859',
            },
            shadow: {
              boxShadowColor: 'rgba(41, 27, 4, 0.2)',
              boxShadowColorHover: 'rgba(41, 27, 4, 0.3)',
              boxShadowColorFocus: 'rgba(41, 27, 4, 0.4)',
            },
            pieChartColors: {
              red: 'rgba(255, 99, 132, 1)',
              orange: 'rgba(255, 159, 64, 1)',
            },
            earnedPointsSeconday: {
              color: '#82796A',
            },
            earnedPointsPendingValue: {
              background: 'rgba(250, 194, 75, 0.30)',
            },
            lineChartColors: {
              backgroundColorLineOne: 'rgba(127, 201, 248, 0.5)',
              backgroundColorLineTwo: 'rgba(47, 55, 154, 0.5)',
              boxShadow: '0px 4px 16px 0px rgba(41, 27, 4, 0.08)',
              dataSetOneColor: '#7FC9F8',
              dataSetTwoColor: '#2F379A',
            },
          }
        : {
            primary: {
              main: '#FABF59',
            },
          }),
    },
  });

export { theme };
