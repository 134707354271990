import React, { useState } from 'react';
import { IconButton, CircularProgress } from '@mui/material';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';

const XLSXDownload = ({ rows, payload }) => {
  const { show } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const downloadXLSX = async () => {
    let dataRows;

    if (payload) {
      delete payload?.page;
      const token = JSON.parse(localStorage.getItem('userdata')).token;

      const config = {
        method: 'post',
        url: `${TENANT_URL}/scan/qr/code/export`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: payload,
      };

      setLoading(true);
      try {
        const response = await axios(config);
        dataRows = response?.data?.data;

        if (!dataRows || dataRows.length === 0) {
          show('No data found', 'error');
          setLoading(false);
          return;
        }
      } catch (error) {
        console.error(error);
        show('Error fetching data', 'error');
        setLoading(false);
        return;
      }
    } else {
      dataRows = rows;
    }

    const filteredRows = dataRows.filter(
      (row) => row.id !== 'is_active' && row.id !== 'actions'
    );

    handleDownloadXLSX(filteredRows);
    setLoading(false);
  };

  const handleDownloadXLSX = (filteredRows) => {
    const worksheet = XLSX.utils.json_to_sheet(filteredRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'data.xlsx');
  };

  return (
    <IconButton
      onClick={downloadXLSX}
      aria-label="Download XLSX"
      style={{ color: 'green' }}
      disabled={loading}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : <ArticleRoundedIcon />}
    </IconButton>
  );
};

export default XLSXDownload;
