import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from '../../commons/snackBarContextProvider';
import { useTenantAuth } from './auth';
import { TENANT_URL } from '../../config';

const ProtectedRoute = () => {
  const data = localStorage.getItem('userdata');
  const { user, setLoading, setUser } = useTenantAuth();
  const { show } = useSnackbar();

  useEffect(() => {
    const verifyToken = async () => {
      setLoading(true);
      if (data) {
        const token = JSON.parse(localStorage.getItem('userdata')).token;
        await axios
          .get(`${TENANT_URL}/verify/token`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(() => {
            setUser(data);
          })
          .catch((error) => {
            console.error(error);
            setUser(false);
            localStorage.removeItem('userdata');
            show('Logged out successfully');
          });
      }
      setLoading(false);
    };
    verifyToken();
  }, [setLoading, setUser, data, show]);

  return (
    <div>
      {user ? (
        <>
          <Outlet />
        </>
      ) : (
        <>{data ? <></> : <Navigate to="/login" />}</>
      )}
    </div>
  );
};
export default ProtectedRoute;
