import { useEffect, useRef, useState } from 'react';
import {
  generateQRCode,
  getAllActiveProducts,
} from '../../services/products/qrProductServices';
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
  Checkbox,
} from '@mui/material';
import * as XLSX from 'xlsx';
import { styled } from '@mui/material/styles';
import gridImage from '../../../images/QRImage.png';
import QRInvoice from './qrInvoice';
import { useSnackbar } from '../../../commons/snackBarContextProvider';

const StyledTextField = styled(InputBase)(({ theme }) => ({
  borderRadius: '12px',
  height: '56px',
  background: theme.palette.textFieldBackground.main,
  boxShadow: theme.palette.textFieldBackground.boxShadow,
  padding: '20px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  border: `0px solid ${theme.palette.printButton.color}`,
  height: '56px',
  background: theme.palette.printButton.background,
  color: theme.palette.printButton.color,
  textTransform: 'none',
}));

const ProductsQR = () => {
  const theme = useTheme();
  const [activeProducts, setActiveProducts] = useState([]);
  const userData = localStorage.getItem('userdata');
  const user = JSON.parse(userData);
  const [userName] = useState(user.name);

  const pointsRef = useRef('');
  const numberOfCouponsRef = useRef('');

  const [selectedPartNumber, setSelectedPartNumber] = useState(null);
  const [points, setPoints] = useState(0);
  const [numberOfCoupons, setNumberOfCoupons] = useState(0);
  const [selectedCheckbox, setSelectedCheckbox] = useState('onlyQR');

  const { show } = useSnackbar();

  const handleCheckboxChange = (value) => {
    setSelectedCheckbox(value);
  };

  const toggleInputs = () => {
    getAllActiveProducts().then((response) => {
      setActiveProducts(response);
    });
    if (activeProducts && activeProducts.length > 0) {
      setSelectedPartNumber(JSON.stringify(activeProducts[0]));
    }
  };

  let selectedPart = null;
  if (selectedPartNumber) {
    selectedPart = JSON.parse(selectedPartNumber);
  }

  const downloadQRData = () => {
    if (selectedPartNumber && numberOfCoupons) {
      generateQRCode(JSON.parse(selectedPartNumber).id, numberOfCoupons)
        .then((response) => {
          const date = new Date();
          const worksheet = XLSX.utils.json_to_sheet(response.data);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
          XLSX.writeFile(workbook, `QR_DATA - ${date}.xlsx`);
          toggleInputs();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      show('Fill the required fields', 'error');
    }
  };

  useEffect(() => {
    getAllActiveProducts().then((response) => {
      setActiveProducts(response);
    });
  }, []);

  useEffect(() => {
    if (activeProducts && activeProducts.length > 0) {
      setSelectedPartNumber(JSON.stringify(activeProducts[0]));
    }
  }, [activeProducts]);

  useEffect(() => {
    if (selectedPartNumber) setPoints(JSON.parse(selectedPartNumber).points);
  }, [selectedPartNumber]);

  return (
    <Container sx={{ paddingTop: '24px' }}>
      <Typography variant="h4">
        Generate <span style={{ fontWeight: 'bold' }}>QR Codes</span>
      </Typography>
      <Typography>List of All The {userName} Products</Typography>
      <FormGroup sx={{ marginTop: '27px' }}>
        <Box display={'flex'}>
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: theme.palette.checkboxBackground.main,
                  '&.Mui-checked': {
                    color: theme.palette.checkboxBackground.main,
                  },
                }}
                checked={selectedCheckbox === 'onlyQR'}
                onChange={() => handleCheckboxChange('onlyQR')}
              />
            }
            label="Only QR Code"
          />
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: theme.palette.checkboxBackground.main,
                  '&.Mui-checked': {
                    color: theme.palette.checkboxBackground.main,
                  },
                }}
                checked={selectedCheckbox === 'mrpWithQR'}
                onChange={() => handleCheckboxChange('mrpWithQR')}
              />
            }
            label="MRP Sticker with QR Code"
          />
        </Box>
      </FormGroup>

      <Box marginTop={'35px'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Select
              id="outlined-select-currency"
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                width: '100%',
                borderRadius: 4,
                border: '2px solid #DEDEDE',
                background: '#FFF',
                boxShadow: `0px 4px 12px ${theme.palette.shadow.boxShadowColor}`,
                '&:hover': {
                  boxShadow: `0px 6px 16px ${theme.palette.shadow.boxShadowColorHover}`,
                },
                '&:focus': {
                  boxShadow: `0px 8px 20px ${theme.palette.shadow.boxShadowColorFocus}`,
                },
              }}
              displayEmpty
              value={selectedPartNumber}
              onChange={(e) => setSelectedPartNumber(e.target.value)}
            >
              <InputLabel id="part-number-label" disabled>
                Select Active Products
              </InputLabel>
              {activeProducts.map((product, index) => (
                <MenuItem key={index} value={JSON.stringify(product)}>
                  {product.part_number}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledTextField
              placeholder="Points"
              fullWidth
              value={points}
              inputRef={pointsRef}
              disabled
              style={{ textAlign: 'center' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledTextField
              placeholder="Number of Coupons"
              variant="outlined"
              fullWidth
              inputRef={numberOfCouponsRef}
              onChange={(e) => setNumberOfCoupons(parseInt(e.target.value))}
              style={{ textAlign: 'center' }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StyledButton fullWidth onClick={downloadQRData}>
              Generate QR Code
            </StyledButton>
          </Grid>
        </Grid>
      </Box>

      <Box
        marginTop={'40px'}
        marginBottom={'30px'}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {selectedCheckbox === 'onlyQR' && (
            <Grid item xs={12} sm={12} md={6}>
              <Box
                sx={{
                  borderRadius: theme.palette.qrCodeImage.borderRadius,
                  boxShadow: theme.palette.qrCodeContainer.boxShadow,
                  backgroundColor: theme.palette.qrCodeContainer.background,
                  display: 'block',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',

                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    display: 'block',
                    textAlign: 'center',
                    mt:'10px',
                    mb:'10px'
                  }}
                >
                  Points = {selectedPart?.points}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={gridImage} alt="QR code" />
                </Box>
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: 600,
                    display: 'block',
                    textAlign: 'center',
                    mt:'10px',
                    mb:'10px'
                  }}
                >
                  Part Number = {selectedPart?.part_number}
                </Typography>
              </Box>
            </Grid>
          )}
          {selectedCheckbox === 'mrpWithQR' && (
            <Grid item xs={12} sm={12} md={8}>
              <QRInvoice selectedPart={selectedPart} />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  );
};

export default ProductsQR;
