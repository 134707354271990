const RedeemRequestCol = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'user_type',
    numeric: false,
    disablePadding: true,
    label: 'User Type',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: true,
    label: 'Phone',
  },
  {
    id: 'bank',
    numeric: false,
    disablePadding: false,
    label: 'Bank',
  },
  {
    id: 'account_number',
    numeric: false,
    disablePadding: false,
    label: 'Account Number',
  },
  {
    id: 'ifsc',
    numeric: false,
    disablePadding: false,
    label: 'IFSC',
  },
  {
    id: 'redeem_points',
    numeric: false,
    disablePadding: false,
    label: 'Redeem Points',
  },
  {
    id: 'amount',
    numeric: false,
    disablePadding: false,
    label: 'Redeem value',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'neft',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

export default RedeemRequestCol;
