const RetailersCol = [
  'name',
  'contact_number',
  // 'bank_name',
  'state_name',
  'city',
  'total_points',
  'avaiable_points',
  'is_active',
  // 'actions',
];

export default RetailersCol;
