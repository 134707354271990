// NavDropdown.js
import React, { useState, useRef } from 'react';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@mui/material';
import KeyboardDoubleArrowDownSharpIcon from '@mui/icons-material/KeyboardDoubleArrowDownSharp';
import { useNavigate } from 'react-router-dom';

const NavDropdown = ({ label, pages, selectedPage, onPageClick }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const handlePageClick = (page) => {
    navigate(page.link);
    onPageClick(page); // Notify the parent component about the selected page change
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  let buttonBackgroundColor = 'inherit';

  if (selectedPage && (label === 'User' || label === 'Products' || label === 'Reports')) {
    
    buttonBackgroundColor = 'button.menu';
  }

  // if (selectedPage && label === 'User') {
    
  //   buttonBackgroundColor = 'button.menu';
  // }

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        sx={{
          my: 2,
          color: 'text.primary',
          display: 'inline-flex',
          padding: '9px 18px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
          backgroundColor:  buttonBackgroundColor,
          marginLeft: '15px',
          borderRadius: '20px',
          fontFamily: 'Poppins',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          textTransform: 'capitalize',
          ...(selectedPage === label && {
            color: 'text.primary',
            textAlign: 'center',
            fontWeight: 600,
            backgroundColor: 'button.menu',
          }),
          '&:hover': {
            backgroundColor: 'button.menuhover',
          },
        }}
        endIcon={<KeyboardDoubleArrowDownSharpIcon />}
      >
        {label}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 1000,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{
              backgroundColor: 'background.paper',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '10px',
            }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  sx={{
                    zIndex: 1000,
                  }}
                >
                  {pages?.map((page, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => handlePageClick(page)}
                      sx={{
                        backgroundColor:
                          selectedPage && selectedPage.link === page.link ? 'button.menu' : 'inherit',
                        color: 'text.primary',
                        fontFamily: 'Poppins',

                        ...(selectedPage && selectedPage.link === page.link && {
                          color: 'text.primary',
                          textAlign: 'center',
                          fontWeight: 600,
                          backgroundColor: 'button.menu',
                        }),
                      }}
                    >
                      {page.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default NavDropdown;
