import React, { useState } from 'react';
import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import DashboardCard from '../../../commons/dashboardCard';
import { LineChart } from '../../components/lineChart/lineChart';
import { PieChart } from '../../components/pieChart/pieChart';
import EarnedPointsCards from '../../components/earnedPointsCards/earnedPointsCards';
import { earnedPointsData } from './dashboardData';
import Spinner from '../../../commons/spinner';
import { useDashboardContext } from './dashboardContext';


const Dashboard = () => {
  const { loading, cardData, scannedPoints,graphInfo } = useDashboardContext();

  const userData = localStorage.getItem('userdata');
  const user = JSON.parse(userData);
  const [userName] = useState(user.name);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Container sx={{ paddingTop: '20px', marginBottom: '30px' }}>
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              Welcome, {userName}
            </Typography>
            <Typography>Your performance summary</Typography>

            <Grid container spacing={isSmallScreen ? 2 : 2} paddingTop={'20px'}>
              {cardData.map((item) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                  <DashboardCard
                    mainText={item.mainText}
                    icon={item.icon}
                    graphImg={item.graphImg}
                    cardName={item.cardName}
                    percentageText={item.percentageText}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={isSmallScreen ? 2 : 4} paddingTop={'26px'}>
              <Grid item xs={12} >
                <LineChart 
                graphInfo={graphInfo}
                />
              </Grid>
              {/* <Grid item xs={12} sm={3}>
                <PieChart />
              </Grid> */}
            </Grid>
            {scannedPoints?.length > 0 &&
              <Grid container spacing={isSmallScreen ? 2 : 2} paddingTop={'32px'}>
                {scannedPoints.slice(0, 4).map((item) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
                    <EarnedPointsCards
                      key={item.id}
                      title={item.tenant_name}
                      contact_number={item.contact_number}
                      total_scanned_points={item.total_scanned_points}
                      total_redeem_points={item.total_redeem_points}
                      totalValue={item.total_points}
                    />
                  </Grid>
                ))}
              </Grid>
            }
          </Container>
        </>
      )}
    </>
  );
};

export default Dashboard;
