const RedeemRequestGiftCol = [
  'user_type',
  'name',
  'phone',
  'gift_name',
  'address',
  'redeem_points',
  'available_points',
  'status',
  'Action'
];

export default RedeemRequestGiftCol;
