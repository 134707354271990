import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { TENANT_URL } from '../../../config';


const UtilityContext = createContext();
const UtilityProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [city, setCity] = useState([]);
  const [banks, setBanks] = useState([]);
  const [distributors, setDistributors] = useState([]);


  const getCities = async (id) => {
    const getConfig = {
      method: 'get',
      url: `${process.env.REACT_APP_GLOBAL}/state/cities/${id}`,
    };

    setLoading(true);
    try {
      const response = await axios(getConfig);
      setCity(response.data.data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getStates = async () => {
    const getConfig = {
      method: 'get',
      url: `${process.env.REACT_APP_GLOBAL}/states`,
    };

    try {
      const response = await axios(getConfig);
      setStates(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };


  const getBanks = async () => {
    const getConfig = {
      method: 'get',
      url: `${process.env.REACT_APP_GLOBAL}/banks`,
    };

    try {
      const response = await axios(getConfig);
      setBanks(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };


  const getDistributors = async () => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/all/distributor`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(getConfig);
      setDistributors(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getStates();
    getBanks();
    getDistributors();
    setLoading(false);
  }, []);

  const contextValue = {
    setLoading,
    loading,
    city,
    setCity,
    states,
    setStates,
    getCities,
    getStates,
    banks,
    distributors,
  };

  return (
    <UtilityContext.Provider value={contextValue}>
      {children}
    </UtilityContext.Provider>
  );
};

const useUtility = () => useContext(UtilityContext);

export { UtilityProvider, useUtility };
