import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import { TENANT_URL } from '../../../config';
import ThrowError from '../../utils/errors';

const PointsContext = createContext();
const PointsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [allPointsData, setAllPointsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [start_date, setStartDate] = useState(false);
  const [end_date, setEndDate] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const { show } = useSnackbar();

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getCurrentPage = async (pageNo) => {
    setCurrentPage(pageNo);
    const userData = {
      ...searchData,
      start_date: start_date,
      end_date: end_date
    };
    setSearchData(userData)
    userData['page'] = pageNo
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'post',
      url: `${TENANT_URL}/search/scan/qr/code`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await axios({ ...getConfig, data: data });
      setAllPointsData(response.data.data);
    } catch (error) {
      console.error(error);
      // show('Data not Found ', 'error');
    }
  };

  const getScanPoints = async (userData) => {
    setSearchData(userData)
    setStartDate(userData.start_date);
    setEndDate(userData.end_date);
    userData = {...userData, page: currentPage };
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'post',
      url: `${TENANT_URL}/search/scan/qr/code`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    try {
      const response = await axios({ ...getConfig, data: data });
      setAllPointsData(response.data.data);
      setRowsPerPage(response.data.meta.per_page);
      setNoOfPages(Math.ceil(response.data.meta.total / 10));
      setTotalRecords(response.data.meta.total);
    } catch (error) {
      console.error(error);
      show('Data not Found ', 'error');
    }
    setLoading(false);
  };

  const searchRedeemPoints = async (searchData) => {
    setSearchData(searchData)
    setStartDate(searchData.start_date);
    setEndDate(searchData.end_date);
    var data = JSON.stringify(searchData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const setData = {
      method: 'post',
      url: `${TENANT_URL}/search/scan/qr/code`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    try {
      const response = await axios({ ...setData, data: data });
      setAllPointsData(response.data.data);
      setRowsPerPage(response.data.meta.per_page || 100);
      setCurrentPage(1);
      setNoOfPages(Math.ceil(response.data.meta.total / 10));
      setTotalRecords(response?.data?.meta?.total || 0);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };

  useEffect(() => {
    const tempFun = () => {
      const currentDate = new Date();
      const formattedCurrentDate = getFormattedDate(currentDate);

      const dateFrom = getFormattedDate(
        new Date(currentDate.setMonth(currentDate.getMonth() - 1))
      );
      const dateTo = formattedCurrentDate;
      getScanPoints({ start_date: dateFrom, end_date: dateTo});
    };
    tempFun();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCurrentPage(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const contextValue = {
    setLoading,
    loading,
    allPointsData,
    rowsPerPage,
    currentPage,
    noOfPages,
    getScanPoints,
    start_date,
    end_date,
    getCurrentPage,
    totalRecords,
    searchRedeemPoints,
    searchData
  };

  return (
    <PointsContext.Provider value={contextValue}>
      {children}
    </PointsContext.Provider>
  );
};

const usePoints = () => useContext(PointsContext);

export { PointsProvider, usePoints };
