const RedeemRequestCol = [
  'user_type',
  'name',
  'phone',
  'account_number',
  'bank',
  'ifsc',
  'redeem_points',
  'amount',
  'status',
  'neft',
  
];

export default RedeemRequestCol;
