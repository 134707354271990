import React from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const CustomBox = ({ value, selected, onClick }) => {
  return (
    <Box
      sx={{
        width: '30px',
        height: '30px',
        display: 'flex',
        border: `1px solid ${!selected ? '#DDDDDD' : 'button.menu'}`,
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: selected ? 'button.menu' : 'transparent',
        color: selected ? 'white' : 'button.menu',
      }}
      onClick={onClick}
    >
      {value}
    </Box>
  );
};

const Pagination = ({
  currentPage,
  maxPages,
  setCurrentPage,
  totalRecords,
}) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography
        sx={{
          marginRight: '10px',
          marginTop: '4px',
          marginLeft: { xs: '10px', md: 'auto' },
        }}
      >
        Total Records: {totalRecords}
      </Typography>
      {currentPage > 1 && (
        <CustomBox
          value={<KeyboardArrowLeftIcon />}
          onClick={() => {
            localStorage.setItem("pageNo", currentPage - 1);
            setCurrentPage(currentPage - 1);
          }}
        />
      )}
      {currentPage > 1 && (
        <CustomBox
          value={currentPage - 1}
          onClick={() => {
            localStorage.setItem("pageNo", currentPage - 1);
            setCurrentPage(currentPage - 1);
          }}
        />
      )}
      <CustomBox value={currentPage} selected={true} />
      {currentPage < maxPages && (
        <CustomBox
          value={currentPage + 1}
          onClick={() => {
            localStorage.setItem("pageNo", currentPage - 1);
            setCurrentPage(currentPage + 1);
          }}
        />
      )}
      {currentPage < maxPages && (
        <CustomBox
          value={<KeyboardArrowRightIcon />}
          onClick={() => {
            localStorage.setItem("pageNo", currentPage - 1);
            setCurrentPage(currentPage + 1);
          }}
        />
      )}
    </Box>
  );
};

export default Pagination;
