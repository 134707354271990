import { Navigate, Outlet } from 'react-router-dom';
import { useTenantAuth } from './auth';

const NonLoginRoute = () => {
  const { user } = useTenantAuth();
  return (
    <div>
      {!user ? (
        <>
          <Outlet />
        </>
      ) : (
        <Navigate to="/" />
      )}
    </div>
  );
};

export default NonLoginRoute;
