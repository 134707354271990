import axios from 'axios';
import { TENANT_URL } from '../../../config';

export const generateQRCode = async (product_id, number) => {
  let data = JSON.stringify({
    product_id,
    number,
    is_active: true,
  });

  const token = JSON.parse(localStorage.getItem('userdata')).token;

  let config = {
    method: 'post',
    url: `${TENANT_URL}/qr/code/list`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data,
  };

  const response = await axios(config);
  return response.data;
};

export const getAllActiveProducts = async () => {
  const token = JSON.parse(localStorage.getItem('userdata')).token;

  let config = {
    method: 'get',
    url: `${TENANT_URL}/select/all/active/product`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios(config);
  return response.data.data;
};
