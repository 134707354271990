import styled from "@emotion/styled";
import { Box, Paper, Typography, Button, TextField, Modal, Grid, TableCell } from "@mui/material";
import { Container } from "@mui/system";

export const TableHeader = styled('thead')(() => ({
    // background:"red",
    background:"#f1f4f7",
    })
    )
export const TableCells = styled(TableCell)(() => ({
   fontWeight:"bold",
   fontSize:"17px",
   '@media (max-width: 588px)': { fontSize: "13px",},
    })
    )