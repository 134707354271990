import React from 'react';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ViewInfoCards from '../viewInfoCard/viewInfoCard';

export default function ViewOrderHeader() {
  const orderDetailData = {
    title: 'Order Detail',
    titleIcon: ShoppingCartCheckoutIcon,
    bgColor: '#f3c200',
    orderDetail: [
      { item: 'Order#', value: 'food' },
      { item: 'Order Data and Time', value: 'food' },
      { item: 'Order Status', value: 'food' },
      { item: 'Grand Total #', value: 'food' },
    ],
  };
  const customerInfo = {
    title: 'Customer Information',
    titleIcon: '',
    bgColor: '#67809f',
    orderDetail: [
      { item: 'Distrubuter Name#', value: 'AQ KHAN' },
      { item: 'Mobile Number', value: '+923341254334' },
      { item: 'City/State', value: 'Peshawer' },
      { item: 'Discount(%)', value: '0.00%' },
    ],
  };
  return (
    <>
      <Box>
        <Box sx={{ width: '100%', background: '#eff3f8', px: 2, py: 1 }}>
          <List
            style={{
              listStyleType: 'disc',
              listStylePosition: 'inside',
              display: 'flex',
              width: '300px',
              padding: '0 0 0px',
              color: '#a7b2c0',
            }}
          >
            <ListItem
              sx={{
                display: 'list-item',
                fontSize: '14px',
                padding: ' 0px',
                margin: '0px',
              }}
            >
              Home
            </ListItem>
            <ListItem
              sx={{ display: 'list-item', fontSize: '14px', padding: ' 0px' }}
            >
              Order
            </ListItem>
            <ListItem
              sx={{ display: 'list-item', fontSize: '14px', padding: ' 0px' }}
            >
              View
            </ListItem>
          </List>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
            py: 3,
            borderBottom: 'solid 1px lightgray',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SettingsIcon />
            <Typography
              sx={{
                color: '#2f353b !important',
                fontWeight: '600',
                fontSize: '16px',
              }}
            >
              ORDER PO/2023/12/4398 | DEC 14, 2023 07:35:59
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Button
              variant="outlined"
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                fontSize: '13px',
                px: 1,
                color: 'red',
                borderColor: 'red',
                py: 0,
                ':hover': {
                  background: 'red',
                  color: 'white',
                  borderColor: 'red',
                  textTransform: 'none',
                },
              }}
            >
              Download Purchase Order
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderRadius: '20px',
                textTransform: 'none',
                fontSize: '13px',
                px: 1,
                color: '#9acbed',
                borderColor: '#9acbed',
                py: 0,
                ':hover': {
                  background: '#9acbed',
                  color: 'white',
                  borderColor: '#9acbed',
                },
              }}
            >
              Reject Order
            </Button>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
          gap: 3,
          my: 2,
        }}
      >
        <ViewInfoCards cardData={orderDetailData} />
        <ViewInfoCards cardData={customerInfo} />
      </Box>
    </>
  );
}
