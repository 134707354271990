import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { TENANT_URL } from '../../../config';
import ThrowError from '../../utils/errors';
import { useSnackbar } from '../../../commons/snackBarContextProvider';

const RedeemRequestContext = createContext();

export const useRedeemRequestContext = () => useContext(RedeemRequestContext);

export const RedeemRequestProvider = ({ children }) => {
  const [RedeemRequest, setRedeemRequest] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rowsPrPage, setRowsPrPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const { show } = useSnackbar();

  const token = JSON.parse(localStorage.getItem('userdata')).token;

  useEffect(() => {
    setLoading(true);
    getPage();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCurrentPage(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const getCurrentPage = async (pageNo) => {
    setCurrentPage(pageNo);
    try {
      const res = await axios.get(
        `${TENANT_URL}/redeem/request?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setRedeemRequest(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getPage = async () => {
    try {
      const res = await axios.get(
        `${TENANT_URL}/redeem/request?page=${currentPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRowsPrPage(res.data.meta.per_page);
      setCurrentPage(res.data.meta.current_page);
      setNoOfPages(Math.ceil(res.data.meta.total / 10));
      setTotalRecords(res.data.meta.total);
    } catch (error) {
      console.log(error);
    }
  };

  const searchRedeemReqs = async (searchData) => {
    var data = JSON.stringify(searchData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const setData = {
      method: 'post',
      url: `${TENANT_URL}/search/redeem/request`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    try {
      const response = await axios({ ...setData, data: data });
      setRedeemRequest(response.data.data);
      setRowsPrPage(100);
      setCurrentPage(1);
      setNoOfPages(1);
      setTotalRecords(10);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };

  const values = {
    RedeemRequest,
    loading,
    rowsPrPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchRedeemReqs,
  };

  return (
    <RedeemRequestContext.Provider value={values}>
      {children}
    </RedeemRequestContext.Provider>
  );
};
