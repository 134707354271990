const RedeemRequestSearchCol = [
  {
    id: 'user_type',
    numeric: false,
    disablePadding: true,
    label: 'User Type',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    // id: 'phone',
    id: 'contact_number',
    numeric: false,
    disablePadding: true,
    label: 'Phone',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

export default RedeemRequestSearchCol;
