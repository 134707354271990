import React from 'react';
import Page from '../pageData/page';
import ProductCatDataCol from '../../components/tabledata/productsTable/prodCatDataCol';
import ProductCatSearchCol from '../../components/tabledata/productsTable/searchFieldsCat';
import ProductCatCol from '../../components/tabledata/productsTable/prodCatCol';
import { useProductCategory } from '../../services/products/productCategory';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';

const ProductCategory = () => {
  const {
    loading,
    addData,
    allProductData,
    deleteProd,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchProductService,
  } = useProductCategory();



  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_products_tab != (1||"1") ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Page
              columns={ProductCatDataCol}
              rows={allProductData}
              data={ProductCatCol}
              temp={'id'}
              showGradientButton={true}
              showQRButton={false}
              delData={deleteProd}
              addData={addData}
              updateData={updateData}
              toggle={active}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              total={noOfPages}
              getPage={getCurrentPage}
              totalRecords={totalRecords}
              searchFields={ProductCatSearchCol}
              search={searchProductService}
            />
          )}
        </>
      )}
    </>
  );
};

export default ProductCategory;
