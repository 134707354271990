
const OrdersDataCol = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'order_no',
      numeric: false,
      disablePadding: true,
      label: 'Order No.',
    },
    // {
    //   id: 'user_type',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'User Type',
    // },
    {
      id: 'distributor_name',
      numeric: false,
      disablePadding: true,
      label: 'Distributor Name',
    },
    {
      id: 'distributor_state',
      numeric: false,
      disablePadding: false,
      label: 'State',
    },
    {
      id: 'distributor_city',
      numeric: false,
      disablePadding: false,
      label: 'City',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
    },
    {
      id: 'shop_name',
      numeric: false,
      disablePadding: true,
      label: 'Shop Name',
    },
    {
      id: 'distributor_contact_number',
      numeric: true,
      disablePadding: true,
      label: 'Phone',
    },
    {
      id: 'grand_total',
      numeric: false,
      disablePadding: true,
      label: 'Net Value',
    },
    {
      id: 'order_date_time',
      numeric: false,
      disablePadding: true,
      label: 'Order Date',
    },
    {
      id: 'gst_number',
      numeric: false,
      disablePadding: false,
      label: 'GST Number',
    },
    {
      id: 'password',
      numeric: false,
      disablePadding: false,
      label: 'Password',
    },
    {
      id: 'total_points',
      numeric: true,
      disablePadding: false,
      label: 'Total Points',
    },
    {
      id: 'avaiable_points',
      numeric: true,
      disablePadding: false,
      label: 'Available Points',
    },
    {
      id: 'pan_number',
      numeric: false,
      disablePadding: false,
      label: 'PAN Number',
    },
    {
      id: 'account_number',
      numeric: false,
      disablePadding: false,
      label: 'Account Number',
    },
    {
      id: 'aadhar_number',
      numeric: false,
      disablePadding: false,
      label: 'Aadhar Number',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Address',
    },
    {
      id: 'ifsc_code',
      numeric: false,
      disablePadding: true,
      label: 'IFSC',
    },
    {
      id: 'pin_code',
      numeric: false,
      disablePadding: false,
      label: 'Pin Code',
    },
    {
      id: 'bank_name',
      numeric: false,
      disablePadding: false,
      label: 'Bank',
    },
    {
      id: 'order_status',
      numeric: false,
      disablePadding: false,
      label: 'Order Status',
    },
    // {
    //   id: 'is_active',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Is Active',
    // },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
  ];

  
  export default OrdersDataCol;
  