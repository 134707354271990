import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Card, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTenantAuth } from '../../../auth/tenant/auth';
import FormInitialValues from '../../components/validations/initalValues';
import { useFormik } from 'formik';
import AddSchema from '../../components/validations/validationsSchema';
import gridImage from '../../../images/QRImage.png';


export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { login, loading } = useTenantAuth();

  const handleSubmit = async (event) => {
    try {
      await login({ email, password });
      navigate('/');
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  const formik = useFormik({
    initialValues: FormInitialValues,
    validationSchema: AddSchema({
      email: true,
      password: true,
    }),
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundImage: `url(${"background"})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
          justifyContent: 'flex-end',
        }}
        height="100vh"
      >
        <Box
          sx={{
            width: '470px',
            width: {
              xs: '0%',
              sm: '470px',
            },
            height: '500px',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: { xs: "0px", sm: "300px", lg: '400px' },
              height: { xs: "p;x", sm: "250px", lg: '500px' },
              background: `url(${gridImage})`,
              // margin: 'auto',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              top: 0,
              bottom: {
                xs: 'unset',
                lg: 0,
              },
              left: { sm: "-30%", lg: "-42%" },
              right: {
                xs: 0,
                lg: 'unset',
              },
              transition: '.3s',

            }}
          >
            <Box sx={{ display:{xs:"none",sm:"block"},position: "absolute",left:"42%",bottom:{xs:"0%",lg:"18%"} }}>
              QRCMS
            </Box>

          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            backgroundSize: '100% 100%',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            alignItems: 'center',
            backdropFilter: 'blur(8px)',
            background: 'linear-gradient(100.91deg, rgba(250, 191, 89, 0.36) 0%, rgba(248, 191, 89, 1) 99.58%, rgba(248, 191, 89,1) 67.58%, rgba(250, 191, 89, 1.3) 100%)'
            // background: 'linear-gradient(100.91deg, rgba(250, 191, 89, 0.36) 0%, rgba(248, 191, 89, 1) 50%, rgba(248, 191, 89,1) 50%, rgba(250, 191, 89, 1.3) 100%)'
            // background:'linear-gradient(100.91deg, rgba(250, 191, 89, 0.36) 0%, rgba(248, 191, 89, 0.234) 39.58%, rgba(248, 191, 89, 0.3) 64.58%, rgba(250, 191, 89, 0.3) 100%)'
            // background:"#FABF59"
          }}
        >
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '500px',
              },
              minHeight: '600px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              // borderRadius: '41.88px',
              zIndex: 1,
              padding: '20px 60px',
              boxSizing: 'border-box',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                gap: '20px',
              }}
            >
              <Typography
                color="#3F2F5B"
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: '45.69px',
                  fontWeight: '700',
                  lineHeight: '55.91px',
                  textAlign: 'center',
                }}
              >
                Login
              </Typography>
              <Typography
                mt={2}
                variant="body1"
                color="#8B739B"
                sx={{
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '35.54px',
                  textAlign: 'center',
                }}
              >
                Log In to your account to manage all services and explore our
                goals.
              </Typography>
            </Box>

            <Box>
              <form
                onSubmit={(e) => {
                  if (formik.handleSubmit(e)) handleSubmit(e);
                }}
                onReset={formik.handleReset}
              >
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom align="left">
                    Email
                  </Typography>
                  <TextField
                    fullWidth
                    id="email"
                    type="email"
                    variant="outlined"
                    disabled={loading}
                    value={email}
                    error={formik.errors['email'] && formik.touched['email']}
                    helperText={
                      formik.errors['email'] && formik.touched['email']
                        ? formik.errors['email']
                        : ''
                    }
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange(event);
                      setEmail(event.target.value);
                    }}
                  />
                </Box>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom align="left">
                    Password
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={loading}
                    id="password"
                    type="password"
                    variant="outlined"
                    value={password}
                    error={formik.errors['password'] && formik.touched['password']}
                    helperText={
                      formik.errors['password'] && formik.touched['password']
                        ? formik.errors['password']
                        : ''
                    }
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      formik.handleChange(event);
                      setPassword(event.target.value);
                    }}
                  />
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={!formik.isValid || loading}
                  sx={{
                    boxShadow: 'none',
                    textTransform: 'none',
                    height: '2.5rem',
                  }}
                >
                  {loading ? `Loging in` : `Login`}
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

// <Box
// sx={{
//   display: 'flex',
//   backgroundImage: `url(${background})`,
//   backgroundSize: 'cover',
//   backgroundPosition: 'center center',
//   backgroundRepeat: 'no-repeat',
//   position: 'relative',
//   justifyContent: 'flex-end',
//   px: {
//     xs: '15px',
//     sm: '50px',
//     md: '100px',
//   },
// }}
// height="100vh"
// >
// <Box
//   sx={{
//     width: '470px',
//     width: {
//       xs: '90%',
//       sm: '470px',
//     },
//     height: '470px',
//     position: 'absolute',
//     top: 0,
//     bottom: 0,
//     left: 0,
//     right: 0,
//     margin: 'auto',
//   }}
// >
//   <Box
//     sx={{
//       background: `url(${frameSVG})`,
//       margin: 'auto',
//       backgroundPosition: 'center',
//       backgroundSize: 'contain',
//       backgroundRepeat: 'no-repeat',
//       width: '100%',
//       height: '100%',
//       mixBlendMode: 'screen',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//     }}
//   ></Box>
//   <Box
//     sx={{
//       position: 'absolute',
//       width: '270px',
//       height: '115px',
//       background: `url(${forkSVG})`,
//       margin: 'auto',
//       backgroundPosition: 'center',
//       backgroundSize: 'contain',
//       backgroundRepeat: 'no-repeat',
//       top: 0,
//       bottom: {
//         xs: 'unset',
//         lg: 0,
//       },
//       left: 0,
//       right: {
//         xs: 0,
//         lg: 'unset',
//       },
//       transform: {
//         xs: 'translateY(calc(-100% - 50px))',
//         lg: 'translateX(calc(-100% - 50px))',
//       },
//       transition: '.3s',
//       '&:before': {
//         content: "''",
//         position: 'absolute',
//         width: '200px',
//         background: `url(${forkShadowSVG})`,
//         margin: 'auto',
//         backgroundPosition: 'center',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//         top: 0,
//         bottom: '-90px',
//         backgroundBlendMode: 'overlay',
//         opacity: '.1',
//         left: '0',
//         right: '0',
//         mixBlendMode: 'Overlay',
//       },
//     }}
//   ></Box>
// </Box>
// <Box
//   sx={{
//     display: 'flex',
//     backgroundSize: '100% 100%',
//     backgroundPosition: 'center center',
//     backgroundRepeat: 'no-repeat',
//     alignItems: 'center',
//   }}
// >
//   <Card
//     sx={{
//       boxShadow:
//         '0px 4.472850322723389px 40.255653381347656px 1.1182125806808472px #6C0C2547',
//       background:
//         'linear-gradient(100.91deg, rgba(255, 255, 255, 0.36) 0%, rgba(248, 251, 252, 0.234) 39.58%, rgba(248, 251, 252, 0.3) 64.58%, rgba(255, 255, 255, 0.3) 100%)',
//       width: {
//         xs: '100%',
//         sm: '500px',
//       },
//       minHeight: '600px',
//       display: 'flex',
//       flexDirection: 'column',
//       justifyContent: 'center',
//       borderRadius: '41.88px',
//       backdropFilter: 'blur(8px)',
//       zIndex: 1,
//       padding: '20px 60px',
//       boxSizing: 'border-box',
//       gap: '20px',
//     }}
//   >
//     <Box
//       sx={{
//         gap: '20px',
//       }}
//     >
//       <Typography
//         color="#3F2F5B"
//         sx={{
//           fontFamily: 'Roboto',
//           fontSize: '45.69px',
//           fontWeight: '700',
//           lineHeight: '55.91px',
//           textAlign: 'center',
//         }}
//       >
//         Login
//       </Typography>
//       <Typography
//         mt={2}
//         variant="body1"
//         color="#8B739B"
//         sx={{
//           fontSize: '18px',
//           fontWeight: '400',
//           lineHeight: '35.54px',
//           textAlign: 'center',
//         }}
//       >
//         Log In to your account to manage all services and explore our
//         goals.
//       </Typography>
//     </Box>

//     <Box>
//       <LoginForm />
//     </Box>
//   </Card>
// </Box>
// </Box>