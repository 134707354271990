const ProdSearchCol = [
  {
    id: 'product_category',
    numeric: false,
    disablePadding: false,
    label: 'Product Category',
  },
  {
    id: 'part_number',
    numeric: false,
    disablePadding: false,
    label: 'Part Number',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Active',
  },
];

export default ProdSearchCol;
