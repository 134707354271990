const MarketingExecutivesDataCol = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      required: true,
      label: 'ME Name',
    },
    {
      id: 'me_distributor',
      numeric: false,
      disablePadding: true,
      // required: true,
      label: 'Distributors',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      required: true,
      label: 'Email',
    },
    {
      id: 'contact_number',
      numeric: true,
      disablePadding: true,
      required: true,
      label: 'Phone',
    },
    {
      id: 'password',
      numeric: false,
      disablePadding: false,
      required: true,
      label: 'Password',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      required: true,
      label: 'Postal Address',
    },
    {
      id: 'state_name',
      numeric: false,
      disablePadding: false,
      required: true,
      label: 'State',
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      required: true,
      label: 'State',
    },
    {
      id: 'city',
      numeric: false,
      disablePadding: false,
      required: true,
      label: 'City',
    },
    // {
    //   id: 'shop_name',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Shop Name',
    // },
    // {
    //   id: 'gst_number',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'GST Number',
    // },
    // {
    //   id: 'total_points',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Total Points',
    // },
    // {
    //   id: 'avaiable_points',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'Available Points',
    // },
    // {
    //   id: 'pan_number',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'PAN Number',
    // },
    // {
    //   id: 'account_number',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Account Number',
    // },
    // {
    //   id: 'aadhar_number',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Aadhar Number',
    // },
    // {
    //   id: 'ifsc_code',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'IFSC',
    // },
    {
      id: 'pin_code',
      numeric: false,
      disablePadding: false,
      label: 'PIN Code',
    },
    // {
    //   id: 'bank_name',
    //   numeric: false,
    //   disablePadding: false,
    //   label: 'Bank',
    // },
    {
      id: 'is_active',
      numeric: false,
      disablePadding: false,
      label: 'Is Active',
    },
    {
      id: 'actions',
      numeric: false,
      disablePadding: false,
      label: 'Actions',
    },
  ];
  
  export default MarketingExecutivesDataCol;
  