import React from 'react';
import { Box, Typography } from '@mui/material';
import ViewOrderHeader from './components/viewOrderHeader/viewOrderHeader';
import TableData from '../../../../commons/table/table';
import ViewOrderFooter from './components/viewOrderFooter/viewOrderFooter';

export default function ViewOrder() {
  return (
    <>
      <Box sx={{ width: '100%', py: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, px: 2 }}>
          <Typography
            variant="p"
            sx={{
              color: '#697882',
              fontSize: '22px',
              fontWeight: '400',
              margin: 0,
            }}
          >
            Order View
          </Typography>
          <Typography
            variant="p"
            sx={{ fontSize: '13px', color: '#9eacb4', fontWeight: '400' }}
          >
            order View
          </Typography>
        </Box>
        <Box sx={{ border: 'solid 15px #eff3f8' }}>
          <ViewOrderHeader />
          <Box>
            <Box>
              <ViewOrderFooter />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
