const prodDataCol = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'Category ID',
  },
  {
    id: 'category_name',
    numeric: false,
    disablePadding: true,
    label: 'Category Name',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Updated At',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Is Active',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

export default prodDataCol;
