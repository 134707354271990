import React from 'react';
import { Button, styled } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

const GradientButtonStyle = styled(Button)(({ theme }) => ({
  background: theme.palette.button.gradient,
  marginRight:"0.7rem",
  borderRadius: '12px',
  color: theme.palette.text.primary,
  padding: '16px 32px',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '8px',
  '&:hover': {
    background: theme.palette.button.menu,
  },
  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));

const UploadGradientButton = ({ name, onClick, showQr,UploadIcon }) => {
  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      {!showQr ? (
        <GradientButtonStyle
          variant="contained"
          startIcon={UploadIcon?<UploadIcon /> :<AddBoxOutlinedIcon />}
          onClick={handleButtonClick}
        >
          {name}
        </GradientButtonStyle>
      ) : (
        <GradientButtonStyle
          variant="contained"
          startIcon={<QrCodeScannerIcon />}
          onClick={handleButtonClick}
        >
          {name}
        </GradientButtonStyle>
      )}
    </>
  );
};
export default UploadGradientButton;
