const DistributorsCol = [
    'name',
    'contact_number',
    // 'bank_name',
    'email',
    'state_name',
    'city',
    // 'total_points',
    // 'avaiable_points',
    'landing_cost',
    // 'password',
    'is_active',
    'actions',
  ];
  
  export default DistributorsCol;
  