const GiftSearchCol = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'points',
    numeric: true,
    disablePadding: false,
    label: 'Points',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Active',
  },
];

export default GiftSearchCol;
