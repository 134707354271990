import React from 'react';
import Page from '../pageData/page';
import OrdersDataCol from '../../components/tabledata/ordersTable/ordersDataCol';
import OrdersCol from '../../components/tabledata/ordersTable/ordersCol';

import { useOrder } from '../../services/orders/ordersService';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';
import OrdersSearchCol from '../../components/tabledata/ordersTable/searchFields';
import allOrderData from '../../services/orders/ordersService';

const Orders = () => {
  const {
    loading,
    addData,
    allOrderData,
    deleteMech,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchOrders,
    setCurrentOrder,
  } = useOrder();
  
  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_order_tab != (1||"1") ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Page
              columns={OrdersDataCol}
              rows={allOrderData}
              data={OrdersCol}
              temp={'id'}
              showGradientButton={false}
              showQRButton={false}
              delData={deleteMech}
              addData={addData}
              updateData={updateData}
              toggle={active}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              total={noOfPages}
              getPage={getCurrentPage}
              totalRecords={totalRecords}
              searchFields={OrdersSearchCol}
              search={searchOrders}
              setCurrentOrder={setCurrentOrder}
            />
          )}
        </>
      )}
    </>
  );
};

export default Orders;
  