import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import arrowDown from '../../../images/arrowDown.png';
import arrowUp from '../../../images/arrowUp.png';
import graph from '../../../images/dashboardGraph1.png';
import graph2 from '../../../images/dashboardGraph2.png';
import { TENANT_URL } from '../../../config';
import { QrCode, ProductionQuantityLimits, Storefront, Person } from '@mui/icons-material';

const DashboardContext = createContext();

const DashboardProvider = ({ children }) => {
  const [productCount, setProductCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [retailer, setRetailer] = useState(0);
  const [mechanic, setMechanic] = useState(0);
  const [qrCode, setqrCode] = useState(0);
  const [scannedPoints, setScannedPoints] = useState([]);
  const [graphInfo, setGraphInfo] = useState({});

  const userData = localStorage.getItem('userdata');
  const user = JSON.parse(userData);
  const name = user ? user.name : '';

  useEffect(() => {
    fetchMonthlyGraphs()
    fetchProductData();
    fetchMechanic();
    fetchRetailerData();
    fetchQR();
    fetchEarnPoints()
    // eslint-disable-next-line
  }, []);

  const fetchEarnPoints = async () => {
    const token = user ? user.token : null;
    setLoading(true);
    try {
      const res = await axios.get(`${TENANT_URL}/total/scanned/points `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = res;
      setScannedPoints(data || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const fetchMonthlyGraphs = async () => {
    const token = user ? user.token : null;
    setLoading(true);
    try {
      const res = await axios.get(`${TENANT_URL}/total/months/points `, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = res;
console.log("datadata",data)
setGraphInfo(data)
      // setScannedPoints(data || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const fetchProductData = async () => {
    const token = user ? user.token : null;
    setLoading(true);
    try {
      const res = await axios.get(`${TENANT_URL}/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setProductCount(res.data.meta.total);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchRetailerData = async () => {
    const token = user ? user.token : null;
    setLoading(true);
    try {
      const res = await axios.get(`${TENANT_URL}/retailer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRetailer(res.data.meta.total);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchMechanic = async () => {
    const token = user ? user.token : null;
    setLoading(true);
    try {
      const res = await axios.get(`${TENANT_URL}/mechanic`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setMechanic(res.data.meta.total);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchQR = async () => {
    const token = user ? user.token : null;
    setLoading(true);
    try {
      const res = await axios.get(`${TENANT_URL}/qr/code`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setqrCode(res.data.meta.total);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const cardData = [
    {
      id: 0,
      mainText: `${retailer}`,
      percentageText: '20%',
      icon: <Storefront color="primary" sx={{ width: 35, height: 35 }} />,
      graphImg: graph2,
      cardName: 'Retailers',
    },
    {
      id: 1,
      mainText: `${mechanic}`,
      percentageText: '20%',
      icon: <Person color="info" sx={{ width: 35, height: 35 }} />,
      graphImg: graph,
      cardName: 'Mechanics',
    },
    {
      id: 2,
      mainText: `${productCount}`,
      percentageText: '20%',
      icon: <ProductionQuantityLimits color="success" sx={{ width: 35, height: 35 }} />,
      graphImg: graph2,
      cardName: 'Products',
    },
    {
      id: 3,
      mainText: `${qrCode}`,
      percentageText: '20%',
      icon: <QrCode color="error" sx={{ width: 35, height: 35 }} />,
      graphImg: graph,
      cardName: 'QR Code',
    },
  ];

  return (
    <DashboardContext.Provider
      value={{
        productCount,
        loading,
        retailer,
        mechanic,
        qrCode,
        name,
        cardData,
        graphInfo,
        scannedPoints
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error(
      'useDashboardContext must be used within a DashboardProvider'
    );
  }
  return context;
};

export { DashboardProvider, useDashboardContext };
