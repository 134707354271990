import arrowDown from '../../images/arrowDown.png';
import arrowUp from '../../images/arrowUp.png';
import graph from '../../images/dashboardGraph1.png';
import graph2 from '../../images/dashboardGraph2.png';

export const cardData = [
  {
    id: 0,
    mainText: '34.k',
    percentageText: '20%',
    arrowImg: arrowDown,
    graphImg: graph,
  },
  {
    id: 1,
    mainText: '34.k',
    percentageText: '20%',
    arrowImg: arrowUp,
    graphImg: graph2,
  },
  {
    id: 2,
    mainText: '34.k',
    percentageText: '20%',
    arrowImg: arrowDown,
    graphImg: graph,
  },
  {
    id: 3,
    mainText: '34.k',
    percentageText: '20%',
    arrowImg: arrowUp,
    graphImg: graph2,
  },
];

export const earnedPointsData = [
  {
    id: 0,
    title: 'Earned Points',
    pendingText: 'Pending till 31st March 2022',
    pendingValue: '20,000',
    financialYearValue: '40,000',
    totalValue: '60,000',
  },
  {
    id: 1,
    title: 'Earned Points',
    pendingText: 'Pending till 30th April 2022',
    pendingValue: '25,000',
    financialYearValue: '50,000',
    totalValue: '75,000',
  },
  {
    id: 2,
    title: 'Earned Points',
    pendingText: 'Pending till 30th April 2022',
    pendingValue: '25,000',
    financialYearValue: '50,000',
    totalValue: '75,000',
  },
  {
    id: 3,
    title: 'Earned Points',
    pendingText: 'Pending till 30th April 2022',
    pendingValue: '25,000',
    financialYearValue: '50,000',
    totalValue: '75,000',
  },
];
