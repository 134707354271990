const MarketingExecutivesCol = [
    'name',
    'contact_number',
    // 'bank_name',
    'state_name',
    'city',
    // 'me_distributor',
    // 'password',
    // 'total_points',
    // 'avaiable_points',
    'is_active',
    'actions',
  ];
  
  export default MarketingExecutivesCol;
  