const RedPointsDataCol = [
  {
    id: 'user_type',
    numeric: false,
    disablePadding: true,
    label: 'User Type',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
    label: 'Phone',
  },
  {
    id: 'part_number',
    numeric: false,
    disablePadding: false,
    label: 'Part Number',
  },
  {
    id: 'scan_point',
    numeric: false,
    disablePadding: false,
    label: 'Scan Point',
  },
  {
    id: 'location',
    numeric: false,
    disablePadding: false,
    label: 'Coupon Scanned Location',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
];

export default RedPointsDataCol;
