import { useEffect, useState } from 'react';

const UserList = () => {
  const val = JSON.parse(localStorage.getItem('userdata'));
  const permissions = val.tenant_nav_bar_permission;
  const [userPages, setUserPages] = useState([]);

  useEffect(() => {
    const updatedTenantPages = [];
    if (permissions?.is_retailer_tab == (1 || "1")) {
      updatedTenantPages.push(
        {
          name: 'Retailer(S)',
          link: '/retailer',
        }
      );
    }
    if (permissions?.is_mechanic_tab == (1 || "1")) {
      updatedTenantPages.push(
        {
          name: 'Mechanic(S)',
          link: '/mechanic',
        },
      );
    }
    if (permissions?.is_distributor_tab == (1 || "1")) {
      updatedTenantPages.push(
        {
          name: 'Distributor(S)',
          link: '/distributor',
        },
      );
    }
    if (permissions?.is_marketing_executive_tab == (1 || "1")) {
      updatedTenantPages.push(
        {
          name: 'Markrting Executive (ME)',
          link: '/marketingExecutive',
        }
      );
    }
    setUserPages(updatedTenantPages);
    // eslint-disable-next-line
  }, [permissions]);
  // useEffect(() => {
  //   const updatedTenantPages = [];
  //   if (permissions?.is_redeem_request_tab === 1) {
  //     updatedTenantPages.push(
  //       {
  //           name: 'Retailer(S)',
  //           link: '/retailer' ,
  //       },
  //       {
  //         name: 'Mechanic(S)',
  //         link: '/mechanic',
  //       },
  //       // {
  //       //   name: 'Distributor(S)',
  //       //   link: '/distributor',
  //       // },
  //       // {
  //       //   name: 'Markrting Executive (ME)',
  //       //   link: '/marketingExecutive',
  //       // }
  //     );
  //   }
  //   setUserPages(updatedTenantPages);
  //   // eslint-disable-next-line
  // }, [permissions]);

  return userPages;
};

export default UserList;
