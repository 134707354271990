import React, { useState } from 'react';
import TableData from '../../commons/table/table';
import { Box, Typography } from '@mui/material';
import GradientButton from '../../commons/gradiantComponents/gradientButton';
import DialogBox from '../../admin/adminDialogBox/adminAddRecord';
import PermissionDialogBox from '../../admin/managePermission/permissionDialogBox';

const ManageTenantPage = ({
  columns,
  rows: rowData,
  data,
  temp,
  showGradientButton,
  rowsPrPage,
  page,
  total,
  delData,
  updateData,
  addData,
  fetchPageData,
}) => {
  const [name] = useState('SuperAdmin');
  const [pageName] = useState('Manage Tenant (s)');
  const [showDialog, setShowDialog] = useState(false);
  const [showPermissionDialog, setShowPermissionDialog] = useState(false);
  // eslint-disable-next-line
  const [row, setRows] = useState(rowData);
  const [editData, setEditData] = useState([]);
  const [selectedTenantId, setSelectedTenantId] = useState(null);
  const [isEditDialog, setIsEditDialog] = useState(false);

  const handleClick = () => {
    setEditData([]);
    setShowDialog(!showDialog);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleAddRow = (newData, id) => {
    if (!id) {
      addData(newData);
    } else {
      newData.pan_card_number = newData.pan_card_number.toString();
      updateData(newData, id);
    }
  };

  const handleEditData = (val, del) => {
    if (del) {
      delData(val);
    } else {
      const matchedObject = rowData.find((row) => row[temp] === val);
      matchedObject.pan_card_number = matchedObject.pan_card_number.toString();
      setEditData(matchedObject);
      setIsEditDialog(true);
      setShowDialog(true);
    }
  };

  const handlePermissionClick = (tenantId) => {
    setSelectedTenantId(tenantId);
    const matchedObject = rowData.find((row) => row[temp] === tenantId);
    setEditData(matchedObject);
    setShowPermissionDialog(true);
  };

  const dataWithoutNameAndPassword = data.filter(
    (col) => col !== 'name' && col !== 'password'
  );

  const filteredColumns = columns.filter((col) =>
    dataWithoutNameAndPassword.includes(col.id)
  );

  const filteredRows = row
    .map((row) => {
      const filteredRow = {};
      dataWithoutNameAndPassword.forEach((col) => {
        filteredRow[col] = row[col];
      });
      return filteredRow;
    })
    .filter((row) => Object.values(row).join('').toLowerCase());

  const allColumns = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'company_name',
      numeric: false,
      disablePadding: false,
      label: 'Company Name',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: false,
      label: 'Name',
    },
    {
      id: 'password',
      numeric: false,
      disablePadding: false,
      label: 'Password',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'phone',
      numeric: false,
      disablePadding: false,
      label: 'Phone',
    },
    {
      id: 'country',
      numeric: false,
      disablePadding: false,
      label: 'Country',
    },
    {
      id: 'state',
      numeric: false,
      disablePadding: false,
      label: 'State',
    },
    {
      id: 'gst_number',
      numeric: false,
      disablePadding: false,
      label: 'GST Number',
    },
    {
      id: 'pan_card_number',
      numeric: false,
      disablePadding: false,
      label: 'Pan Card Number',
    },
    {
      id: 'address',
      numeric: false,
      disablePadding: false,
      label: 'Address',
    },
  ];

  const formColumns = [...allColumns];

  return (
    <Box>
      {showDialog && (
        <DialogBox
          name={'Tenants'}
          onClose={handleClose}
          data={formColumns}
          onAddRow={handleAddRow}
          editData={editData}
          id={temp}
          isEditMode={isEditDialog}
        />
      )}
      {showPermissionDialog && (
        <PermissionDialogBox
          id={selectedTenantId}
          onClose={() => setShowPermissionDialog(false)}
          data={columns}
          onAddRow={(newData) => {
            setShowPermissionDialog(false);
          }}
          editData={editData}
        />
      )}
      <Box sx={{ mx: '40px', my: '20px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { md: 'row', xs: 'column' },
            justifyContent: 'space-between',
            marginBottom: '24px',
            alignItems: { md: 'auto', xs: 'center' },
          }}
        >
          <Box sx={{ marginBottom: { md: 'auto', xs: '24px' } }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: { md: 'row', xs: 'column' },
              }}
            >
              <Typography
                sx={{
                  color: 'text.headings',
                  fontFamily: 'Poppins',
                  fontSize: 28,
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '36px',
                  textTransform: 'capitalize',
                }}
              >
                {name},
              </Typography>
              <Typography
                sx={{
                  color: 'text.headings',
                  fontFamily: 'Poppins',
                  fontSize: 28,
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '36px',
                  textTransform: 'capitalize',
                }}
              >
                &nbsp;{pageName}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: 'text.headings',
                fontFamily: 'Poppins',
                fontSize: 14,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '22px',
                textTransform: 'uppercase',
              }}
            >
              List of all the {name} Tenants
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: { xs: 'center', sm: 'flex-start' },
              justifyContent: { xs: 'center', sm: 'flex-start' },
              textAlign: { xs: 'center', sm: 'left' },
            }}
          >
            {showGradientButton && (
              <Box
                sx={{
                  marginBottom: { xs: '20px', sm: '0' },
                  marginRight: { xs: '0', sm: '8px' },
                  width: { xs: '100%', sm: 'auto' },
                }}
              >
                <GradientButton
                  name={`Add Tenant`}
                  onClick={handleClick}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <TableData
          columns={filteredColumns}
          rows={filteredRows}
          func={handleEditData}
          temp={temp}
          onPermissionClick={handlePermissionClick}
          rowsPPage={rowsPrPage}
          pageNo={page}
          total={total}
          getPage={fetchPageData}
        />
      </Box>
    </Box>
  );
};

export default ManageTenantPage;
