import React, { useState, useEffect } from 'react';
import {
  Customtypography,
  BoxContainer,
  MainTypography,
  CustomHr,
  CustomGrid,
  BoxWrapper,
  BoxWrapperTwo,
  CustomTypography,
  OrderTypography,
  CustomLi,
  CustomLiTwo,
  StatusLi,
  CustomContainer,
  BoxWrapperThree,
  CustomTableTypography,
  CustomTextField,
  CustomButton,
  BoxLast,
  CardWrapperTwo,
  CustomSpan,
  CustomLiOne,
  OneBox,
  CustomTextFieldDate,
  CustomGridOne,
  CustomGridTwo,
  CustomGridLast,
} from './OrderView.Style';
import * as XLSX from 'xlsx';
import TableComponent from './TableOrderView';
import { useOrder } from '../../services/orders/ordersService';
import { useParams } from 'react-router-dom';
import ThrowError from '../../utils/errors';
import ErrorPage from '../../utils/errorPage';
import { Toolbar, Grid, Typography, } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import { TENANT_URL } from '../../../config';
import moment from 'moment';
import { Box } from '@mui/system';
const ViewPage = () => {
  const { getOrderById, order:currentOrder } = useOrder();

  const [loading, setLoading] = useState(false);
  const userData = localStorage.getItem('userdata');
  const { orderId } = useParams();
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;
  const [grandTotalSaleValue, setGrandTotalSaleValue] = useState(0);
  const [allDistributorData, setAllDistributorData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { show } = useSnackbar();
  const [qrCodeOrderList, setQrCodeOrderList] = useState([]);
  const [invoice_no, setInvoice_no] = useState('');
  const [invoice_date, setInvoice_date] = useState('');
  const [invoiceNoError, setInvoiceNoError] = useState('');
  const [invoiceDateError, setInvoiceDateError] = useState('');
  const [isFileDownloaded, setIsFileDownloaded] = useState(
    JSON.parse(localStorage.getItem(`isFileDownloaded${orderId}`)) || false
  );
  useEffect(()=>{
    if(orderId){
     getOrderById(orderId).then(res=>{}).catch(err=>{})
    }
  },[orderId])
  const handleUpdateTotalSaleValue = (value) => {
    setGrandTotalSaleValue(value);
  };
  const handleUpdateInvoiceNo = (event) => {
    setInvoice_no(event.target.value);
    setInvoiceNoError('');
  };

  const handleUpdateInvoiceDate = (event) => {
    setInvoice_date(event.target.value);
    setInvoiceDateError('');
  };

  const handleSaveData = () => {
    
    let isValid = true;
    const invoiceNoPattern = /^[a-zA-Z0-9-]+$/;
    if (!invoiceNoPattern.test(invoice_no)) {
      setInvoiceNoError('Invoice number required.');
      isValid = false;
    } else if (invoice_no.length < 3) {
      setInvoiceNoError('Invoice number must be at least 3 characters long.');
      isValid = false;
    }
    if (!moment(invoice_date).isValid()) {
      setInvoiceDateError('Invoice date required.');
      isValid = false;
    }

    if (isValid) {
      updateData();
    }

  };
  const handleGenerateQrCode = () => {
    const ws = XLSX.utils.json_to_sheet(qrCodeOrderList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const filename = `QRCodes_${moment().format('YYYYMMDD_HHmmss')}.xlsx`;
    XLSX.writeFile(wb, filename);
    // Set isFileDownloaded to true and store it in localStorage
    setIsFileDownloaded(true);
    localStorage.setItem(`isFileDownloaded${orderId}`, JSON.stringify(true));
  };

  const TotalSaleValue = grandTotalSaleValue.toFixed(2);
  const grand_total = grandTotalSaleValue.toFixed(2);

  const orderz =
  currentOrder?.orders?.map((item) => (
      {
        "product_id": Number(item.id),
        "quantity": item.quantity,
      }
    ))
    const fetchQrCodeOrderList = async () => {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      const getConfig = {
        method: 'get',
        url: `${TENANT_URL}/qr/code/order/list/${orderId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      setLoading(true);
      try {
        const response = await axios(getConfig);
        setQrCodeOrderList(response.data.data);;
      } catch (error) {
        console.error(error);
        show('Record Not Found', 'error');
      }
      setLoading(false);
    };

  const getCurrentPage = async (pageNo) => {
    setCurrentPage(pageNo);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/distributor?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setAllDistributorData(response.data.data);
    } catch (error) {
      console.error(error);
      show('Data not Updated', 'error');
    }
  };

  const updateData = async (userData, id) => {
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const setData = {
      method: 'put',
      url: `${TENANT_URL}/orders/${orderId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    try {
      await axios({ ...setData, data: { invoice_date, invoice_no, order_status: "Approved Order", grand_total, orders: orderz } });
      show('Order Updated');
      await getCurrentPage(currentPage);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
    window.location.reload();
  };
  useEffect(() => {
    getCurrentPage(currentPage);
  },[currentPage]);

  useEffect(() => {
    fetchQrCodeOrderList();
  },[]);
  return (
    <>
      {permissions.is_order_tab != (1||"1") ? (
        <ErrorPage />
      ) : (
        <BoxContainer>
          <Toolbar>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CustomTypography>ORDER VIEW</CustomTypography>
              </Grid>
              <CustomGrid item xs={12}>
                <MainTypography>{currentOrder?.order_no}<CustomSpan> | {currentOrder?.order_date_time}</CustomSpan></MainTypography>
                <CustomHr />
              </CustomGrid>
              <CustomGridOne item xs={12} md={6} lg={6}>
                <BoxWrapper>
                  <OrderTypography>Order Details</OrderTypography>
                  <CustomContainer>
                    <ul>
                      <CustomLi>Order #:</CustomLi>
                      <CustomLi>Order Date & Time:</CustomLi>
                      <CustomLi>Order Status:</CustomLi>
                      {currentOrder.order_status === 'Approved Order' && (
                        <>
                          <CustomLi>Invoice No #:</CustomLi>
                          <CustomLi>Invoice Date #:</CustomLi>
                        </>
                      )}
                      <CustomLi>Grand Total:</CustomLi>
                    </ul>
                    <ul>
                      <CustomLiTwo>{currentOrder.order_no || "--"}</CustomLiTwo>
                      <CustomLiTwo>{currentOrder.order_date_time || "--"}</CustomLiTwo>
                      <StatusLi>{currentOrder.order_status || "--"}</StatusLi>
                      {currentOrder.order_status === 'Approved Order' && (
                        <>
                          <CustomLiTwo>{currentOrder.invoice_no || '--'}</CustomLiTwo>
                          <CustomLiTwo>{currentOrder.invoice_date || '--'}</CustomLiTwo>
                        </>
                      )}
                      <CustomLiTwo>{TotalSaleValue}</CustomLiTwo>
                    </ul>
                  </CustomContainer>
                </BoxWrapper>
              </CustomGridOne>
              <CustomGridOne item xs={12} md={6} lg={6}>
                <BoxWrapperTwo>
                  <Customtypography>Customer Information</Customtypography>
                  <CustomContainer>
                    <ul>
                      <CustomLiOne>Distributor Name:</CustomLiOne>
                      <CustomLiOne>Mobile Number:</CustomLiOne>
                      <CustomLiOne>City/State:</CustomLiOne>
                      <CustomLiOne>Discount(%):</CustomLiOne>
                    </ul>
                    <ul>
                      <CustomLiTwo>{currentOrder.distributor_name || "--"}</CustomLiTwo>
                      <CustomLiTwo>{currentOrder.distributor_contact_number || "--"}</CustomLiTwo>
                      <CustomLiTwo>{currentOrder.distributor_state || "--"}</CustomLiTwo>
                      <CustomLiTwo>{currentOrder.landing_cost}</CustomLiTwo>
                    </ul>
                  </CustomContainer>
                </BoxWrapperTwo>
              </CustomGridOne>
              <CustomGridTwo item xs={12} >
                <BoxWrapperThree>
                  <CustomTableTypography>Purchase Order (P.O.)</CustomTableTypography>
                  <TableComponent onUpdateTotalSaleValue={handleUpdateTotalSaleValue} />
                </BoxWrapperThree>
              </CustomGridTwo>
              <CustomGridLast item xs={12}>
                <BoxLast>
                  <Box sx={{display:"flex", flexDirection:"column", width:"70%",'@media (max-width: 900px)': {width:"100%"}}}>
                  <OneBox sx={{display:"flex", flexDirection:"row", width:"100%" }}>
                    {currentOrder.order_status === 'Approved Order' && (
                      <>
                        <CustomLiTwo>
                          <CustomTextField
                            type='text'
                            label="Invoice No"
                            value={currentOrder.invoice_no}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleUpdateInvoiceNo}
                            error={invoiceNoError}
                            helperText={invoiceNoError}
                          />
                        </CustomLiTwo>
                        <CustomLiTwo>
                          <CustomTextFieldDate
                            type="date"
                            label="Invoice Date"
                            value={currentOrder.invoice_date}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleUpdateInvoiceDate}
                            error={invoiceDateError}
                            helperText={invoiceDateError}
                          />
                        </CustomLiTwo>
                      </>
                    )}
                    {currentOrder.order_status === 'New Order' && (
                      <>
                        <CustomLiTwo>
                          <CustomTextField
                            type='text'
                            label="Invoice No"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleUpdateInvoiceNo}
                            error={invoiceNoError}
                            helperText={invoiceNoError}
                          />
                        </CustomLiTwo>
                        <CustomLiTwo>
                          <CustomTextFieldDate
                            type="date"
                            label="Invoice Date"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleUpdateInvoiceDate}
                            error={invoiceDateError}
                            helperText={invoiceDateError}
                          />
                        </CustomLiTwo>
                      </>
                    )}
                  
                  </OneBox>
                  <Box sx={{width:"88%"}}>
                   {(currentOrder.order_status === 'New Order') && (
                      <CustomButton onClick={handleSaveData}>Update</CustomButton>
                    )}
                  </Box>
                  <Box sx={{width:"88%"}}>
                   {(currentOrder.order_status === 'Approved Order') && (isFileDownloaded === false) && (
                      <CustomButton onClick={handleGenerateQrCode}>Generate QR Code</CustomButton>
                    )}
                  </Box>
                  </Box>
                  <CustomGridOne item xs={12} md={6} lg={6}>
                    <CardWrapperTwo>
                      <ul>
                        <CustomLi>Total:</CustomLi>
                        <CustomLi>Discount:</CustomLi>
                        <CustomLi>Grand Total:</CustomLi>
                      </ul>
                      <ul>
                        <CustomLiTwo> {TotalSaleValue || "--"}</CustomLiTwo>
                        <CustomLiTwo> {currentOrder .landing_cost}</CustomLiTwo>
                        <CustomLiTwo> {(TotalSaleValue - currentOrder .landing_cost).toFixed(2) || "--"}</CustomLiTwo>
                      </ul>
                    </CardWrapperTwo>
                  </CustomGridOne>
                </BoxLast>
              </CustomGridLast>
            </Grid>
          </Toolbar>
        </BoxContainer>
      )}
    </>
  );
};

export default ViewPage;