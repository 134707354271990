import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

const OrderDetailCard = ({ cardData }) => {
  return (
    <Card style={{ width: '50%', border: `1px solid ${cardData.bgColor}` }}>
      <CardHeader
        title={cardData.title}
        style={{
          height: '20px',
          background: cardData.bgColor,
          borderBottom: '1px solid #ccc',
          fontSize: '18px',
        }}
      />

      <CardContent style={{ padding: '0%' }}>
        <List>
          {cardData.orderDetail.map((data, index) => {
            return (
              <ListItem
                key={index}
                style={{ width: '60%', padding: '0px auto', fontSize: '10px' }}
              >
                <ListItemText
                  primary={data.item}
                  secondary={data.value}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '12px',
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
};

export default OrderDetailCard;
