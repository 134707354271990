import { Box, Container, TextField, Typography } from '@mui/material';
import gridImage2 from '../../../images/QR.svg';
import logo from '../../../images/loyaltyLogo.svg';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';

const GridTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.printButton.color,
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '28px',
  textTransform: 'capitalize',
}));

const QRInvoice = ({ selectedPart }) => {
  const theme = useTheme();
  const [partName, setPartName] = useState('');
  const [points, setPoints] = useState('');
  const [mrp, setMrp] = useState('');

  useEffect(() => {
    setMrp(selectedPart?.mrp);
    setPoints(selectedPart?.points);
    setPartName(selectedPart?.part_number);
  }, [selectedPart]);

  return (
    <>
      <Box
        sx={{
          borderRadius: '20px',
          boxShadow: theme.palette.qrCodeContainer.boxShadow,
          backgroundColor: '#fff',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            paddingTop: '20px',
          }}
        >
          <Container>
            <img src={logo} alt="loyalty logo" />
            <Box paddingTop={'48px'}>
              <GridTypography sx={{ paddingBottom: '16px' }}>
                Part Name = {partName}
              </GridTypography>
              <GridTypography sx={{ paddingBottom: '16px' }}>
                Part Number = {points}
              </GridTypography>
              <GridTypography sx={{ paddingBottom: '16px' }}>
                MRP = {mrp}
              </GridTypography>
              <Typography
                sx={{
                  paddingBottom: '16px',
                  fontStyle: 'italic',
                  fontSize: '14px',
                }}
              >
                incl. of taxes
              </Typography>
            </Box>
          </Container>
          <Box>
            <Container>
              <img
                style={{ maxWidth: '100%' }}
                src={gridImage2}
                alt="QR code"
              />
            </Container>

            <Typography
              sx={{ paddingTop: '16px', fontStyle: 'italic', fontSize: '14px' }}
            >
              Packing Date: DD-MM-YYYY
            </Typography>
          </Box>
        </Box>

        <Container sx={{ paddingTop: '62px' }}>
          <Box flexGrow={1} border="1px solid #82796a29" ml={1} />
          <TextField
            sx={{ p: '16px' }}
            fullWidth
            placeholder="Description"
            multiline
            rows={2}
            variant="outlined"
          />
        </Container>
      </Box>
    </>
  );
};

export default QRInvoice;
