import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, MenuItem, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import AddSchema from '../../tenant/components/validations/validationsSchema';
import FormInitialValues from '../../tenant/components/validations/initalValues';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: 'button.menu' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'icon.main',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DialogBox = ({
  name,
  onClose,
  data,
  onAddRow,
  editData,
  id,
  isEditMode,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const func = editData && Object.keys(editData).length > 0 ? 'Edit' : 'Add';

  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const handleSelectChange = (key, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const formik = useFormik({
    initialValues: { FormInitialValues },
    validationSchema: AddSchema(data),
    onSubmit: () => {
      setOpen(false);
      onAddRow(selectedValues, editData[id]);
      onClose();
    },
  });

  useEffect(() => {
    if (editData) setSelectedValues(editData);
    else setSelectedValues([]);
    setOpen(true);
  }, [editData]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'background.paper',
            boxShadow: 'none',
            borderRadius: '10px',
            width: '100%',
            maxWidth: '600px',
          },
          '& button.MuiButtonBase-root ': {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            textTransform: 'capitalize',
            borderRadius: '10px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'button.menuhover',
              boxShadow: 'none',
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {func}&nbsp;
          <span style={{ textTransform: 'capitalize' }}>{name}</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Grid container spacing={2}>
              {Object.entries(data).map(([key, value]) => {
                if (
                  value.id === 'is_active' ||
                  value.id === 'actions' ||
                  value.id === 'action' ||
                  value.id === 'mechanicTab' ||
                  value.id === 'retailersTab' ||
                  value.id === 'productsTab' ||
                  value.id === 'redeemRequestTab' ||
                  value.id === 'redeemPointsTab' ||
                  value.id === 'permission' ||
                  value.id === 'deleted_at' ||
                  value.id === 'created_at' ||
                  value.id === 'updated_at'
                ) {
                  return null;
                }

                return (
                  <Grid item xs={12} md={6} key={key}>
                    {!['bank', 'city'].includes(value.id) ? (
                      <TextField
                        placeholder={value.label}
                        fullWidth
                        label={value.label}
                        value={selectedValues[value.id]}
                        error={
                          formik.errors[value.id] && formik.touched[value.id]
                        }
                        helperText={
                          formik.errors[value.id] && formik.touched[value.id]
                            ? formik.errors[value.id]
                            : ''
                        }
                        onBlur={formik.handleBlur}
                        onChange={(event) => {
                          formik.handleChange(event);
                          handleSelectChange(value.id, event.target.value);
                        }}
                        disabled={
                          isEditMode &&
                          value.id === 'id' &&
                          editData &&
                          editData.id !== undefined
                        }
                      />
                    ) : (
                      <Grid item xs={12} md={12}>
                        <Select
                          fullWidth
                          value={selectedValues[value.id] || ''}
                          displayEmpty
                          name={value.id}
                          onChange={(event) =>
                            handleSelectChange(value.id, event.target.value)
                          }
                        >
                          <MenuItem value="" disabled>
                            {value.label}
                          </MenuItem>
                          <MenuItem value="Yes">Yes</MenuItem>
                          <MenuItem value="No">No</MenuItem>
                        </Select>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
              <Grid item xs={12} md={12}>
                <Button
                  autoFocus
                  fullWidth
                  type="submit"
                  sx={{
                    backgroundColor: 'button.menu',
                    color: 'icon.main',
                    fontFamily: 'Poppins',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '22px',
                    textTransform: 'capitalize',
                    padding: '12px 0px 12px 20px',
                    borderRadius: '10px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'button.menuhover',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default DialogBox;
