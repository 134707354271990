const ProductCol = [
  'product_category',
  'part_number',
  //  'product_category_id',
  'product_description',
  'mrp',
  'points',
  'is_active',
  // 'actions',
];

export default ProductCol;
