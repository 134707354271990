import { Container, Grid, Typography, useMediaQuery } from '@mui/material';
import DashboardCard from '../../commons/dashboardCard';
import { LineChart } from '../../tenant/components/lineChart/lineChart';
import { PieChart } from '../../tenant/components/pieChart/pieChart';
import EarnedPointsCards from '../../tenant/components/earnedPointsCards/earnedPointsCards';
import { cardData, earnedPointsData } from './adminDashboardData';

const Dashboard = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <Container sx={{ paddingTop: '20px', marginBottom: '30px' }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Good Morning, Jhon Doe
      </Typography>
      <Typography>Your performance summary this week</Typography>

      <Grid container spacing={isSmallScreen ? 2 : 2} paddingTop={'20px'}>
        {cardData.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <DashboardCard
              mainText={item.mainText}
              arrowImg={item.arrowImg}
              graphImg={item.graphImg}
              percentageText={item.percentageText}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={isSmallScreen ? 2 : 4} paddingTop={'26px'}>
        <Grid item xs={12} sm={9}>
          <LineChart />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PieChart />
        </Grid>
      </Grid>
      <Grid container spacing={isSmallScreen ? 2 : 2} paddingTop={'32px'}>
        {earnedPointsData.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <EarnedPointsCards
              key={item.id}
              title={item.title}
              pendingText={item.pendingText}
              pendingValue={item.pendingValue}
              financialYearValue={item.financialYearValue}
              totalValue={item.totalValue}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
