import React from 'react';
import { Button, styled } from '@mui/material';

const GradientButtonStyle = styled(Button)(({ theme, disabled }) => ({
  background: theme.palette.button.gradient,
  borderRadius: '12px',
  color: theme.palette.text.primary,
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  border: '0px solid #291B04;',
  padding: '12px 20px',
  boxShadow: '0px 4px 16px 0px rgba(41, 27, 4, 0.08);',
  fontFamily: 'Poppins',
  fontSize: 18,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
  textTransform: 'capitalize',
  '&:hover': {
    background: theme.palette.button.menu,
  },

  pointerEvents: disabled ? 'none' : 'auto',
  opacity: disabled ? 0.5 : 1,
  [theme.breakpoints.down('md')]: {
    width: 'auto',
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}));

const GradientButton = ({ name, onClick, disabled }) => {
  const handleButtonClick = () => {
    if (onClick && !disabled) {
      onClick();
    }
  };

  return (
    <GradientButtonStyle onClick={handleButtonClick} disabled={disabled}>
      {name}
    </GradientButtonStyle>
  );
};

export default GradientButton;
