import { Box, Container, styled, Typography } from '@mui/material';

const CustomBox = styled(Box)(({ theme }) => ({
  height: '148px',
  flexShrink: 0,
  borderRadius: '20px',
  background: theme.palette.background.main,
  boxShadow: '0px 4px 16px 0px rgba(41, 27, 4, 0.08)',
}));

const CustomTypography = styled(Typography)({
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '40px',
  letterSpacing: '0.8px',
});

const GraphTypoography = styled(Typography)(({ theme }) => ({
  color: theme.palette.graphTypography.color,
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  textTransform: 'capitalize',
}));

const FlexContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '20px',
});

const RetailersTypography = styled(Typography)({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '20px',
  textTransform: 'capitalize',
});

const DashboardCard = ({
  mainText,
  percentageText,
  arrowImg,
  graphImg,
  cardName,
  icon
}) => {
  return (
    <CustomBox>
      <FlexContainer sx={{ paddingTop: '20px' }}>
        <CustomTypography>{mainText}</CustomTypography>
        {icon}
      </FlexContainer>
      {/* <GraphTypoography
        display={'flex'}
        justifyContent={'flex-end'}
        paddingRight={'32px'}
      >
        {percentageText}
      </GraphTypoography> */}
      <FlexContainer sx={{mt:2}}>
        <RetailersTypography>{cardName}</RetailersTypography>
        <img src={graphImg} alt="asd" />
      </FlexContainer>
    </CustomBox>
  );
};

export default DashboardCard;
