import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Switch } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from '../../commons/snackBarContextProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, backgroundColor: 'button.menu' }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'icon.main',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const PermissionDialogBox = ({ id, onClose, data, editData }) => {
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    is_mechanic_tab: true,
    is_products_tab: true,
    is_redeem_points_tab: true,
    is_redeem_request_tab: false,
    is_retailer_tab: true,
    is_order_tab: true,
    is_distributor_tab: true,
    is_marketing_executive_tab: true,
    is_gift_tab: true,
    is_points_tab: true
  });
  const { show } = useSnackbar();
  const token = JSON.parse(localStorage.getItem('adminData')).token;

  const handleClose = (e) => {
    setOpen(false);
    onClose();
  };

  const handleSelectChange = (key, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_ADMIN}/permission/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const resData = {
        is_mechanic_tab: res.data.data.is_mechanic_tab,
        is_products_tab: res.data.data.is_products_tab,
        is_redeem_points_tab: res.data.data.is_redeem_points_tab,
        is_redeem_request_tab: res.data.data.is_redeem_request_tab,
        is_retailer_tab: res.data.data.is_retailer_tab,
        is_order_tab: res.data.data.is_order_tab,
        is_distributor_tab: res.data.data.is_distributor_tab,
        is_marketing_executive_tab: res.data.data.is_marketing_executive_tab, 
        is_gift_tab: res.data.data.is_gift_tab,
        is_points_tab: res.data.data.is_points_tab
      };

      setSelectedValues(resData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      is_retailer_tab: selectedValues.is_retailer_tab,
      is_mechanic_tab: selectedValues.is_mechanic_tab,
      is_products_tab: selectedValues.is_products_tab,
      is_redeem_request_tab: selectedValues.is_redeem_request_tab,
      is_redeem_points_tab: selectedValues.is_redeem_points_tab,
      is_order_tab: selectedValues.is_order_tab,
      is_distributor_tab: selectedValues.is_distributor_tab,
      is_marketing_executive_tab: selectedValues.is_marketing_executive_tab,
      is_gift_tab: selectedValues.is_gift_tab,
      is_points_tab: selectedValues.is_points_tab
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_ADMIN}/permission/${id}`,
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      onClose();
      show('Permissions Updated');
    } catch (error) {
      console.error('Error:', error);
      show('An Error Occured', 'error');
    }
  };

  useEffect(() => {
    if (editData) {
      setSelectedValues(editData);
    } else {
      setSelectedValues({});
    }

    fetchData();
    setOpen(true);
    //eslint-disable-next-line
  }, [editData, id]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            backgroundColor: 'background.paper',
            boxShadow: 'none',
            borderRadius: '10px',
            width: '100%',
            maxWidth: '600px',
          },
          '& button.MuiButtonBase-root ': {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '22px',
            textTransform: 'capitalize',
            borderRadius: '10px',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'button.menuhover',
              boxShadow: 'none',
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Permissions - &nbsp;
          <span style={{ textTransform: 'capitalize' }}>{id}</span>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit} onReset={() => setSelectedValues([])}>
            <Grid container spacing={2}>
              {Object.entries(data).map(([key, value]) => {
                if (
                  !(
                    value.id === 'is_retailer_tab' ||
                    value.id === 'is_mechanic_tab' ||
                    value.id === 'is_products_tab' ||
                    value.id === 'is_redeem_request_tab' ||
                    value.id === 'is_redeem_points_tab' ||
                    value.id === 'is_order_tab' ||
                    value.id === 'is_distributor_tab' ||
                    value.id === 'is_marketing_executive_tab' ||
                    value.id === 'is_gift_tab' ||
                    value.id === 'is_points_tab'
                  )
                ) {
                  return null;
                }
                return (
                  <Grid item xs={12} md={12} key={value.id}>
                    <Grid container alignItems="center">
                      <Grid item xs={8} md={8}>
                        <span>{value.label}</span>
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <Switch
                          fullWidth
                          placeholder={value.label}
                          label={value.label}
                          name={value.id}
                          checked={selectedValues[value.id]}
                          onChange={(event) =>
                            handleSelectChange(value.id, event.target.checked)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}

              <Grid item xs={12} md={12}>
                <Button
                  autoFocus
                  fullWidth
                  type="submit"
                  sx={{
                    backgroundColor: 'button.menu',
                    color: 'icon.main',
                    fontFamily: 'Poppins',
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '22px',
                    textTransform: 'capitalize',
                    padding: '12px 0px 12px 20px',
                    borderRadius: '10px',
                    boxShadow: 'none',
                    '&:hover': {
                      backgroundColor: 'button.menuhover',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Save changes
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default PermissionDialogBox;
