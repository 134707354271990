import { Outlet } from 'react-router-dom';
import AdminNavbar from '../components/navbar/navbar';
import GradientBox from '../../commons/gradiantComponents/gradientBox';

const AdminLayout = () => {
  return (
    <GradientBox>
      <AdminNavbar />
      <Outlet />
    </GradientBox>
  );
};

export default AdminLayout;
