import React from 'react';
import ProductsDataCol from '../../components/tabledata/productsTable/productDataCol';
import ProductsCol from '../../components/tabledata/productsTable/productCol';
import { useProduct } from '../../services/products/productService';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';
import ProdSearchCol from '../../components/tabledata/productsTable/searchFieldsProd';
import PorductPage from '../pageData/productPage';
const Products = () => {
  const {
    loading,
    addData,
    allProductData,
    deleteProd,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchProductService,
  } = useProduct(); 

  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_products_tab != (1||"1") ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <PorductPage
              columns={ProductsDataCol}
              rows={allProductData}
              data={ProductsCol}
              // setCurrentProduct={setCurrentProduct}
              temp={'id'}
              showGradientProductButton={true}
              showQRButton={true}
              delData={deleteProd}
              addData={addData}
              updateData={updateData}
              toggle={active}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              total={noOfPages}
              getPage={getCurrentPage}
              totalRecords={totalRecords}
              searchFields={ProdSearchCol}
              search={searchProductService}
              selectFile='file'
            />
          )}
        </>
      )}
    </>
  );
};

export default Products;
