import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import ThrowError from '../../utils/errors';
import { TENANT_URL } from '../../../config';

const GiftContext = createContext();
const GiftProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [allGiftData, setAllGiftData] = useState([]);
  const [giftData, setGiftData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const { show } = useSnackbar();

  const addData = async (userData) => {
    userData.is_active = true;
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const setData = {
      method: 'post',
      url: `${TENANT_URL}/gift`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    try {
      await axios({ ...setData, data: data });
      show('Gifts Added Successfully');
      await getCurrentPage(currentPage);
    } catch (error) {
      ThrowError(error, show);
    }

    setLoading(false);
  };

  const getSingleData = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/gift/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setLoading(true);
    try {
      const response = await axios(getConfig);
      setGiftData(response.data.data);
    } catch (error) {
      console.error(error);
      show('User Not Found', 'error');
    }
    setLoading(false);
  };

  const getCurrentPage = async (pageNo) => {
    setCurrentPage(pageNo);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/gift?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setAllGiftData(response.data.data);
    } catch (error) {
      console.error(error);
      show('Data not Updated', 'error');
    }
  };

  const getPage = async () => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/gift?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRowsPerPage(response.data.meta.per_page);
      setCurrentPage(response.data.meta.current_page);
      setNoOfPages(Math.ceil(response.data.meta.total / 10));
      setTotalRecords(response.data.meta.total);
    } catch (error) {
      console.error(error);
      show('Data not Updated', 'error');
    }
  };

  const deleteGift = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/gift/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(deleteConfig);
      await getCurrentPage(currentPage);
      show('Gifts deleted Successfully');
    } catch (error) {
      console.error(error);
      show('Gifts not deleted', 'error');
    }
  };

  const updateData = async (userData, id) => {
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const setData = {
      method: 'put',
      url: `${TENANT_URL}/gift/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    try {
      await axios({ ...setData, data: data });
      show('Gifts Updated');
      await getCurrentPage(currentPage);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };

  const active = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var getConfig = {
      method: 'get',
      url: `${TENANT_URL}/active/gift/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(getConfig);
      getCurrentPage(currentPage);
    } catch (error) {
      console.error(error);
    }
  };

  const searchGifts = async (searchData) => {
    var data = JSON.stringify(searchData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const setData = {
      method: 'post',
      url: `${TENANT_URL}/search/gift`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    try {
      const response = await axios({ ...setData, data: data });
      setAllGiftData(response.data.data);
      setRowsPerPage(100);
      setCurrentPage(1);
      setNoOfPages(1);
      setTotalRecords(10);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getPage();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCurrentPage(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const contextValue = {
    setLoading,
    loading,
    addData,
    allGiftData,
    getSingleData,
    giftData,
    getCurrentPage,
    deleteGift,
    updateData,
    rowsPerPage,
    currentPage,
    noOfPages,
    getPage,
    active,
    totalRecords,
    searchGifts,
  };

  return (
    <GiftContext.Provider value={contextValue}>{children}</GiftContext.Provider>
  );
};

const useGifts = () => useContext(GiftContext);

export { GiftProvider, useGifts };
