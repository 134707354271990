const ThrowError = (error, show) => {
  if (error.response.data.message && error.response.data.message.id)
    show(error.response.data.message.id, 'error');
  if (error.response.data.message && error.response.data.message.email)
    show(error.response.data.message.email, 'error');
  if (error.response.data.message && error.response.data.message.contact_number)
    show(error.response.data.message.contact_number, 'error');
  if (error.response.data.message && error.response.data.message.state)
    show(error.response.data.message.state, 'error');
  if (error.response.data.message && error.response.data.message.date)
    show(error.response.data.message.date, 'error');
  if (error.response.data.message && error.response.data.message.password)
    show(error.response.data.message.password, 'error');
  if (error.response.data.message && error.response.data.message.pan_card_number)
    show(error.response.data.message.pan_card_number, 'error');  
  if (error.response.data.message && error.response.data.message.ifsc_code)
    show(error.response.data.message.ifsc_code, 'error');  
  if (error.response.data.message && error.response.data.message.account_number)
    show(error.response.data.message.account_number, 'error');  
  if (error.response.data.message && error.response.data.message.city)
    show(error.response.data.message.city, 'error');  
  if (error.response.data.message && error.response.data.message.bank_name)
    show(error.response.data.message.bank_name, 'error');  
  if (error.response.data.message && error.response.data.message.aadhar_number)
    show(error.response.data.message.aadhar_number, 'error');  
  if (error.response.data.message && error.response.data.message.pan_number)
    show(error.response.data.message.pan_number, 'error');  
  if (error.response.data.message && error.response.data.message.gst_number)
    show(error.response.data.message.gst_number, 'error');  
  if (error.response.data.message && error.response.data.message.name)
    show(error.response.data.message.name, 'error');  
  if (error.response.data.message && error.response.data.message.shop_name)
    show(error.response.data.message.shop_name, 'error');  
  if (error.response.data.errors && error.response.data.errors.contact_number)
    show(error.response.data.errors.contact_number[0], 'error');
  if (error.response.data.errors && error.response.data.errors.state)
    show(error.response.data.errors.state[0], 'error');
  if (error.response.data.errors && error.response.data.errors.city)
    show(error.response.data.errors.city[0], 'error');
  if (error.response.data.errors && error.response.data.errors.name)
    show(error.response.data.errors.name[0], 'error');
  if (error.response.data.errors && error.response.data.errors.email)
    show(error.response.data.errors.email[0], 'error');

  return null;
};

export default ThrowError;
