import { Box } from '@mui/material';
import { styled } from '@mui/system';

const GradientBox = styled(Box)(({ theme }) => ({
  background: theme.palette.page.main,
  display: { md: 'flex', xs: 'flex' },
  margin: 0,
}));

export default GradientBox;
