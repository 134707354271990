import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
} from '@mui/material';
import { useOrder } from '../../services/orders/ordersService';
import { TableHeader, TableCells } from './Table.Style';

const TableComponent = ({ onUpdateTotalSaleValue }) => {
  const headers = [
    'Part Number',
    'MRP',
    'Order Quantity',
    'Value',
    'Sale Quantity',
    'Sale Value',
  ];

  const headersValues = [
    'part_number',
    'mrp',
    'quantity',
    'value',
    'sale_quantity',
    'sale_value',
  ];

  const { order:currentOrder  } = useOrder();
  const [order, setOrder] = useState({});
  const [defaultSaleQuantities, setDefaultSaleQuantities] = useState({});
  useEffect(() => {
    if (currentOrder?.orders) {
      const updatedOrders = currentOrder.orders.map((order) => ({
        ...order,
        value: order.mrp * order.quantity,
        sale_quantity: defaultSaleQuantities[order.part_number] || order.quantity,
        sale_value: order.mrp * (defaultSaleQuantities[order.part_number] || order.quantity),
      }));
      setOrder((prevOrder) => ({
        ...prevOrder,
        orders: updatedOrders,
      }));
      // Calculate total sale value for the ViewPage
      const totalSaleValue = order?.orders?.reduce((total, ord) => total + ord.sale_value, 0) || 0;
      onUpdateTotalSaleValue(totalSaleValue);
    }
  }, [currentOrder.orders, defaultSaleQuantities]);

  const handleSaleQuantityChange = (partNumber, rowIndex) => (value) => {
    setOrder((prevOrder) => ({
      ...prevOrder,
      orders: prevOrder.orders.map((order, i) =>
        i === rowIndex
          ? {
              ...order,
              sale_quantity: value,
              sale_value: order.mrp * value,
            }
          : order
      ),
    }));
    setDefaultSaleQuantities((prevDefaults) => ({
      ...prevDefaults,
      [partNumber]: value,
    }));
  };

  return (
    <div>
      <TableContainer component={Paper} sx={{borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' }}>
        <Table >
          <TableHeader>
            <TableRow>
              <TableCell>#</TableCell>
              {[...headers || []]?.map((column, index) => (
                <TableCell key={index}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {order?.orders?.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell>{rowIndex + 1}</TableCell>
                {[...headersValues || []]?.map((column, colIndex) => (
                  <TableCell key={colIndex}>
                    {column === 'sale_quantity' ? (
                      <TextField
                        type="number"
                        value={row[column]}
                        onChange={(e) => handleSaleQuantityChange(row.part_number, rowIndex)(e.target.value)}
                      />
                    ) : (
                      row[column.toLowerCase().replace(/\s/g, '')]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TableComponent;