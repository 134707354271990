import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../../commons/snackBarContextProvider';
import ThrowError from '../../utils/errors';
import { TENANT_URL } from '../../../config';

const ProductCategoryContext = createContext();
const ProductCategroyProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [allProductData, setAllProductData] = useState([]);
  const [productData, setProductData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [product, setProduct] = useState({});

  const { show } = useSnackbar();

  const addData = async (userData) => {
    userData.is_active = true;
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const setData = {
      method: 'post',
      url: `${TENANT_URL}/category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);

    try {
      await axios({ ...setData, data: data });
      show('Product Category Added Successfully');
      await getCurrentPage(currentPage);
    } catch (error) {
      ThrowError(error, show);
    }

    setLoading(false);
  };

  const getSingleData = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setLoading(true);
    try {
      const response = await axios(getConfig);
      setProductData(response.data.data);
    } catch (error) {
      console.error(error);
      show('Product Category Not Found', 'error');
    }
    setLoading(false);
  };
  const getCurrentPage = async (pageNo) => {
    setCurrentPage(pageNo);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/category?page=${currentPage}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setAllProductData(response.data.data);
    } catch (error) {
      console.error(error);
      show('Data not Updated', 'error');
    }
  };

  const getPage = async (pageNo) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/category?page=${pageNo}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRowsPerPage(response.data.meta.per_page);
      setCurrentPage(response.data.meta.current_page);
      setNoOfPages(Math.ceil(response.data.meta.total / 10));
      setTotalRecords(response.data.meta.total);
    } catch (error) {
      console.error(error);
      show('Data not Updated', 'error');
    }
  };

  const deleteProd = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(deleteConfig);
      await getCurrentPage(currentPage);
      show('Product Category deleted Successfully');
    } catch (error) {
      console.error(error);
      show('Product Category not deleted', 'error');
    }
  };

  const updateData = async (userData, id) => {
    var data = JSON.stringify(userData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const setData = {
      method: 'put',
      url: `${TENANT_URL}/category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };
    setLoading(true);
    try {
      await axios({ ...setData, data: data });
      show('Product Category Updated');
      await getCurrentPage(currentPage);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };

  const active = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var getConfig = {
      method: 'get',
      url: `${TENANT_URL}/active/category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(getConfig);
      getCurrentPage(currentPage);
    } catch (error) {
      console.error(error);
    }
  };

  const searchProductService = async (searchData) => {
    var data = JSON.stringify(searchData);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const setData = {
      method: 'post',
      url: `${TENANT_URL}/search/category`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    setLoading(true);
    try {
      const response = await axios({ ...setData, data: data });
      setAllProductData(response.data.data);
      setRowsPerPage(100);
      setCurrentPage(1);
      setNoOfPages(1);
      setTotalRecords(10);
    } catch (error) {
      ThrowError(error, show);
    }
    setLoading(false);
  };
  const setCurrentProduct =  (obj) => {
    setProduct(obj);
  }

  useEffect(() => {
    setLoading(true);
    getPage();
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCurrentPage(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const contextValue = {
     product,
    setLoading,
    loading,
    addData,
    allProductData,
    getSingleData,
    productData,
    getCurrentPage,
    deleteProd,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getPage,
    totalRecords,
    searchProductService,
     setCurrentProduct,
  };
  return (
    <ProductCategoryContext.Provider value={contextValue}>
      {children}
    </ProductCategoryContext.Provider>
  );
};

const useProductCategory = () => useContext(ProductCategoryContext);

export { ProductCategroyProvider, useProductCategory };
