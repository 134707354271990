import React from 'react';
import Page from '../pageData/page';
import DistributorsDataCol from '../../components/tabledata/distributorTable/distributorsDataCol';
import DistributorsCol from '../../components/tabledata/distributorTable/distributorsCol';
import { useDistributor } from '../../services/distributors/distributorsService';
import Spinner from '../../../commons/spinner';
import ErrorPage from '../../utils/errorPage';
import DistributorSearchCol from '../../components/tabledata/distributorTable/searchFields';

const Distributors = () => {
  const {
    loading,
    addData,
    allDistributorData,
    deleteMech,
    updateData,
    active,
    rowsPerPage,
    currentPage,
    noOfPages,
    getCurrentPage,
    totalRecords,
    searchDistributors,
  } = useDistributor();

  const userData = localStorage.getItem('userdata');
  const permissions = JSON.parse(userData).tenant_nav_bar_permission;

  return (
    <>
      {permissions.is_retailer_tab != (1||"1") ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <Spinner />
          ) : (
            <Page
              columns={DistributorsDataCol}
              rows={allDistributorData}
              data={DistributorsCol}
              temp={'id'}
              showGradientButton={true}
              showQRButton={false}
              delData={deleteMech}
              addData={addData}
              updateData={updateData}
              toggle={active}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              total={noOfPages}
              getPage={getCurrentPage}
              totalRecords={totalRecords}
              searchFields={DistributorSearchCol}
              search={searchDistributors}
            />
          )}
        </>
      )}
    </>
  );
};

export default Distributors;
