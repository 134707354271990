const prodDataCol = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'product_category_id',
    numeric: false,
    disablePadding: false,
    label: 'Product Category',
  },
  // {
  //   id: 'product_category',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Product Category',
  // },
  {
    id: 'part_number',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'Part Number',
  },
  {
    id: 'list',
    numeric: true,
    disablePadding: false,
    required: true,
    label: 'LIST',
  },
  {
    id: 'product_description',
    numeric: false,
    disablePadding: false,
    required: true,
    label: 'Description',
  },
  {
    id: 'mrp',
    numeric: true,
    disablePadding: false,
    required: true,
    label: 'MRP',
  },
  
  {
    id: 'deleted_at',
    numeric: false,
    disablePadding: false,
    label: 'Deleted At',
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'updated_at',
    numeric: false,
    disablePadding: false,
    label: 'Updated At',
  },
  {
    id: 'points',
    numeric: false,
    disablePadding: true,
    required: true,
    label: 'Points',
  },
  {
    id: 'is_active',
    numeric: false,
    disablePadding: false,
    label: 'Is Active',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
  },
];

export default prodDataCol;
