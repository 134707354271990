import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
export default function viewOrderFooter() {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          px: 2,
        }}
      >
        <Box
          sx={{ width: '50%', display: 'flex', alignItems: 'center', gap: 2 }}
        >
          <Box sx={{ width: '30%' }}>
            <Typography sx={{ fontSize: '13px', mb: 1, fontWeight: 500 }}>
              Invoice
            </Typography>
            <TextField
              name="vendorCode"
              value=""
              sx={{ height: '25px' }}
              fullWidth
              type={'text'}
              InputProps={{
                sx: {
                  backgroundColor: 'white',
                  flex: 6,
                  height: '40px',
                  border: 'solid 0.2px rgba(191, 197, 208, 1)',
                },
              }}
            />
          </Box>
          <Box sx={{ width: '30%' }}>
            <Typography sx={{ fontSize: '13px', mb: 1, fontWeight: 500 }}>
              Invoice Data
            </Typography>
            <TextField
              name="vendorCode"
              value=""
              sx={{ height: '25px' }}
              fullWidth
              type={'text'}
              InputProps={{
                sx: {
                  flex: 6,
                  height: '40px',
                  border: 'solid 0.2px rgba(191, 197, 208, 1)',
                },
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'end',
            width: '50%',
            background: '#f1f4f7',
            py: 2,
            my: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '60%',
              height: '10px',
            }}
          >
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              Total:{' '}
            </Box>
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              27040.00
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '60%',
              height: '10px',
            }}
          >
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              Discount(48.52)% :{' '}
            </Box>
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              {' '}
              13119.81
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '60%',
              height: '10px',
            }}
          >
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              TCS (0.1%):{' '}
            </Box>
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              13.92
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '60%',
              height: '10px',
            }}
          >
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              Grand Total:{' '}
            </Box>
            <Box sx={{ fontsize: '10px', color: '#333' }} component="span">
              13934.11
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
