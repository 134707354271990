import React from 'react';
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { Box, Container } from '@mui/system';
import { Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale);

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: '20px',
  background: theme.palette.secondary.color,
  boxShadow: theme.palette.lineChartColors.boxShadow,
  height: '100%',
}));

const PieChartTypo = styled(Typography)({
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: 'capitalize',
  paddingTop: '25px',
});

const InsidePieChartTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.earnedPointsSeconday.color,
  textAlign: 'center',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20px',
  textTransform: 'capitalize',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const InsidePieChartTypoMain = styled(Typography)({
  fontSize: '25px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: 'capitalize',
  paddingTop: '40px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export function PieChart() {
  const theme = useTheme();

  const data = {
    labels: ['DataSet1', 'DataSet2'],
    datasets: [
      {
        label: '# of scans',
        data: [12, 20],
        backgroundColor: [
          theme.palette.pieChartColors.red,
          theme.palette.pieChartColors.orange,
        ],
        borderColor: [
          theme.palette.pieChartColors.red,
          theme.palette.pieChartColors.orange,
        ],
        borderWidth: [1, theme.breakpoints.down('sm') ? 7 : 2],
      },
    ],
  };

  const options = {
    cutout: 80,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      elements: {
        arc: {
          draw: (context) => {
            const { ctx } = context;
            const { innerRadius, outerRadius, startAngle, endAngle, label } =
              context.chart.data.datasets[0];

            const x = (outerRadius + innerRadius) / 2;
            const y = (startAngle + endAngle) / 2;

            ctx.save();
            ctx.font = '18px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#000';
            ctx.fillText(label, x, y);
            ctx.restore();
          },
        },
      },
    },
  };

  return (
    <StyledBox>
      <Container sx={{ display: 'flex', flexDirection: 'column' }}>
        <PieChartTypo>Total Scans</PieChartTypo>
        <InsidePieChartTypo>Total Scans</InsidePieChartTypo>
        <InsidePieChartTypoMain>25.5k</InsidePieChartTypoMain>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '100px',
          }}
        >
          <Pie
            data={data}
            options={options}
            responsive={true}
            maintainAspectRatio={false}
          />
        </div>
      </Container>
    </StyledBox>
  );
}
